
import { Box } from '@mui/material'

import { toCurrency } from 'utils/formats'

function DiscrepancyDetail({ row, formattedDate, formattedTime }) {
  const details = row?.details ? JSON.parse(row.details) : {}
  const additional_info = row?.additional_info ? JSON.parse(row.additional_info) : {}

  return (
    <>
      <table width="100%" className="subtable">
        <tbody>
          <tr>
            <td>Proveedor:</td>
            <td>{additional_info?.vendor_name}</td>
          </tr>
          <tr>
            <td>Monto:</td>
            <td>{toCurrency(row?.amount)}</td>
          </tr>
          <tr>
            <td>Autorización:</td>
            <td>{row?.auth_number || 'N/A'}</td>
          </tr>
          <tr>
            <td>Fecha:</td>
            <td>{formattedDate}</td>
          </tr>
          <tr>
            <td>Hora:</td>
            <td>{formattedTime}</td>
          </tr>
        </tbody>
      </table>
      <Box className="bodyLarge" style={{ marginBottom: 10 }}>
        <b>Producto reembolsado</b>
      </Box>
      <table width="100%" className="subtable">
        <thead>
          <tr>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Precio tienda</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {details?.consigment?.map((item, index) => (
            <tr key={index}>
              <td>{item?.name}</td>
              <td>{item?.units}</td>
              <td>{toCurrency(item?.store_cost)}</td>
              <td>{toCurrency(item?.store_cost * item?.units)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default DiscrepancyDetail