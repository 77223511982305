import { Box, Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import Input from 'components/Input'
import { toCurrency } from 'utils/formats'
import { minLength, maxLength } from 'utils/validations'
import colors from 'styles/colors'
import { useEffect } from 'react'

function Referenced({ control, watch, trigger, configuration, shouldVerifyReference, handleConsultAmount }) {
  const reference = watch('reference')
  const {
    max_length,
    min_length,
    allow_overdue,
    allow_partial_payment,
    customer_fee,
    store_fee,
    payment_info,
    // is_alphanumeric,
    // verify_reference
  } = configuration || {}

  useEffect(() => {
    if (reference) {
      trigger('reference')
    }
  }, [reference])

  return (
    <Box>
      <Box className="service-details">
        <p className="title">Detalles</p>
        <br />
        <p>
          Comisión a cobrar por servicio: <b>{toCurrency(customer_fee)}</b>
        </p>
        <br />
        <p>
          Comisión a ganar por servicio: <b style={{ color: colors.success }}>{toCurrency(store_fee)}</b>
        </p>
        <br />
        <p>
          Tiempo que tarda en reflejarse: <b>{payment_info}</b>
        </p>
        <br />
        <Box mb={2}>
          {allow_partial_payment ? (
            <Box className="flex-row align-center" sx={{ gap: '10px' }}>
              <CheckIcon sx={{ color: colors.success}} />  
              <span>
                Acepta pagos parciales
              </span>
            </Box>) : (
            <Box className="flex-row align-center" sx={{ gap: '10px' }}>
              <CloseIcon sx={{ color: colors.error }} />  
              <span>
                No acepta pagos parciales
              </span>
            </Box>)
          }
        </Box>
        <Box mb={0}>
          {allow_overdue ? (
            <Box className="flex-row align-center" sx={{ gap: '10px' }}>
              <CheckIcon sx={{ color: colors.success}} />  
              <span>Acepta recibos vencidos</span>
            </Box>) : (
            <Box className="flex-row align-center" sx={{ gap: '10px' }}>
              <CloseIcon sx={{ color: colors.error }} />  
              <span>No acepta recibos vencidos</span>
            </Box>)
          }
        </Box>
      </Box>
      
      <Box className="headlineSmall" my={2}>
        1: Ingresa datos del servicio
      </Box>

      <Box className="flex-row align-center" sx={{ gap: '20px' }}>
        <Box flex={1}>
          <Input
            name="reference"
            control={control}
            rules={{
              required: true,
              validate: {
                min: (value) => minLength(value, min_length || 0) || `Debe contener al menos ${min_length} cáracteres`,
                max: (value) => maxLength(value, max_length || 30) || `No debe contener mas de ${max_length || 30} cáracteres`
              }
            }}
            label="Ingresa número o escanea"
            fullWidth
          />
        </Box>
        <Box flex={1}>
          {shouldVerifyReference &&
            <Button
              variant="contained" 
              fullWidth 
              onClick={() => handleConsultAmount()}
              disabled={!reference}
            >
              Consultar
            </Button>
          }
        </Box>
      </Box>

      <Box className="flex-row" sx={{ gap: '20px' }}>
        <Input
          name="amount"
          control={control}
          rules={{
            required: true,
            validate: (value) => /^[0-9.]+$/.test(value) || 'Debe contener solo números'
          }}
          label="Ingresa monto"
          fullWidth
        />
        <Input
          name="confirm_amount"
          control={control}
          rules={{
            required: true,
            validate:  (value) => /^[0-9.]+$/.test(value) || 'Debe contener solo números'
          }}
          label="Confirma monto"
          fullWidth
        />
      </Box>


      <Box className="headlineSmall" my={2}>
        2: Ingresa el número celular
      </Box>
      
      <Box className="flex-row" sx={{ gap: '20px' }}>
        <Input
          name="phone_number"
          control={control}
          rules={{
            required: true,
            validate: {
              numbers: (value) => /^[0-9]+$/.test(value) || 'Solo numeros',
              length: (value) => /^.{10}$/.test(value) || 'Debe contener 10 digitos'
            }
          }}
          label="Ingresa número celular"
          fullWidth
        />
        <Input
          name="confirm_phone"
          control={control}
          rules={{
            required: true,
            validate: {
              numbers: (value) => /^[0-9]+$/.test(value) || 'Solo numeros',
              length: (value) => /^.{10}$/.test(value) || 'Debe contener 10 digitos'
            }
          }}
          label="Confirma número celular"
          fullWidth
        />
      </Box>
    </Box>
  )
}

export default Referenced
