import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box, Divider, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Grid from '@mui/material/Grid'
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { FORM_TYPES } from 'constants/types'
import { useAppContext, MODAL_TYPES } from 'providers/AppProvider'
import { Container } from '@mui/material'
import HeaderBar from 'components/HeaderBar'
import colors from 'styles/colors'
import ConfirmationModal from './modals/ConfirmationModal'
import TransactionModal from './modals/TransactionModal'
import { toCurrency } from 'utils/formats'
import { isNumeric } from 'utils/validations'
import {
  startTransaction, 
  clearErrors, 
  clearCheckout, 
  clearVerifyTransaction, 
  clearProduct,
  addNotificationId,
  fetchTransactionBalance,
  fetchProductBySlug,
  getMenuItems,
  setCellphonePlans,
  setCheckoutOptions,
  // setServiceFee
} from 'store/slices/sales'

import TransactionInProgress from './modals/TransactionInProgress'

import CellphoneMinutes from './forms/CellphoneMinutes'
import Pin from './forms/Pin'
import Referenced from './forms/Referenced'

function Checkout() {
  const app = useAppContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { control, handleSubmit, watch, setValue, trigger } = useForm()
  const { service_slug, slug } = useParams()
  const [searchParams] = useSearchParams()
  const { id: store_id, notified_ids } = useSelector(state => state.sales)
  const { 
    menu: {
      items,
      checkoutOptions,
      cellphonePlans,
      // selectedProduct,
      isLoading
    },
    // transaction,
    transaction: { error: transactionError },
    // navigateHome,
    // service_fee,
    verify_transaction,
    verify_transaction: {
      status,
      message,
    },
    consulted_amount,
    isConsultingAmount,
    consultAmountError,
  } = useSelector(state => state.sales)

  const form_type = searchParams.get('form_type')
  const productConfig = checkoutOptions?.topups?.[0]?.topups?.[0] || checkoutOptions?.topups?.[0]
  const { customer_fee } = productConfig?.configuration || {}
  const service = items?.find(item => item.slug === service_slug)
  const phoneNumber = watch('phone_number')
  const confirmPhone = watch('confirm_phone')
  const inputAmount = watch('amount')
  const selectedPlan = watch('plan')
  const optionAmount = watch('option_amount')
  const reference = watch('reference')
  const totalAmount = optionAmount?.price || inputAmount || 0
  const company = cellphonePlans?.description || checkoutOptions?.description

  useEffect(() => {
    if (!items) {
      dispatch(getMenuItems())
    }
    return () => {
      dispatch(clearCheckout())
    }
  }, [])

  useEffect(() => {
    app.setIsLoading(isLoading || isConsultingAmount)
  }, [isLoading, isConsultingAmount])

  useEffect(() => {
    // Hydrate selected product
    if(items && service_slug && slug) {
      const categoryObject = items.find(item => {
        if (service_slug === 'others') {
          return !item?.slug || item?.slug === service_slug
        } else {
          return item.slug === service_slug
        }
      })
      const productObject = categoryObject?.items?.find(item => item.slug === slug)
      const form_type = productObject?.topups?.[0]?.form_type || productObject?.form_type
      if (form_type === 'cellphone_minutes') {
        dispatch(setCellphonePlans(productObject))
      } else {
        dispatch(setCheckoutOptions(productObject))
      }
    }
  }, [items, slug, service_slug])
  
  useEffect(() => {
    const sale_id = verify_transaction?.data?.sale_id
    if (status == 'completed' && !notified_ids?.[sale_id]) {
      // console.log('Notified by request')
      dispatch(addNotificationId(sale_id))
      app.showModal({
        component: (
          <TransactionModal
            error={null}
            data={verify_transaction?.data}
          />
        ),
        onClose: () => {
          navigate('/services')
        }
      })
    } else if (status == 'error' && !notified_ids?.[sale_id]) {
      // console.log('Notified by request')
      dispatch(addNotificationId(sale_id))
      app.showModal({
        component: (
          <TransactionModal
            error={{
              title: 'No se pudo procesar la transacción',
              message: message
            }}
          />
        ),
        onClose: () => {
          navigate('/')
        }
      })
    } else {
      // console.log('Avoid notification 1')
    }
  }, [status])

  useEffect(() => {
    if (transactionError) {
      // app.closeModal()
      app.showModal({
        component: <TransactionModal error={{ message: transactionError }} title="Error"/>,
        onClose: () => {
          dispatch(clearErrors())
        }
      })
    }
  }, [transactionError])

  useEffect(() => {
    if (isNumeric(consulted_amount)) {
      setValue('amount', `${consulted_amount}`)
    }
  }, [consulted_amount])

  useEffect(() => {
    if (consultAmountError) {
      app.showModal({
        type: MODAL_TYPES.ERROR,
        title: 'Error',
        message: consultAmountError
      })
    }
  }, [consultAmountError])

  const pinesPayment = (data) => {
    if (data.phone_number && data.phone_number !== data.confirm_phone) {
      app.showModal({ type: MODAL_TYPES.ERROR, title: 'Datos erroneos', message: 'Los teléfonos no coinciden' })
    } else if (!optionAmount) {
      app.showModal({ type: MODAL_TYPES.ERROR, title: 'Error', message: 'Debes seleccionar un monto' })
    } else {
      app.showModal({ component: <TransactionInProgress /> })
      dispatch(startTransaction({
        store_id,
        sku: optionAmount?.sku,
        reference: data?.phone_number,
        receipt_phone: data?.phone_number,
        amount: 0
      }))
    }
  }

  const cellphoneMinutesPayment = (data) => {
    if (data.phone_number && data.phone_number !== data.confirm_phone) {
      app.showModal({ type: MODAL_TYPES.ERROR, title: 'Datos erroneos', message: 'Los teléfonos no coinciden' })
    } else if (!optionAmount) {
      app.showModal({ type: MODAL_TYPES.ERROR, title: 'Error', message: 'Debes seleccionar un monto' })
    } else {
      app.showModal({ component: <TransactionInProgress /> })
      dispatch(startTransaction({
        store_id,
        sku: optionAmount?.sku,
        reference: data?.phone_number,
        receipt_phone: data?.phone_number,
        amount: 0
      }))
    }
  }

  const referencedPayment = (data) => {
    if (data.amount && data.amount !== data.confirm_amount) {
      app.showModal({ type: MODAL_TYPES.ERROR, message: 'Los montos no coinciden' })
    } else {
      app.showModal({ component: <TransactionInProgress /> })
      dispatch(startTransaction({
        store_id,
        sku: checkoutOptions?.topups?.[0]?.sku || checkoutOptions?.sku,
        reference: data?.reference,
        receipt_phone: data?.phone_number,
        amount: Number(data?.amount) + customer_fee
      }))
    }
  }

  const onSubmit = (data) => {
    if (checkoutOptions?.form_type === FORM_TYPES.CELLPHONE_MINUTES) {
      cellphoneMinutesPayment(data)
    } else if (checkoutOptions?.form_type === FORM_TYPES.PINES) {
      pinesPayment(data)
    } else {
      referencedPayment(data)
    }
  }

  const confirmCheckout = () => {
    app.showModal({
      component: (
        <ConfirmationModal 
          serviceSlug={service_slug}
          onConfirm={handleSubmit(onSubmit)}
          data={{
            company,
            plan_name: selectedPlan?.label,
            totalAmount,
            phone_number: phoneNumber
          }}
        />
      ),
    })
  }

  const handleConsultAmount = () => {
    trigger('reference')
    const { balance_inquiry_sku } = productConfig?.configuration || {}
    if (!balance_inquiry_sku || !reference) {
      return
    }
    dispatch(fetchTransactionBalance({
      sku: balance_inquiry_sku,
      reference: reference
    }))
  }

  const shouldVerifyReference = !!productConfig?.configuration?.balance_inquiry_sku

  const isDisabled = () => {
    // console.log({ optionAmount, phoneNumber, confirmPhone, form_type })
    if (form_type === FORM_TYPES.CELLPHONE_MINUTES) {
      return !optionAmount || !phoneNumber || phoneNumber !== confirmPhone
    } else if (form_type === FORM_TYPES.PINES) {
      return !optionAmount || !phoneNumber || phoneNumber !== confirmPhone
    } else {
      return !inputAmount || !reference || !phoneNumber || phoneNumber !== confirmPhone
    }
  }

  return (
    <div className="container">
      <HeaderBar />
      <Container maxWidth="xl">
        <Box className="align-center see-more" my={4} component={Link} to="/services">
          <ArrowBackIcon sx={{ marginRight: '10px' }}/>
          Regresa a servicios
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={8}>
            {form_type === FORM_TYPES.PINES && (
              <Pin
                control={control} 
                watch={watch} 
                configuration={productConfig?.configuration} 
              />
            )}
            {form_type === FORM_TYPES.CELLPHONE_MINUTES && (
              <CellphoneMinutes 
                control={control} 
                watch={watch}
                setValue={setValue}
                configuration={productConfig?.configuration} 
              />
            )}
            {(form_type === FORM_TYPES.REFERENCED || !form_type) && (
              <Referenced 
                control={control} 
                watch={watch}
                trigger={trigger}
                configuration={productConfig?.configuration} 
                product={productConfig} 
                shouldVerifyReference={shouldVerifyReference}
                handleConsultAmount={handleConsultAmount}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <Box className="summary-card">
              <Box className="subtitle" mb={2}>
                Resumen
              </Box>
              <Box className="bodySmall" sx={{ color: colors.darkgrey }}>
                Servicio:
              </Box>
              <Box className="bodyLarge" mt={1} mb={2}>
                {service?.category || 'Otros'}
              </Box>
              <Box className="bodySmall" sx={{ color: colors.darkgrey }}>
                Compañía:
              </Box>
              <Box className="bodyLarge" mt={1} mb={2}>
                {company}
              </Box>
              {form_type === FORM_TYPES.CELLPHONE_MINUTES &&
                <>
                  <Box className="bodySmall" sx={{ color: colors.darkgrey }}>
                    Plan:
                  </Box>
                  <Box className="bodyLarge" mt={1} mb={2}>
                    {selectedPlan?.label}
                    {/* {optionAmount?.label} */}
                  </Box>
                </>
              }
              <Box className="bodySmall" sx={{ color: colors.darkgrey }}>
                Monto:
              </Box>
              <Box className="bodyLarge" mt={1} mb={2}>
                {toCurrency(totalAmount)}
              </Box>

              <Divider />
              
              <Box className="bodySmall" mt={2} mb={1}>
                Comisión a cobrar:
              </Box>
              <Box className="headlineSmall" sx={{ color: colors.hanpurple }}>
                {form_type === FORM_TYPES.CELLPHONE_MINUTES ? '$0.00' : toCurrency(customer_fee)}
              </Box>
              <Box className="bodySmall" my={1}>
                <b>Total a cobrar:</b>
              </Box>
              {!!totalAmount &&
                <Box className="headlineSmall" sx={{ color: colors.success }} mb={2}>
                  <b>{form_type === FORM_TYPES.CELLPHONE_MINUTES ? toCurrency(totalAmount) : toCurrency(Number(totalAmount) + customer_fee)}</b>
                </Box>
              }
              <Box pt={2}>
                <Button variant="contained" onClick={confirmCheckout} fullWidth disabled={isDisabled()}>
                  Vender
                </Button>
                {/* <Button onClick={() => app.showModal({ component: <TransactionModal data={{ description: 'AT&T Plus', auth_number: "21231232" }} />})}>
                  Success
                </Button>
                <Button onClick={() => app.showModal({ component: <TransactionModal />})}>
                  Error
                </Button> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Checkout