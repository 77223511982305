import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, IconButton, Snackbar } from '@mui/material'
import { CopyAll, Close, WhatsApp, FileDownload } from '@mui/icons-material'
import Barcode from 'react-barcode'

import { toCurrency } from 'utils/formats'
import { getPaymentInfoData } from 'store/slices/store'
import OpenPayLogos from 'assets/open-pay-logos.png'
import ShelfEmptyState from 'components/ShelfEmptyState'
// import { Share, Linking, Clipboard } from 'react-native'

function PaymentByCashDeposit() {
  const dispatch = useDispatch()
  const [toastVisible, setToastVisible] = useState(false)
  const { paymentInfo, id, shelves} = useSelector(state => state.store)
  const shelf = shelves?.[0] || {}
  const shelf_number = shelf?.shelf_number || ''

  useEffect(() => {
    dispatch(getPaymentInfoData())
  }, [])

  const bank_info = {
    amount: toCurrency(Math.abs(paymentInfo?.amount)),
    cash_reference: paymentInfo?.cash_reference
  }
  const { amount, cash_reference } = bank_info

  const share_text = `
Datos para Depósito:
Acude a: Kiosko, Walmart, Walmart Express, Soriana, 7Eleven, Bodega Aurrera, Waldos o Farmacias del Ahorro

💲 Cantidad: $${amount}
🔢 Referencia: ${cash_reference}

Instrucciones:

1. Solicita al cajero un pago de servicio Open Pay
2.Dicta la referencia o escanea el código de barras
3. Realiza el pago + la comisión del establecimiento
4.Guarda el ticket por cualquier aclaración

💡 Tip: Si vas a escanear el código súbe el brillo de tu pantalla al máximo.      
`

  const onDismissSnackBar = () => setToastVisible(false)

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(`${text}`)
      setToastVisible(true)
    } catch (error) {
      console.error('Error copying to clipboard:', error)
    }
  }

  const copyAllText = async () => {
    await navigator.clipboard.writeText(share_text)
    setToastVisible(true)
  }

  const download = async () => {
    // Download PDF
  }

  const openSupport = () => {
    const text = `Hola, necesito ayuda y este es mi identificador: ${id}`
    const phoneNumber = '+5213125933452'
    // Linking.openURL(`whatsapp://send?text=${text}&phone=${phoneNumber}`);
  }

  const CopyButton = ({ copyText }) => {
    return (
      <IconButton onClick={() => copyToClipboard(copyText)}>
        <CopyAll />
      </IconButton>
    )
  }

  if (!shelf_number) {
    return (
      <ShelfEmptyState title="Pago por Depósito" deposit />
    )
  }

  return (
    <div>
      <table width="100%">
        <tbody className="bodySmall">
          <tr>
            <td>Cantidad por Pagar (MXN)</td>
            <td className="bodyLarge">
              {amount}
              <CopyButton copyText={amount} />
            </td>
          </tr>
          <tr>
            <td>No. de Referencia</td>
            <td className="bodyLarge">
              {cash_reference}
              <CopyButton copyText={cash_reference} />
            </td>
          </tr>
        </tbody>
      </table>
      <Barcode
        value={cash_reference}
        format="CODE128"
        width={4}
        height={70}
        displayValue={false}
      />
      <Box my={2}>
        💡 <strong>Monto máximo por depósito: $10,000.00 M.N</strong>
      </Box>
      <Box>
        Puedes depositar en:
      </Box>
      <Box>
        <img src={OpenPayLogos} alt="Logos OpenPay" width="100%" />
      </Box>
      <Box className="bodyLarge">
        Instrucciones
      </Box>
      <Box className="bodySmall" my={2}>
        En tu banca en linea:
      </Box>
      <Box className="bodySmall">
        <ol>
          <li>Acude a una tienda afiliada</li>
          <li>Solicita al cajero un <strong>pago de servicio Openpay</strong></li>
          <li>Dicta la referencia</li>
          <li>Realiza tu pago + la comisión de $XX</li>
          <li><strong>Guarda tu ticket</strong> por cualquier aclaración</li>
        </ol>
      </Box>
      <Box my={3} display="flex" sx={{ gap: '20px' }}>
        <Button onClick={download} variant="contained" startIcon={<FileDownload />} sx={{ flex: 1 }}>
          Descargar
        </Button>
        <Button onClick={copyAllText} variant="contained" startIcon={<CopyAll />} color="secondary" sx={{ flex: 1 }}>
          Copiar todo
        </Button>
        <Box sx={{ flex: 1 }} />
      </Box>
      <Snackbar
        open={toastVisible}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setToastVisible(false)}
        message="Datos copiados con éxito"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setToastVisible(false)}>
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </div>
  )
}

export default PaymentByCashDeposit