import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ImageWithFallback from 'components/ImageWithFallback';

import HeaderBar from 'components/HeaderBar'
import ServiceGroupIcon from 'assets/service_group_icons'
import images from 'assets/services'
import { setCellphonePlans, setCheckoutOptions, getMenuItems } from 'store/slices/sales'
import { useAppContext } from 'providers/AppProvider'

export const ServiceImage = ({ service }) => {
  return (
    <ImageWithFallback
      source={service?.imageURL || (images?.[service?.slug] ? images[service?.slug] : null)}
      fallbackText={service?.description}
      
    />
  );
}

function Services() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const app = useAppContext()
  const { menu: { items, isLoading } } = useSelector(state => state.sales)
  const [currentMenu, setCurrentItems] = useState(null)

  useEffect(() => {
    if (!items) {
      dispatch(getMenuItems())
    }
  }, [])

  useEffect(() => {
    app.setIsLoading(isLoading)
  }, [isLoading])

  const handleServiceSelect = (items) => {
    setCurrentItems(items)
    // navigate('/service-selector')
  }

  const handleCheckout = (item) => {
    const category = currentMenu?.slug || 'others'
    // console.log('handleCheckout', { screen_name: item.screen_name, item })
    const form_type = item?.topups?.[0]?.form_type || item?.form_type
    if (form_type === 'cellphone_minutes') {
      // dispatch(setCellphonePlans(item))
      // dispatch(setCheckoutOptions(null))
      navigate(`/checkout/${category}/product/${item.slug}?form_type=cellphone_minutes`)
    } else {
      // dispatch(setCellphonePlans(null))
      // dispatch(setCheckoutOptions(item))
      navigate(`/checkout/${category}/product/${item.slug}?form_type=${form_type}`)
    }
  }

  const isServiceActive = (slug) => {
    if (currentMenu && !currentMenu?.slug && currentMenu?.slug === 'others') {
      return 'active'
    } else {
      return currentMenu?.slug === slug ? 'active' : ''
    }
  }


  return (
    <div className="container">
      <HeaderBar />
      <Container maxWidth="xl">
        <Box className="subtitle" mt={3} mb={3}>
          Pago de Servicios
        </Box>
        <Box className="services-grid">
          {items?.map((item, index) => {
            return (
              <Box key={index} className={`service-card ${isServiceActive(item?.slug)}`} sx={{ background: 'white' }} onClick={() => handleServiceSelect(item)}>
                <ServiceGroupIcon iconName={item.slug} />
                <Box className="title">
                  {item.category}
                </Box>
              </Box>
            )
          })}
        </Box>
        {currentMenu &&
          <Box py={3}>
            <Box className="subtitle" mb={3}>
              {currentMenu?.category}
            </Box>
            <Box className="services-grid">
              {currentMenu?.items?.map((item, index) => {
                return (
                  <Box 
                    key={index} 
                    className="product-card" 
                    onClick={() => handleCheckout(item)}
                  >
                    <ServiceImage service={item} />
                  </Box>
                )
              })}
            </Box>
          </Box>
        }
      </Container>
    </div>
  )
}

export default Services