import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import identitySlice from './slices/identity'
import activitySlice from './slices/activity'
import registrySlice from './slices/registry'
import shelfSlice from './slices/shelf'
import storeSlice from './slices/store'
import teamMembersSlice from './slices/teamMembers'
import salesSlice from './slices/sales'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['store'],
  blacklist: ['identity']
}

const rootReducer = combineReducers({
  identity: identitySlice,
  activity: activitySlice,
  registry: registrySlice,
  shelf: shelfSlice,
  store: storeSlice,
  teamMembers: teamMembersSlice,
  sales: salesSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }
  ),
})

export const persistor = persistStore(store)