import { Box } from '@mui/material'
import { toCurrency } from 'utils/formats'
import colors from 'styles/colors'

function FinanceDetail({ row, formattedDate, formattedTime }) {
  const additional_info = row?.additional_info ? JSON.parse(row.additional_info) : {}

  return (
    <>
      <table width="100%" className="subtable">
        <tbody>
          <tr>
            <td>Monto:</td>
            <td style={{ color: colors.oriolesred }}>
              {toCurrency(row?.amount + row?.fee)}
            </td>
          </tr>
          {additional_info?.interest_rate &&
            <tr>
              <td>
                Interés diario:
              </td>
              <td style={{ color: colors.oriolesred }}>
                {additional_info?.interest_rate}
              </td>
            </tr>
          }
          <tr>
            <td>Autorización:</td>
            <td>{row?.auth_number || 'N/A'}</td>
          </tr>
          <tr>
            <td>Fecha:</td>
            <td>{formattedDate}</td>
          </tr>
          <tr>
            <td>Hora:</td>
            <td>{formattedTime}</td>
          </tr>
        </tbody>
      </table>
      <Box py={2}>
        <Box style={{ marginBottom: 10, fontWeight: '600', color: colors.deepblue }}>
          ¿Qué significa este cargo?
        </Box>
        <Box>{row?.reference}</Box>
      </Box>
    </>
  )
}

export default FinanceDetail