import request from 'utils/request'

export const authenticate = async ({ username, password, code }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/identity/singin`,
    data: {
      user_name: username,
      password: password,
      code: code
    },
    auth: false,
  })

  return response?.data
}

export const resendOTP = async ({ username, password }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/identity/resend`,
    data: {
      user_name: username,
      password: password,
    },
    auth: false,
  })

  return response?.data
}

export const generateOTP = async ({ prospect_id }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/identity/OTP`,
    data: {
      id: prospect_id,
      type: 'sign'
    },
    auth: false,
  })

  return response?.data
}

export const validateOTP = async ({ prospect_id, code, metadata }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/identity/otp/validate`,
    data: {
      id: prospect_id,
      code: code,
      metadata
    },
    auth: false,
  })

  return response?.data
}

export const signContract = async ({ prospect_id }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/contracts/sign`,
    data: {
      prospect_id,
    },
    auth: false,
  })

  return response?.data
}

export const setAccountPassword = async ({ id, password, user_type }) => {
  try {
    const user = {
      [`${user_type}_id`]: id,
      password,
      role: user_type === 'prospect' ? 'store' : 'employee',
      business_id: id,
      user_type: user_type === 'prospect' ? 'main' : 'employee',
    }
    console.log('USER', user)
    const response = await request({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/identity/create`,
      data: user,
      auth: false,
    })
    return response?.data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
  
}

export const passwordRecover = async ({ email }) => {
  try {
    const response = await request({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/deeplink/generate`,
      data: {
        email,
        type: "MAIL_RESTOREPASSWORD"
      },
      auth: false,
    })
    return response?.data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
  
}


export const resetPassword = async ({ deeplink_id, password }) => {
  try {
    const response = await request({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/identity/reset`,
      data: {
        deeplink_id,
        new_password: password
      },
      auth: false,
    })
    return response?.data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
  
}

export const updatePassword = async ({ username, old_password, new_password, token }) => {
  try {
    const response = await request({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/identity/update`,
      data: {
        username,
        old_password,
        new_password
      },
    })
    return response?.data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
}

export const getProfile = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/identity/user`,
  })
  return response?.data
}

export const fieldValidation = async ({ field, q }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/identity/user/validate?field=${field}&q=${q}`,
  })

  return response?.data
}