import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Loading from '../../components/Loading'
import { clearStoreState } from 'store/slices/store'
import { clearIdentityState } from 'store/slices/identity'
import { clearShelfState } from 'store/slices/shelf'
import { clearActivityState } from 'store/slices/activity'
import { clearTeamMembersState } from 'store/slices/teamMembers'
import { clearVerifyTransaction } from 'store/slices/sales'

const Logout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isAuth } = useSelector(state => state.identity)

  useEffect(() => {
    dispatch(clearIdentityState())
    dispatch(clearActivityState())
    dispatch(clearStoreState())
    dispatch(clearShelfState())
    dispatch(clearTeamMembersState())
    dispatch(clearVerifyTransaction())
    localStorage.removeItem('session')
  }, [])

  useEffect(() => {
    if (!isAuth) {
      navigate('/login')
    }
  }, [navigate, isAuth])

  return <Loading loading={true} />
}

export default Logout