import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
  authenticate, 
  setAccountPassword, 
  passwordRecover, 
  resetPassword, 
  updatePassword, 
  getProfile,
  fieldValidation,
  resendOTP
} from '../../services/identity'

export const getLocalUserData = createAsyncThunk(
  'identity/getLocalUserData',
  async () => {
    const token = localStorage.getItem('session')
    if (token) {
      return token
    } else {
      throw new Error('No se está autenticado')
    }
  }
)

export const logout = createAsyncThunk(
  'identity/logout',
  async () => {
    localStorage.setItem('session', '')
    localStorage.clear()
  }
)

export const login = createAsyncThunk(
  'identity/login',
  async ({ username, password, code, biometrics }) => {
    // console.log('login', { username, password, biometrics })
    const res = await authenticate({ username, password, code })
    if (!res?.success && res?.data?.otp_required) {
      return {
        otp_required: true
      }
    } else if (res?.success && res?.data?.token) {
      localStorage.setItem('session', res?.data?.token)
      return {
        token: res?.data?.token
      }
    } else {
      throw new Error('Usuario o contraseña son incorrectos')
    }
  }
)

export const resendOtpCode = createAsyncThunk(
  'identity/resendOtpCode',
  async ({ username, password }) => {
    const res = await resendOTP({ username, password })
    if (res?.code === 200) {
      return { success: true }
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const setPassword = createAsyncThunk(
  'identity/setPassword',
  async ({ id, password, user_type }) => {
    const res = await setAccountPassword({ id, password, user_type })
    // console.log('setPassword', res)
    if (res.success) {
      return { user_type }
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const recoverPassword = createAsyncThunk(
  'identity/recoverPassword',
  async ({ email }) => {
    const res = await passwordRecover({ email })
    if (res) {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const restorePassword = createAsyncThunk(
  'identity/restorePassword',
  async ({ deeplink_id, password }) => {
    const res = await resetPassword({ deeplink_id, password })
    if (res.success) {
      return { success: res.success }
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const changePassword = createAsyncThunk(
  'identity/changePassword',
  async ({ username, old_password, new_password }, { getState }) => {
    const { token } = getState().identity
    const res = await updatePassword({ username, old_password, new_password, token })
    if (res.success) {
      return { success: res.success }
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const fetchProfile = createAsyncThunk(
  'identity/fetchProfile',
  async () => {
    const res = await getProfile()
    // console.log('fetchProfile', res)
    if (res) {
      return res
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

// export const validateField = createAsyncThunk(
//   'identity/validateField',
//   async ({ field, value }) => {
//     const res = await fieldValidation({ field, q: value })
//     if (res) {
//       return res
//     } else {
//       throw new Error('No se pudo completar el proceso')
//     }
//   }
// )

export const validateEmail = createAsyncThunk(
  'identity/validateEmail',
  async ({ email }) => {
    const res = await fieldValidation({ field: 'email', q: email })
    if (res) {
      return res
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const validatePhone = createAsyncThunk(
  'identity/validatePhone',
  async ({ phone }) => {
    console.log('validatePhone', phone)
    const res = await fieldValidation({ field: 'phone', q: phone })
    if (res) {
      return res
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

const initialState = {
  isAuth: Boolean(localStorage.getItem('session')) || null,
  token: '',
  isLoading: false,
  error: null,
  isAdmin: false,
  otpRequired: false,

  passwordIsLoading: false,
  passwordError: null,
  passwordSuccess: false,
  passwordUserType: '',
  // password: {
  //   isLoading: false,
  //   error: null,
  //   success: false,
  //   user_type: ''
  // },
  passwordRecovery: {
    isLoading: false,
    error: null,
    success: false,
  },
  passwordChange: {
    isLoading: false,
    error: null,
    success: false,
  },
  
  profileUser: null,
  profileIsLoading: false,
  profileError: null,

  phoneIsLoading: false,
  phoneError: null,
  phoneExists: null,

  emailIsLoading: false,
  emailError: null,
  emailExists: null,
  // passwordRestoring: {
  //   isLoading: false,
  //   error: null,
  //   success: false,
  // },
  passRestIsLoading: false,
  passRestError: null,
  passRestSuccess: false
}

const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    clearPasswordChange: (state) => {
      state.passwordChange.error = null
      state.passwordChange.success = false
      state.passwordChange.isLoading = false
    },
    clearFieldValidation: (state) => {
      state.phoneError = null
      state.phoneExists = null
      state.phoneIsLoading = false
      state.emailIsLoading = null
      state.emailError = null
      state.emailExists = null
      state.emailIsLoading = false
    },
    clearIdentityState: () => {
      return {
        ...initialState,
        isAuth: false,
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLocalUserData.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getLocalUserData.fulfilled, (state, action) => {
      state.isAuth = true
      state.isLoading = false
      state.error = null
      state.token = action.payload
    })
    builder.addCase(getLocalUserData.rejected, (state, action) => {
      state.isAuth = false
      state.isLoading = false
      state.error = action.error.message
    })

    builder.addCase(logout.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false
      state.isAuth = false
      state.token = ''
    })
    builder.addCase(logout.rejected, (state, action) => {
      state.isLoading = false
      state.isAuth = false
      state.error = action.error.message
    })

    builder.addCase(login.pending, (state) => {
      state.isLoading = true
      // state.otpRequired = false
    })
    builder.addCase(login.fulfilled, (state, action) => {
      console.log('login.fulfilled', action.payload)
      state.isLoading = false
      if (action.payload?.otp_required) {
        state.otpRequired = true
      } else {
        state.isAuth = true
        state.token = action.payload?.token || ''
      }
    })
    builder.addCase(login.rejected, (state, action) => {
      console.log('login.rejected', JSON.stringify(action.error))
      state.isLoading = false
      state.isAuth = false
      if (action?.error?.code === 'ERR_BAD_RESPONSE') {
        state.error = 'Algo salió mal, por favor intenta de nuevo'
      } else if (action?.error?.code === 'ERR_BAD_REQUEST') {
        state.error = 'Usuario o contraseña son incorrectos'
      } else {
        state.error = action.error.message
      }
    })

    builder.addCase(resendOtpCode.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(resendOtpCode.fulfilled, (state) => {
      state.error = null
      state.isLoading = false
    })
    builder.addCase(resendOtpCode.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    builder.addCase(setPassword.pending, (state) => {
      state.passwordIsLoading = true
      state.passwordSuccess = false
      state.passwordError = null
      state.passwordUserType = ''
    })
    builder.addCase(setPassword.fulfilled, (state, action) => {
      console.log('setPassword.fulfilled', action.payload)
      state.passwordIsLoading = false
      state.passwordSuccess = true
      state.passwordUserType = action.payload.user_type
    })
    builder.addCase(setPassword.rejected, (state, action) => {
      console.log('setPassword', action)
      state.passwordIsLoading = false
      state.passwordError = action.error.message
    })

    builder.addCase(recoverPassword.pending, () => {
      return {
        passwordRecovery: {
          success: false,
          isLoading: true
        } 
      }
    })
    builder.addCase(recoverPassword.fulfilled, (state, action) => {
      console.log('recoverPassword.fulfilled', action.payload)
      return {
        passwordRecovery: {
          isLoading: false,
          error: null,
          success: true,
        } 
      }
    })
    builder.addCase(recoverPassword.rejected, (state, action) => {
      console.log('recoverPassword', action)
      return {
        passwordRecovery: {
          isLoading: false,
          error: action.error.message,
          success: false
        } 
      }
    })

    builder.addCase(restorePassword.pending, (state) => {
      state.passRestError = null
      state.passRestSuccess = false
      state.passRestIsLoading = true
    })
    builder.addCase(restorePassword.fulfilled, (state, action) => {
      console.log('restorePassword.fulfilled', action.payload)
      state.passRestError = null
      state.passRestSuccess = true
      state.passRestIsLoading = false
    })
    builder.addCase(restorePassword.rejected, (state, action) => {
      console.log('restorePassword', action)
      state.passRestError = action.error.message
      state.passRestSuccess = false
      state.passRestIsLoading = false
    })

    builder.addCase(changePassword.pending, (state) => {
      state.passwordChange.error = null
      state.passwordChange.success = false
      state.passwordChange.isLoading = true
    })
    builder.addCase(changePassword.fulfilled, (state, action) => {
      console.log('changePassword.fulfilled', action.payload)
      state.passwordChange.error = null
      state.passwordChange.success = true
      state.passwordChange.isLoading = false
    })
    builder.addCase(changePassword.rejected, (state, action) => {
      console.log('changePassword', action)
      state.passwordChange.error = action.error.message
      state.passwordChange.success = false
      state.passwordChange.isLoading = false
    })

    builder.addCase(fetchProfile.pending, (state) => {
      state.profileIsLoading = true
      state.profileError = null
    })
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
      const user = payload?.data
      // console.log('fetchProfile.fulfilled', user)
      state.isAdmin = user?.rol === 'store'
      state.profileIsLoading = false
      state.profileError = null
      state.profileUser = user
    })
    builder.addCase(fetchProfile.rejected, (state, action) => {
      // console.log('fetchProfile', action)
      state.profileIsLoading = false
      state.profileError = action.error.message
    })

    builder.addCase(validateEmail.pending, (state) => {
      state.emailIsLoading = true
      state.emailIsLoading = null
      state.emailExists = null
    })
    builder.addCase(validateEmail.fulfilled, (state, { payload }) => {
      console.log('validateEmail.fulfilled', payload)
      state.emailIsLoading = false
      state.emailError = payload?.exists && 'El correo ya ha sido utilizado por otro usuario'
      state.emailExists = payload?.exists
    })
    builder.addCase(validateEmail.rejected, (state, action) => {
      console.log('validateEmail', action)
      state.emailIsLoading = false
      state.emailError = action.error.message
      state.emailExists = null
    })

    builder.addCase(validatePhone.pending, (state) => {
      state.phoneIsLoading = true
      state.phoneError = null
      state.phoneExists = null
    })
    builder.addCase(validatePhone.fulfilled, (state, { payload }) => {
      console.log('validatePhone.fulfilled', payload)
      state.phoneIsLoading = false
      state.phoneError = payload?.exists && 'El telefono ya ha sido utilizado por otro usuario'
      state.phoneExists = payload?.exists
    })
    builder.addCase(validatePhone.rejected, (state, action) => {
      console.log('validatePhone', action)
      state.phoneIsLoading = false
      state.phoneError = action.error.message
      state.phoneExists = null
    })


    // builder.addCase(validateField.pending, (state, { meta }) => {
    //   if (meta?.arg?.field === 'phone') {
    //     state.phoneIsLoading = true
    //     state.phoneError = null
    //     state.phoneExists = null
    //   } else if (meta?.arg?.field === 'email') {
    //     state.emailIsLoading = true
    //     state.emailIsLoading = null
    //     state.emailExists = null
    //   }
    // })
    // builder.addCase(validateField.fulfilled, (state, { payload, meta }) => {
    //   console.log('validateField.fulfilled', payload, meta?.arg)
    //   if (meta?.arg?.field === 'phone') {
    //     state.phoneValidation.isLoading = false
    //     state.phoneError = payload?.exists && 'El telefono ya ha sido utilizado por otro usuario'
    //     state.phoneExists = payload?.exists
    //   }
    //   if (meta?.arg?.field === 'email') {
    //     state.emailIsLoading = false
    //     state.emailIsLoading = payload?.exists && 'El correo ya ha sido utilizado por otro usuario'
    //     state.emailExists = payload?.exists
    //   }
    // })
    // builder.addCase(validateField.rejected, (state, action) => {
    //   console.log('validateField', action)
    //   if (action.meta.arg.field === 'phone') {
    //     state.phoneValidation.isLoading = false
    //     state.phoneValidation.error = action.error.message
    //     state.phoneValidation.exists = null
    //   } else if (action.meta.arg.field === 'email') {
    //     state.emailIsLoading = false
    //     state.emailValidation.error = action.error.message
    //     state.emailValidation.exists = null
    //   }
    // })
  },
})

export const {
  clearPasswordChange,
  clearFieldValidation,
  clearIdentityState
} = identitySlice.actions
export default identitySlice.reducer