import { Box, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import colors from 'styles/colors'
import { filterActivities, clearFilter } from 'store/slices/activity'

function FiltersModal({ onDismiss }) {
  const dispatch = useDispatch()
  const { current_filter } = useSelector(state => state.activity)
  const [dateRange, setDateRange] = useState('')
  const [transactionTypes, setTransactionsType] = useState([])

  const dateIsSelected = (range) => dateRange === range

  const toggleTransactionType = (type) => {
    if (transactionTypes.includes(type)) {
      setTransactionsType(prev => prev.filter(t => t !== type))
    } else {
      setTransactionsType(prev => [...prev, type])
    }
  }

  useEffect(() => {
    if (current_filter) {
      setDateRange(current_filter?.date_range || '')
      if (current_filter?.transaction_types?.length > 0) {
        setTransactionsType(current_filter?.transaction_types)
      }
    }
  }, [])

  const handleApplyFilters = () => {
    dispatch(filterActivities({ date_range: dateRange, transaction_types: transactionTypes }))
    onDismiss()
  }

  const handleClearFilters = () => {
    dispatch(clearFilter())
    setDateRange('')
    setTransactionsType([])
  }

  return (
    <Box px={4} pb={4} pt={2}>
      <Box className="flex-row">
        <Box className="title hanpurple">
          Filtrar transacciones
        </Box>
        <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      
      <Box my={3}>
        <b>Por rango de fechas</b>
      </Box>
      <Box display="flex" sx={{ gap: '10px' }}>
        <Button size="small" variant="outlined" sx={{ backgroundColor: dateIsSelected('last_7_days') ? colors.lighthan : 'transparent' }} onClick={() => setDateRange('last_7_days')}>
          Últimos 7 días
        </Button>
        <Button size="small" variant="outlined" sx={{ backgroundColor: dateIsSelected('last_15_days') ? colors.lighthan : 'transparent' }} onClick={() => setDateRange('last_15_days')}>
          Últimos 15 días
        </Button>
        <Button size="small" variant="outlined" sx={{ backgroundColor: dateIsSelected('last_30_days') ? colors.lighthan : 'transparent' }} onClick={() => setDateRange('last_30_days')}>
          Últimos 30 días
        </Button>

      </Box>
      <Box my={3}>
        <b>Por tipo de transacción</b>
      </Box>

      <Box pl={2}>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={transactionTypes.includes('digital_sale')} onClick={() => toggleTransactionType('digital_sale')} />} label="Ventas recargas y pagos de servicio" />
          <FormControlLabel control={<Checkbox checked={transactionTypes.includes('physical_sale')} onClick={() => toggleTransactionType('physical_sale')} />} label="Ventas reconocidas producto físico " />
          <FormControlLabel control={<Checkbox checked={transactionTypes.includes('finantial')} onClick={() => toggleTransactionType('finantial')} />} label="Abonos y Cargos" />
        </FormGroup>
      </Box>

      <Box pt={2} className="flex-row" sx={{ gap: '20px', justifyContent: 'flex-end' }}>
        <Button size="small" onClick={handleClearFilters}>
          Limpiar filtros
        </Button>
        <Button size="small" variant="contained" onClick={handleApplyFilters} disabled={!dateRange && !transactionTypes.length > 0}>
          Aplicar filtros
        </Button>
      </Box>
      
    </Box>
  )
}

export default FiltersModal