import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import Input from 'components/Input'
import { getReceiptById } from 'store/slices/shelf'

function ReceiptModal({ onDismiss }) {
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const [codeError, setCodeError] = useState('')
  const { receipt: { id: receipt_id } } = useSelector(state => state.shelf)
  
  const onSubmit = (data) => {
    if (data?.receipt_code === data?.confirm_receipt_code) {
      dispatch(getReceiptById({ consignment_id: data?.receipt_code }))
      setCodeError('')
    } else {
      setCodeError('Los códigos deben de coincidir')
    }
  }

  useEffect(() => {
    if (receipt_id) {
      window.location = `/receipt/${receipt_id}`
    }
  }, [receipt_id])

  return (
    <Box px={4} pb={4} pt={2} className="confirmation-modal">
      <Box mb={3} className="flex-row">
        <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box className="title" mb={2}>
        Ingresa el código
      </Box>
      <Box className="paragraph" mb={3}>
        Pide al proveedor o al embajador que te dicten o muestren el código que les aparece en su aplicación.
      </Box>
      <Box>
        <Input
          name="receipt_code"
          label="Ingresa código"
          control={control}
          fullWidth
          rules={{ 
            required: true,
            pattern: {
              value: /^[0-9]*$/,
              message: 'Solo se permiten números'
            }
          }}
        />
        <Input
          name="confirm_receipt_code"
          label="Confirma código"
          control={control}
          fullWidth
          rules={{ 
            required: true,
            pattern: {
              value: /^[0-9]*$/,
              message: 'Solo se permiten números'
            }
          }}
        />
      </Box>
      <Box className="field-error" mt={2}>
        {codeError}
      </Box>
      <Box mt={1} className="flex-row" sx={{ justifyContent:'flex-end', gap: '20px' }}>
        <Button onClick={onDismiss}>Cancelar</Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>Confirmar</Button>
      </Box>
    </Box>
  )
}

export default ReceiptModal