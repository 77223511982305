import { Box, Button } from '@mui/material'
import { Bolt } from '@mui/icons-material'

import colors from 'styles/colors'
import ShelfImage from 'assets/shelf-image.png'

function ShelfEmptyState() {
  return (
    <div className="shelf-empty-state">
      <Box className="main text-center" p={3} mb={3}>
        <img src={ShelfImage} alt="Anaquel" />
        <Box className="headlineSmall" my={2}>
          <b>Exclusivo para tiendas con anaquel.</b>
        </Box>
        <p>Para realizar depósitos en efectivo, debes contar con Anaquel de Comouna.</p>
      </Box>
      <Box className="note" p={2} mb={3}>
        <Bolt sx={{ color: colors.hanpurple }} /> El Anaquel Comouna no tiene costo y te permite ofrecer en tienda productos locales a consignación, pagando sólo lo que vendes.
      </Box>
      <Box display="flex" sx={{ gap: '20px' }} mb={1}>
        <Button variant="contained" sx={{ flex: 1 }} onClick={() => window.location.href = 'https://t.me/Comouna'}>
          Solicitar instalación
        </Button>
        <Button variant="contained" sx={{ flex: 1 }} color="secondary" onClick={() => window.location.href = 'https://www.comouna.mx/anaquel-comouna'}>
          Conocer más
        </Button>
        <Box sx={{ flex: 1 }} />
      </Box>
    </div>
  )
}

export default ShelfEmptyState