import { DateTime } from 'luxon';

export const isValidDate = (dateString) => {
  return DateTime.fromFormat(dateString, 'dd/MM/yyyy', { zone: 'utc' }).isValid
}

export const isValidFutureDate = (dateString) => {
  return DateTime.now().startOf('day') <= DateTime.fromFormat(dateString, 'dd/MM/yyyy').startOf('day')
}

export const isNumeric = (value) => {
  return /^[0-9]+$/.test(value)
}

export const isPhoneNumber = () => {

}

// export const minLength = (value, length) => {
//   const pattern = new RegExp(`^.{${length}}$`)
//   return pattern.test(value)
// }

export const minLength = (value, min_length) => {
  return min_length && value?.length >= min_length
}

export const maxLength = (value, max_length) => {
  return max_length && value?.length <= max_length
}

export const containsUppercase = (value) => {
  return /^(?=.*[A-Z]).+$/.test(value)
}

export const containsLowercase = (value) => {
  return /^(?=.*[a-z]).+$/.test(value)
}

export const containsNumber = (value) => {
  return /^(?=.*[0-9]).+$/.test(value)
}
