import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box, InputAdornment, IconButton, Button } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import Input from 'components/Input'
import PasswordRule from 'components/PasswordRule'
import { containsUppercase, containsLowercase, containsNumber, minLength } from 'utils/validations'
import { savePassword } from '../../../store/slices/registry'
import { setPassword } from 'store/slices/identity'
import Logo from 'assets/logo.svg'

function CreatePassword({ setStep, setLoading, setError }) {
  const dispatch = useDispatch()
  const { handleSubmit, watch, control } = useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const { 
    passwordIsLoading,
    passwordError,
    passwordSuccess,
    // passwordUserType,
  } = useSelector(state => state.identity)
  const { prospect_id } = useSelector(state => state.registry)

  const password = watch('password')
  const passwordConfirm = watch('confirm_password')

  const passwordsMatch = password === passwordConfirm
  const passwordComplex = containsUppercase(password) && containsLowercase(password) && containsNumber(password)

  useEffect(() => {
    setLoading(passwordIsLoading)
  }, [passwordIsLoading])

  // Esto debe ir en otra ruta que se llame create_password como en la web app del vendor
  // useEffect(() => {
  //   if(id) {
  //     console.log('route.params.id', id)
  //     dispatch(getEmployeeId({ temp_id: id }))
  //   }
  // }, [])
  
  // Esto debe ir en otra ruta que se llame create_password como en la web app del vendor
  // useEffect(() => {
  //   if(employee_id) {
  //     dispatch(getEmployee({ employee_id }))
  //   }
  // }, [employee_id])

  useEffect(() => {
    if (passwordSuccess) {
      dispatch(savePassword({ password }))
      setStep(3) // Team member registration
      // Esto debe ir en otra ruta que se llame create_password como en la web app del vendor
      // if (user_type === 'prospect') {
      //   // navigation.navigate('TeamMemberRegister')
      // } else {
      //   if (employee?.phone_number) {
      //     // console.log('login', employee?.phone_number, password)
      //     // navigation.navigate('Login') // Avoid automatic login after registration
      //     navigate('/login')
      //   } else {
      //     setError('No se pudimos encontrar al usuario')
      //   }
      // }
    }
  }, [passwordSuccess])

  const onSubmit = async (data) => {
    setLoading(true)
    if (containsUppercase(data.password) && containsLowercase(data.password) && containsNumber(data.password)) {
      // Esto debe ir en otra ruta que se llame create_password como en la web app del vendor
      // if (employee_id) { 
      //   dispatch(setPassword({ id: employee_id, password, user_type: 'employee' }))
      // } else {
      // }
      dispatch(setPassword({ id: prospect_id, password, user_type: 'prospect' }))
    }
    setLoading(false)
  }

  return (
    <Box width='100%' maxWidth={350} className="align-center flex-column">
      <img src={Logo} alt="Logo Comouna" width="270px" />
      <Box sx={{ textAlign: 'left' }} width="100%" mt={4} mb={2}>
        <span className="password-title">Crea tu contraseña</span>
      </Box>
      <Input
        label="Contraseña"
        name="password"
        control={control}
        rules={{
          required: true,
          validate: {
            minLength: value => minLength(value, 8) || 'Debe contener al menos 8 caracteres',
          }
        }}
        type={showPassword ? 'text' : 'password'}
        fullWidth
        autoFocus
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(prev => !prev)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      <Input
        label="Confirmar Contraseña"
        name="confirm_password"
        control={control}
        rules={{
          required: true,
          validate: {
            minLength: value => minLength(value, 8) || 'Debe contener al menos 8 caracteres',
          }
        }}
        type={showPassword2 ? 'text' : 'password'}
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword2(prev => !prev)}
            >
              {showPassword2 ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      <Box className="rules-wrapper" width="100%">
        {(!passwordsMatch || passwordError) && 
          <>
            <span className="bodyLarge">Error</span>
            {!passwordsMatch && (
              <PasswordRule
                password={'password'}
                validation={() => false}
                message="Las contraseñas no coinciden"
              />
            )}
            {passwordError && (
              <PasswordRule
                password={'password'}
                validation={() => false}
                message="Error del sistema, intente de nuevo"
              />
            )}
          </>
        }
        <Box className="bodyLarge">Debe contener</Box>
        <PasswordRule
          password={password}
          validation={() => minLength(password, 8)}
          message="al menos 8 caracteres"
        />
        <PasswordRule
          password={password}
          validation={containsUppercase}
          message="al menos una mayúscula"
        />
        <PasswordRule
          password={password}
          validation={containsLowercase}
          message="al menos una minúscula"
        />
        <PasswordRule
          password={password}
          validation={containsNumber}
          message="al menos un número"
        />
      </Box>
      
      <Box mb={3} width="100%">
        <Button
          className='btn-main' 
          onClick={handleSubmit(onSubmit)} 
          fullWidth 
          variant="contained"
          disabled={!passwordComplex || !passwordsMatch}
        >
          Crear cuenta
        </Button>
      </Box>
    </Box>
  )
}

export default CreatePassword