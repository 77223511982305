import { Box, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import CircleCheck from 'assets/CircleCheck.svg'
import { login } from 'store/slices/identity'
import { useEffect } from 'react'

function Congratulations({ setLoading }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isAuth, isLoading } = useSelector(state => state.identity)
  const { username, password } = useSelector(state => state.registry)

  useEffect(() => {
    if (isAuth) {
      navigate('/')
    }
  }, [isAuth])

  useEffect(() => {
    if (isLoading) {
      setLoading(isLoading)
    }
  }, [isLoading])


  return (
    <Box width='100%' maxWidth={350} className="align-center flex-column">
      <Box mb={3}>
        <img src={CircleCheck} alt=""/>
      </Box>
      <h1 className="title-success">
        ¡Felicidades! <br />La configuración de tu cuenta está completa.
      </h1>
      <Box mt={7}>
        Desde ahora tendrás:
        <br />
        <br />
        <ul>
          <li>Acceso inmediato a servicios de recarga y pago de servicios para atraer clientes.</li>
          <li>Oportunidad de ganar comisiones desde el primer día.</li>
          <li>Próxima instalación del anaquel para aumentar tus ganancias con productos locales.</li>
          <li>Promoción de la economía local y apoyo a negocios de tu comunidad.</li>
        </ul>
      </Box>
      <Box mt={8} mb={5} width="100%">
        <Button onClick={() => dispatch(login({ username, password }))} fullWidth variant="contained">
          Ingresar
        </Button>
      </Box>
    </Box>
  )
}

export default Congratulations