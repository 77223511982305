import UAParser from 'ua-parser-js'
import { v4 as uuidv4 } from 'uuid'
import * as localForage from 'localforage'

export const stringToColor = (string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const stringAvatar = (name) => {
  if (!name) return null
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export const getUserArchitecture = async () => {
  const parser = new UAParser()
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  
  const getClientCpuArchitecture = async () => {
    if (navigator.userAgentData) {
      return navigator.userAgentData.getHighEntropyValues(['architecture']).then(ua => {
        return ua.architecture
      })
    } else {
      const userAgent = navigator.userAgent;
      let architecture = 'Unknown';
  
      if (userAgent.indexOf('WOW64') !== -1 || userAgent.indexOf('Win64') !== -1) {
        architecture = 'x64';
      } else if (userAgent.indexOf('Win32') !== -1) {
        architecture = 'x86';
      } else if (userAgent.indexOf('Macintosh') !== -1) {
        architecture = 'x86_64'; // Most modern Macs use x86_64
      } else if (userAgent.indexOf('arm') !== -1) {
        architecture = 'ARM';
      }
  
      return architecture
    }
  }
  
  const arch = await getClientCpuArchitecture() // x64, x86, ARM, Unknown
  const userArch = parser.getResult()

  return ({
    ...userArch,
    cpu: {
      architecture: arch
    },
    screen: {
      resolution: screenResolution
    }
  })
}

export const getDeviceId = async () => {
  try {
    const device_id = await localForage.getItem('device_id') || ''
    if (!device_id) {
      const id = uuidv4()
      await localForage.setItem('device_id', id)
      return id
    }
    return device_id
  } catch (err) {
    console.error(err)
    return ''
  }
}