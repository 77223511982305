import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'

import Input from '../../components/Input'
import { login, resendOtpCode } from 'store/slices/identity'
import Loading from '../../components/Loading'
import Logo from 'assets/logo.svg'
import './login.scss'

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { control, handleSubmit, watch, reset } = useForm()
  const { isAuth, isLoading, error, otpRequired } = useSelector((state) => state.identity) 
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const watchCode = watch('code')
  const [countdown, setCountdown] = useState(30) // Set the initial countdown value
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    let timer
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    } else {
      setIsDisabled(false)
    }
    return () => clearTimeout(timer)
  }, [countdown])

  useEffect(() => {
    if (isAuth) {
      navigate('/')
    }
  }, [isAuth, navigate])

  useEffect(() => {
    if (otpRequired) {
      reset()
    }
  }, [otpRequired])

  const onSubmit = async (data) => {
    setPassword(data.password)
    setUsername(data.username)
    dispatch(login({ username: data.username, password: data.password }))
  }

  const handleOtp = async (data) => {
    dispatch(login({ username, password, code: data.code }))
  }

  const handleResendOtp = async () => {
    dispatch(resendOtpCode({ username, password }))
    setCountdown(30)
    setIsDisabled(true)
  }

  return (
    <Box className="align-center justify-center" minHeight="100vh">
      <Loading loading={isLoading} />
      <Box maxWidth={350} className="align-center flex-column">
        <Box mb={4}>
          <img src={Logo} alt="Logo Comouna" width="270px" />
        </Box>

        {!otpRequired ? (
          <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3} width="100%">
              <span className="password-title">Iniciar sesión</span>
            </Box>
            <Input
              name="username"
              label="Teléfono"
              control={control}
              rules={{ required: true }}
              fullWidth
              autoFocus
            />
            
            <Input
              name="password"
              label="Contraseña"
              type="password"
              control={control}
              rules={{ required: true }}
              fullWidth
            />

            {error && (
              <div className="container-error-login">
                <div><CloseIcon/></div>
                <div>
                    <span>Credenciales inválidas.</span>
                </div>
              </div>
            )}

            <Box className="button-section" mt={3}>
              <Button className="btn-main" fullWidth variant="contained" type="submit">
                Iniciar sesion
              </Button>
              <Button onClick={() => navigate('/password_recovery')} fullWidth variant="text" sx={{ marginY: '15px' }}>
                ¿Olvidaste tu contraseña? Recupérala
              </Button>
            </Box>
            {process.env.REACT_APP_ENV &&
              <div className="text-center">
                <p>{process.env.REACT_APP_ENV}</p>
              </div>
            }
          </Box>
        ) : (
          <Box noValidate component="form" onSubmit={handleSubmit(handleOtp)}>
            <Box mb={3} width="100%">
              <span className="password-title">
                Ingresa el código que te enviamos por WhatsApp 
              </span>
            </Box>
            <Input
              name="code"
              label="Código de verificación"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              fullWidth
              autoFocus
            />
            {error && (
              <div className="container-error-login">
                <div><CloseIcon/></div>
                <div>
                    <span>
                      Ha ocurrido un error. Por favor, intenta de nuevo.
                    </span>
                </div>
              </div>
            )}
            <Box className="button-section" mt={3}>
              <Button className="btn-main" fullWidth variant="contained" type="submit" sx={{ mb: 2 }} disabled={!watchCode}>
                Verificar
              </Button>
              <Button variant="text" fullWidth onClick={handleResendOtp} disabled={isDisabled}>
                Reenviar código {isDisabled && `(${countdown}s)`}
              </Button>
            </Box>
          </Box>
        )}
      </Box>

    </Box>
  )
}

export default LoginPage