import { Box } from '@mui/material'

// import PlaceholderProduct from 'assets/PlaceholderProduct'
import { toCurrency } from 'utils/formats'
import colors from 'styles/colors'

function ProductCard({ product }) {
  const {
    producer_name,
    short_name,
    tray,
    retail_price,
    store_cost,
    total_amount,
    unit_revenue,
    revenue,
    sku,
  } = product

  return (
    <div className="product-detail">
      <table width="100%" className="subtable">
        <tbody className="bodyMedium"> 
          <tr>
            <td>Proveedor:</td>
            <td>{producer_name}</td>
          </tr>
          <tr>
            <td>Nivel:</td>
            <td>{tray}</td>
          </tr>
          <tr>
            <td>Precio:</td>
            <td>{toCurrency(retail_price)}</td>
          </tr>
          <tr>
            <td>Precio tienda:</td>
            <td>{toCurrency(store_cost)}</td>
          </tr>
          <tr>
            <td>Ganancia por pieza:</td>
            <td>{toCurrency(unit_revenue)}</td>
          </tr>
          <tr>
            <td style={{ color: colors.success }}>
              Ganancia total*:
            </td>
            <td>{toCurrency(revenue)}</td>
          </tr>
          <tr>
            <td style={{ color: colors.deepblue }}>
              Costo total tienda*:
            </td>
            <td>{toCurrency(total_amount)}</td>
          </tr>
        </tbody>
      </table>
      <Box className="bodySmall" my={2}>
        *Ganancia/Costo sobre el total de productos recibidos.
      </Box>
    </div>
  )
}

export default ProductCard