import { DateTime, Settings, Info } from 'luxon'
Settings.defaultLocale = 'es-ES'

export const toCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  
  return formatter.format(amount)
}

export const formatDate = (date) => date ? DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('yyyy-MM-dd') : ''

export const formatPaymentDate = (payment_day) => {
  const currentDate = DateTime.now()
  if (currentDate.weekday === payment_day + 1) {
    const targetWeekday = payment_day - 1
    const daysUntilTarget = (targetWeekday - currentDate.weekday + 7) % 7
    const nextDate = currentDate.plus({ days: daysUntilTarget })
    const payment_date = DateTime.fromFormat(nextDate.toISODate(),'yyyy-MM-dd').toFormat('dd/MM/yyyy')
    return payment_date
  } else {
    return currentDate.toFormat('dd/MM/yyyy')
  }
}

export const formatDateAndTime = (date) => {
  return {
    date: DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat('dd/MM/yyyy'),
    time: DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat('HH:mm:ss')
  }
}

export const formatDay = (day) => {
  return Info.weekdays('long')[day - 1]
}

export const formatMedDate = (date) => {
  if (!date) return ''
  return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATE_MED)
}

export const latestDateFormattedOrCurrent = (date1) => {
  const currentDate = DateTime.now()
  const systemDate = DateTime.fromISO(date1, { zone: 'utc' }).toLocal()
  const formattedNextPaymentDate = systemDate.toFormat('dd/MM/yyyy')
  const displayDate = systemDate < currentDate ? currentDate.toFormat('dd/MM/yyyy') : formattedNextPaymentDate
  return displayDate
}

export const longFullDate = (date) => {
  if (!date) return ''
  return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATE_FULL)
}
