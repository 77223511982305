function InternetAndTvIcon(props) { 
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6339 58.383H68.6314C71.1577 57.0595 71.1577 56.2065 71.1577 53.6477C70.3156 53.6477 15.5788 53.6477 14.6339 53.6477V17.5026C14.6339 17.0146 14.7753 16.5314 15.0498 16.0806C15.3244 15.6298 15.7269 15.2202 16.2342 14.8752C16.7416 14.5302 17.3439 14.2566 18.0067 14.07C18.6696 13.8834 19.38 13.7874 20.0973 13.7876H14.2437C13.5263 13.7874 12.8159 13.8834 12.1531 14.07C11.4902 14.2566 10.8879 14.5302 10.3806 14.8752C9.87324 15.2202 9.47078 15.6298 9.19619 16.0806C8.92161 16.5314 8.78028 17.0146 8.78028 17.5026V54.3707C8.7787 55.4299 9.39349 56.4464 10.4903 57.1982C11.5872 57.95 13.0769 58.376 14.6339 58.383Z" fill="#E9A9C1"/>
      <path d="M27.1219 16.9727H12.6828C12.4758 16.9727 12.2773 17.0566 12.1309 17.2059C11.9846 17.3552 11.9023 17.5578 11.9023 17.769V53.7373C11.9023 53.9485 11.9846 54.1511 12.1309 54.3004C12.2773 54.4497 12.4758 54.5336 12.6828 54.5336H67.317C67.524 54.5336 67.7225 54.4497 67.8689 54.3004C68.0152 54.1511 68.0975 53.9485 68.0975 53.7373V17.769C68.0975 17.5578 68.0152 17.3552 67.8689 17.2059C67.7225 17.0566 67.524 16.9727 67.317 16.9727H52.878V18.5653H66.5365V52.941H13.4633V18.5653H27.1219V16.9727Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M57.6078 47.0215C57.7103 47.0217 57.8118 47.0011 57.9065 46.9611C58.0013 46.9211 58.0873 46.8624 58.1597 46.7883L60.4783 44.4227C60.6247 44.2733 60.7069 44.0707 60.7069 43.8595C60.7068 43.6483 60.6246 43.4458 60.4782 43.2964C60.3318 43.1471 60.1333 43.0632 59.9263 43.0632C59.7193 43.0632 59.5208 43.1471 59.3744 43.2965L57.0558 45.6624C56.9467 45.7738 56.8724 45.9157 56.8423 46.0702C56.8122 46.2246 56.8277 46.3848 56.8868 46.5303C56.9458 46.6757 57.0459 46.8001 57.1742 46.8876C57.3025 46.9751 57.4534 47.0215 57.6078 47.0215Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M58.178 50.2358C57.971 50.2358 57.7725 50.1519 57.6261 50.0025C57.5536 49.9286 57.4961 49.8408 57.4569 49.7442C57.4177 49.6475 57.3975 49.544 57.3975 49.4394C57.3975 49.3348 57.4177 49.2312 57.4569 49.1346C57.4961 49.038 57.5536 48.9502 57.6261 48.8762L62.5239 43.8789C62.6703 43.7295 62.8689 43.6456 63.0759 43.6456C63.2829 43.6456 63.4814 43.7295 63.6278 43.8789C63.7742 44.0283 63.8564 44.2308 63.8564 44.442C63.8564 44.6533 63.7742 44.8558 63.6278 45.0052L58.7299 50.0025C58.5835 50.1519 58.385 50.2358 58.178 50.2358Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M17.325 36.2363C17.532 36.2363 17.7305 36.1524 17.8769 36.0031C18.0232 35.8538 18.1055 35.6512 18.1055 35.44V25.3787C18.1055 25.1675 18.0232 24.9649 17.8769 24.8156C17.7305 24.6663 17.532 24.5824 17.325 24.5824C17.118 24.5824 16.9195 24.6663 16.7731 24.8156C16.6267 24.9649 16.5445 25.1675 16.5445 25.3787V35.44C16.5445 35.6512 16.6267 35.8538 16.7731 36.0031C16.9195 36.1524 17.118 36.2363 17.325 36.2363Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M17.8769 23.2367C17.7305 23.3861 17.532 23.47 17.325 23.47C17.118 23.47 16.9195 23.3861 16.7731 23.2367C16.6267 23.0874 16.5445 22.8848 16.5445 22.6736V22.0321C16.5445 21.8208 16.6267 21.6183 16.7731 21.4689C16.9195 21.3196 17.118 21.2357 17.325 21.2357C17.532 21.2357 17.7305 21.3196 17.8769 21.4689C18.0232 21.6183 18.1055 21.8208 18.1055 22.0321V22.6736C18.1055 22.8848 18.0232 23.0874 17.8769 23.2367Z" fill={props?.color || '#531AFF'} className="border"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4228 12.9902H32.6504V14.5829H12.4228C11.6642 14.5834 10.9368 14.891 10.4003 15.4381C9.86372 15.9853 9.56187 16.7273 9.56098 17.5013V54.3696C9.56077 55.2173 9.88867 56.0308 10.4733 56.6331C11.058 57.2354 11.8521 57.5777 12.6829 57.5856H67.5772C68.3391 57.5778 69.0671 57.2635 69.603 56.711C70.1389 56.1584 70.4393 55.4124 70.439 54.635V17.5013C70.4381 16.7273 70.1363 15.9853 69.5997 15.4381C69.0632 14.891 68.3358 14.5834 67.5772 14.5829H47.0244V12.9902H67.5772C68.7497 12.9912 69.8738 13.4668 70.703 14.3125C71.5322 15.1582 71.9986 16.305 72 17.5013V54.635C71.9999 55.8346 71.5348 56.9855 70.7062 57.8366C69.8777 58.6878 68.7529 59.17 67.5772 59.1783H47.2163L48.2261 64.62H50.2382C51.0064 64.6318 51.7397 64.9494 52.2815 65.5052C52.8233 66.0609 53.1308 66.8108 53.1382 67.5946V68.2052C53.1294 68.4201 53.0391 68.6232 52.8864 68.7715C52.7336 68.9198 52.5303 69.0017 52.3195 68.9999H27.6805C27.4697 69.0017 27.2664 68.9198 27.1136 68.7715C26.9609 68.6232 26.8706 68.4201 26.8618 68.2052V67.5946C26.8692 66.8108 27.1767 66.0609 27.7185 65.5052C28.2603 64.9494 28.9936 64.6318 29.7618 64.62H31.6438L32.6537 59.1783H12.6829C11.4383 59.17 10.2473 58.6597 9.37002 57.7588C8.49271 56.8579 8.00021 55.6396 8 54.3696V17.5013C8.00138 16.305 8.46784 15.1582 9.29701 14.3125C10.1262 13.4668 11.2503 12.9912 12.4228 12.9902ZM51.5262 67.4072H28.4738C28.5125 67.0837 28.6638 66.7851 28.9002 66.5659C29.1365 66.3466 29.4422 66.2213 29.7616 66.2127H50.2382C50.5576 66.2212 50.8633 66.3465 51.0997 66.5658C51.3362 66.785 51.4875 67.0836 51.5262 67.4072ZM46.6382 64.62H33.2317L34.2416 59.1783H45.6284L46.6382 64.62Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M55.4298 17.0363C51.3585 13.0838 45.8718 10.9359 39.985 11.0015C34.1344 11.0621 28.6595 13.2828 24.5701 17.2543C24.002 17.8052 23.9819 18.7201 24.5243 19.2964C25.0674 19.8735 25.9675 19.8939 26.5356 19.3423C30.102 15.8801 34.8886 13.943 40.0149 13.8908C40.0871 13.8908 40.1579 13.8894 40.2295 13.8894C45.2898 13.8894 49.9833 15.744 53.465 19.125C54.0331 19.6767 54.9332 19.6555 55.4763 19.0792C56.0187 18.5021 55.9986 17.5872 55.4305 17.0363H55.4298Z" fill="#E9A9C1"/>
      <path d="M40.0018 17.9709C36.1596 18.0239 32.4805 19.5286 29.7033 22.1827C29.3221 22.5324 29.1604 23.0598 29.2796 23.5628C29.3987 24.0657 29.78 24.4649 30.2769 24.6063C30.7745 24.7477 31.309 24.6098 31.6756 24.2455C36.3499 19.7787 43.8265 19.6797 48.3419 24.0267C48.9099 24.5729 49.8131 24.5562 50.3602 23.9884C50.9073 23.4213 50.8899 22.5178 50.3226 21.971C47.5495 19.3217 43.837 17.8824 40.0018 17.9709Z" fill="#E9A9C1"/>
      <path d="M34.6556 26.9369C34.0894 27.4921 34.0686 28.4124 34.6106 28.9933C35.1525 29.5734 36.0506 29.594 36.6174 29.0394C38.5113 27.184 41.6103 27.0831 43.3829 28.82C43.9498 29.3753 44.8486 29.354 45.3898 28.7738C45.9317 28.193 45.9116 27.272 45.3447 26.7174C42.4592 23.8913 37.6645 23.9907 34.6556 26.9369Z" fill="#E9A9C1"/>
      <path d="M40.0003 31.4914C37.9201 31.4914 36.2345 33.1735 36.2345 35.2481C36.2345 37.3226 37.9201 39.0048 40.0003 39.0048C42.0798 39.0048 43.766 37.3226 43.766 35.2481C43.7632 33.1742 42.0791 31.4935 40.0003 31.4914ZM40.0003 36.1351C39.5094 36.1351 39.1112 35.7378 39.1112 35.2481C39.1112 34.7584 39.5094 34.3611 40.0003 34.3611C40.4912 34.3611 40.8894 34.7584 40.8894 35.2481C40.8887 35.7378 40.4905 36.1344 40.0003 36.1351Z" fill="#E9A9C1"/>
    </svg>
  )
}

export default InternetAndTvIcon