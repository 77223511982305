import React from 'react'

const ServicesSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color || '#541DFF'}
      d="M12 21.577c-.505 0-.94-.175-1.307-.525a1.881 1.881 0 0 1-.597-1.283h3.808a1.881 1.881 0 0 1-.597 1.283c-.366.35-.802.525-1.307.525Zm-3.75-3.192v-1.5h7.5v1.5h-7.5Zm.154-2.885a7.273 7.273 0 0 1-2.488-2.553A6.736 6.736 0 0 1 5 9.5c0-1.949.68-3.603 2.038-4.962C8.398 3.18 10.051 2.5 12 2.5c1.949 0 3.602.68 4.962 2.038C18.32 5.898 19 7.551 19 9.5a6.736 6.736 0 0 1-.916 3.447 7.273 7.273 0 0 1-2.488 2.553H8.404ZM8.85 14h6.3a5.55 5.55 0 0 0 1.737-1.975A5.383 5.383 0 0 0 17.5 9.5c0-1.533-.533-2.833-1.6-3.9C14.833 4.533 13.533 4 12 4s-2.833.533-3.9 1.6C7.033 6.667 6.5 7.967 6.5 9.5c0 .9.204 1.742.612 2.525A5.55 5.55 0 0 0 8.85 14Z"
    />
  </svg>
)

export default ServicesSvg
