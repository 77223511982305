import { toCurrency } from 'utils/formats'

function ReferencedDetail({ row, formattedDate, formattedTime }) {
  return (
    <>
      <table width="100%" className="subtable">
        <tbody>
          <tr>
            <td>Monto:</td>
            <td>{toCurrency(row?.amount)}</td>
          </tr>
          <tr>
            <td>Por pagar:</td>
            <td>{row?.to_pay ? toCurrency(row?.to_pay) : 'N/A'}</td>
          </tr>
          <tr>
            <td>Comisión ganada:</td>
            <td>{row?.fee ? toCurrency(row?.fee) : 'N/A'}</td>
          </tr>
          {(row?.phone && row?.phone?.trim() !== '') &&
            <tr>
              <td>Teléfono:</td>
              <td>{row?.phone}</td>
            </tr>
          }
          <tr>
            <td>Autorización:</td>
            <td>{row?.auth_number || 'N/A'}</td>
          </tr>
          {(row?.reference) &&
            <tr>
              <td>Referencia:</td>
              <td>{row?.reference || 'N/A'}</td>
            </tr>
          }
          <tr>
            <td>Fecha:</td>
            <td>{formattedDate}</td>
          </tr>
          <tr>
            <td>Hora:</td>
            <td>{formattedTime}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default ReferencedDetail