import { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

function AccordionCard({ title, content }) {
  const [isOpen, setIsOpen] = useState()

  const toggleOpen = () => setIsOpen(prevState => !prevState)

  return (
    <Box className="accordion-card">
      <Box className="title" py={1} px={2}>
        <Box onClick={toggleOpen} sx={{ flexGrow: 1 }}>
          {title}
        </Box>
        <IconButton
          onClick={toggleOpen}
          aria-label="expand"
          size="small"
        >
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      {isOpen &&
        <Box px={2} pb={2}>{content}</Box>
      }
    </Box>
  )
}

export default AccordionCard