/* eslint-disable indent */
import CatalogSales from './CatalogSales'
import InternetAndTv from './InternetAndTv'
import OtherServices from './OtherServices'
import PostpaidTelephony from './PostpaidTelephony'
import Services from './Services'
import CellphoneMinutes from './CellphoneMinutes'
import Pins from './Pins'
import GovernmentPayments from './GovernmentPayments'

function ServiceGroupIcon({ iconName, color }) {
  switch(iconName) {
    case 'catalog_sales': {
      return <CatalogSales color={color} />
    }
    case 'prepaid_toll_program': {
      return <OtherServices color={color} />
    }
    case 'telephony': {
      return <PostpaidTelephony color={color} />
    }
    case 'energy': {
      return <Services color={color} />
    }
    case 'mobile_service': {
      return <CellphoneMinutes color={color} />
    }
    case 'entertainment': {
      return <Pins color={color} />
    }
    case 'paytv': {
      return <InternetAndTv color={color} />
    }
    case 'government': {
      return <GovernmentPayments />
    }
    default: {
      return <OtherServices color={color} />
    }
  }
}

export default ServiceGroupIcon