function PostpaidTelephony(props){
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.9999 18.6052H27.2856V57.9163H22.9999V18.6052Z" fill="#E9A9C1"/>
      <path d="M30.871 10.0024H26.5853C24.606 10.0046 23.0022 11.6103 22.9999 13.5925V16.6028H27.2856V13.592C27.2879 11.6103 28.8917 10.0046 30.871 10.0024Z" fill="#E9A9C1"/>
      <path d="M30.871 67.1366C28.8917 67.1343 27.2879 65.5286 27.2856 63.5469V59.9187H22.9999V66.4075C23.0022 68.3892 24.606 69.9954 26.5853 69.9976H53.414C55.1126 69.9954 56.578 68.8015 56.9251 67.1371L30.871 67.1366Z" fill="#E9A9C1"/>
      <path d="M38.8404 42.6572C38.5474 42.6577 38.2689 42.5292 38.0792 42.3063L36.3331 40.2593C35.9743 39.8385 36.0239 39.2067 36.4441 38.8474C36.8643 38.4882 37.4955 38.5379 37.8537 38.9586L38.9659 40.262L42.2751 37.5669C42.5518 37.3395 42.9291 37.278 43.2639 37.4054C43.5993 37.5322 43.8404 37.8295 43.8967 38.1837C43.9531 38.5379 43.8158 38.8949 43.5373 39.1206L39.4715 42.4326C39.2929 42.5778 39.0703 42.6572 38.8404 42.6572Z" fill="#E9A9C1"/>
      <path d="M40.0001 48.8677C35.1083 48.8677 31.1429 44.8976 31.1429 40C31.1429 35.1025 35.1083 31.1324 40.0001 31.1324C44.8918 31.1324 48.8572 35.1025 48.8572 40C48.8516 44.8948 44.8896 48.8621 40.0001 48.8677ZM40.0001 33.1347C36.2127 33.1347 33.1429 36.2081 33.1429 40C33.1429 43.7913 36.2127 46.8653 40.0001 46.8653C43.7869 46.8653 46.8572 43.7913 46.8572 40C46.8527 36.2098 43.7852 33.1386 40.0001 33.1347Z" fill="#E9A9C1"/>
      <path d="M53.4141 8H26.5854C23.5022 8.00335 21.0033 10.5052 21 13.5926V66.4074C21.0033 69.4948 23.5022 71.9961 26.5854 72H53.4141C56.4978 71.9961 58.9967 69.4948 59 66.4074V13.592C58.9961 10.5052 56.4978 8.00335 53.4141 8ZM22.9999 18.6052L57 18.6052V57.9163L22.9999 57.9163V18.6052ZM26.5853 10.0024L53.4141 10.0024C55.3934 10.0046 56.9978 11.6103 57 13.5926V16.6028L22.9999 16.6028L23 13.592C23.0022 11.6103 24.606 10.0046 26.5853 10.0024ZM53.4141 69.9971H26.5854C24.606 69.9948 23.0022 68.3892 22.9999 66.4075V59.9187L57 59.9187V66.4074C56.9978 68.3891 55.3934 69.9948 53.4141 69.9971Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M44.0458 63.9994H35.9537C35.4012 63.9994 34.9537 64.4481 34.9537 65.0006C34.9537 65.5537 35.4012 66.0018 35.9537 66.0018H44.0458C44.5982 66.0018 45.0458 65.5537 45.0458 65.0006C45.0458 64.4481 44.5982 63.9994 44.0458 63.9994Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M53.3571 38.9988H51.8571C51.3047 38.9988 50.8571 39.4469 50.8571 40C50.8571 40.5525 51.3047 41.0012 51.8571 41.0012H53.3571C53.909 41.0012 54.3571 40.5525 54.3571 40C54.3571 39.4469 53.909 38.9988 53.3571 38.9988Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M28.1429 41.0012C28.6948 41.0012 29.1429 40.5525 29.1429 40C29.1429 39.4469 28.6948 38.9988 28.1429 38.9988H26.6429C26.0904 38.9988 25.6429 39.4469 25.6429 40C25.6429 40.5525 26.0904 41.0012 26.6429 41.0012H28.1429Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M52.9542 43.4103L50.8605 42.6879C50.3382 42.5074 49.7695 42.7851 49.5893 43.3081C49.4096 43.831 49.6869 44.4009 50.2087 44.5808L52.3025 45.3032C52.6406 45.4222 53.0167 45.3501 53.2879 45.1154C53.5592 44.8802 53.6836 44.5182 53.6144 44.1657C53.5458 43.8137 53.2935 43.5254 52.9542 43.4103Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M29.7907 35.4187L27.6975 34.6963C27.3588 34.5778 26.9833 34.6493 26.7121 34.884C26.4408 35.1192 26.3164 35.4812 26.385 35.8338C26.4542 36.1858 26.7059 36.4746 27.0458 36.5897L29.139 37.3115C29.6613 37.492 30.2305 37.2143 30.4107 36.6914C30.5904 36.1684 30.3131 35.5986 29.7907 35.4187Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M50.6401 37.6892C50.7411 37.6892 50.841 37.6741 50.9375 37.644L53.0513 36.9858C53.3945 36.8813 53.6551 36.6009 53.7349 36.2511C53.8147 35.9008 53.7009 35.5349 53.4375 35.2918C53.1735 35.0488 52.7997 34.9656 52.4581 35.074L50.3437 35.7321C49.8711 35.8785 49.5765 36.3489 49.6507 36.8383C49.7249 37.3283 50.1456 37.6898 50.6401 37.6892Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M29.0625 42.3555L26.9481 43.0136C26.6049 43.1181 26.3443 43.3986 26.2645 43.7489C26.1853 44.0986 26.2985 44.4646 26.5625 44.7076C26.8265 44.9512 27.1998 45.0339 27.5419 44.9255L29.6562 44.2679C30.1836 44.1036 30.4782 43.5427 30.3142 43.0142C30.1501 42.4862 29.5898 42.1912 29.0625 42.3555Z" fill={props?.color || '#531AFF'} className="border"/>
    </svg>
  )
}

export default PostpaidTelephony