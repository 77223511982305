import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Button, Box } from '@mui/material'
import CircleCheck from 'assets/CircleCheck.svg'

import Input from 'components/Input'
import { addMember, saveTeamMembers } from 'store/slices/teamMembers'
import { validateEmail, validatePhone, clearFieldValidation } from 'store/slices/identity'

function CreateUsers({ setStep, setLoading, setError }) {
  const dispatch = useDispatch()
  const { handleSubmit, control, reset, watch } = useForm()
  const [showSuccess, setShowSuccess] = useState(false)
  const { isLoading, error, success, newMembers } = useSelector(state => state.teamMembers)
  const {
    emailIsLoading, 
    phoneIsLoading, 
    emailError, 
    phoneError, 
    emailExists, 
    phoneExists
  } = useSelector(state => state.identity)
  const { want_shelf, prospect_id } = useSelector(state => state.registry)
  const { id: store_id } = useSelector(state => state.store)

  const name = watch('name')
  const phone = watch('phone')
  const email = watch('email')
  const memberEmail = newMembers?.[newMembers.length - 1]?.email
  // const { vendor_id } = useSelector(state => state.registry)

  const onSubmit = (data) => {
    dispatch(validateEmail({ email: data.email }))
    dispatch(validatePhone({ phone: data.phone_number }))
  }

  const onModalClose = () => {
    reset()
    setShowSuccess(false)
  }

  useEffect(() => {
    if (phoneExists === false && emailExists === false && phoneIsLoading === false && emailIsLoading === false) {
      dispatch(addMember({
        name,
        phone_number: phone,
        email
      }))
      setShowSuccess(true)
    }
  }, [phoneExists, emailExists])

  useEffect(() => {
    return () => {
      dispatch(clearFieldValidation())
    }
  }, [])

  const goNextStep = () => {
    if (want_shelf) {
      setStep(4) // Shelf installation
    } else {
      setStep(5) // Finish onboarding
    }
  }

  useEffect(() => {
    if (!isLoading && !error && success) {
      goNextStep()
      onModalClose()
    }
    if (error) {
      if (error?.length > 0) {
        // Aquí se debe mostrar el error de los usuarios que no se pudieron crear
      } else {
        setError(error)
      }
    }
  }, [isLoading, error, success])

  const onContinue = () => {
    if (newMembers?.length) {
      dispatch(saveTeamMembers({
        [prospect_id ? 'prospect_id': 'store_id']: prospect_id || store_id,
        team_members: newMembers
      }))
    }
  }

  if (showSuccess) {
    return (
      <Box width='100%' maxWidth={350} className="align-center flex-column">
        <Box mb={3}>
          <img src={CircleCheck} alt=""/>
        </Box>
        <h1 className="title-success">Ayudante registrado con éxito.</h1>
        <Box mt={7}>
          Hemos enviado un correo a <strong>{memberEmail}</strong> para que configure su cuenta
        </Box>
        <Box mt={8} mb={5}>
          <Button onClick={onContinue} fullWidth variant="contained">
            Continuar
          </Button>
          <Button onClick={() => setShowSuccess(false)} fullWidth variant="text" sx={{ marginY: '15px' }}>
            Registrar otro ayudante
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box maxWidth={350}>
      <h1 className="onboarding-title">Registra a tus ayudantes.</h1>
      <Box mt={4} className="text-darkgrey">
        Cada persona que registres tendrá su acceso, esto nos permitirá separar e identificar las las ventas para que tengas mejor administración.
      </Box>
      
      <Box className="container-users" mt={3}>
        <Input
          label="Nombre"
          name="name"
          control={control}
          rules={{ required: true }}
          margin="normal"
          fullWidth
          autoFocus
        />

        <Input
          label="Teléfono a 10 dígitos"
          name="phone"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[0-9]{10}$/,
              message: 'El número de teléfono debe tener 10 dígitos'
            }
          }}
          margin="normal"
          fullWidth
        />
        {phoneError && <p className="field-error">{phoneError.message}</p>}
        
        <Input
          label="Correo electrónico"
          name="email"
          control={control}
          rules={{ 
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Correo electrónico inválido"
            }
           }}
          margin="normal"
          fullWidth
        />
        {emailError && <p className="field-error">{emailError.message}</p>}
      </Box>

      <Box className='button-section' mt={3}>
        <Button
          className="btn-main"
          onClick={handleSubmit(onSubmit)} 
          fullWidth 
          variant="contained"
          disabled={!name || !email || !phone || emailIsLoading || phoneIsLoading}
        >
          Continuar
        </Button>
        <Button onClick={goNextStep} fullWidth variant="text" sx={{ marginY: '15px' }}>
          Hacerlo después
        </Button>
      </Box>
    </Box>
  )
}

export default CreateUsers