import { useState } from 'react'
import { useSelector } from 'react-redux'
import { IconButton, Box } from '@mui/material'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'

import { toCurrency } from 'utils/formats'

function BalanceItem({ label, amount, color }) {
  const [isVisible, setIsVisible] = useState(false)
  const { isAdmin } = useSelector(state => state.identity)
  return (
    <Box className="balance-item">
      <Box sx={{ flexDirection: 'row' }} className="justify-center align-center" display="flex">
        <Box className="title" style={{ color: color }} mr={2}>
          {isVisible ? toCurrency(amount) : '$**.**'}
        </Box>
        <IconButton
          size="small"
          onClick={() => {
            if (isAdmin) {
              setIsVisible(!isVisible)
            }
          }}
          sx={{ color: '#000' }}
        >
          {isVisible ? <VisibilityOffOutlined fontSize="17px" /> : <VisibilityOutlined fontSize="17px" />}
        </IconButton>
      </Box>
      <Box className="text-center bodySmall" mt={1}>
        {label}
      </Box>
    </Box>
  )
}

export default BalanceItem
