import { Provider } from 'react-redux'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'
// import { purple } from '@mui/material/colors'

import { store, persistor } from './store'
import AppRouter from './router/index'
import AppProvider from './providers/AppProvider'
import colors from './styles/colors'

import './App.scss'

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'none',
          borderRadius: 24,
          fontWeight: 600,
          lineHeight: 2.2,
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.lighthan,
      contrastText: colors.deepblue
    },
    colors: colors
  },
})

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AppProvider>
            <AppRouter /> 
          </AppProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App