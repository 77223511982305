import { Box } from '@mui/material'
import { Controller } from 'react-hook-form'

import styles, { Option, OptionsWrapper } from './styles'

function OptionsPicker({ options, label, control, name, defaultValue, rules, ...rest }){
  return (
    <Box>
      {label && <span variant="bodyLarge">{label}:</span>}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={rules || {}}
        render={({ field: { onChange, value }, fieldState }) => (
          <OptionsWrapper>
            {options?.map((item, index) => {
              return (
                <Option
                  isSelected={item?.label === value?.label} 
                  onClick={() => {
                    onChange(item)
                  }} 
                  key={index}
                >
                  <span style={styles.optionText} variant="labelLarge">
                    {item.label}
                  </span>
                </Option>
              )
            })}
            {fieldState.invalid && (
              <span type="error" visible={true}>
                Campo requerido
              </span>
            )}
          </OptionsWrapper>
        )}
        {...rest}
      />
    </Box>
  )
}

export default OptionsPicker