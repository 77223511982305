import { toCurrency } from 'utils/formats'

function PaymentDetail({ row, formattedDate, formattedTime }) {
  return (
    <>
      <table width="100%" className="subtable">
        <tbody>
          <tr>
            <td>Monto:</td>
            <td>{toCurrency(row?.amount)}</td>
          </tr>
          {row?.auth_number &&
            <tr>
              <td>Autorización:</td>
              <td>{row?.auth_number || 'N/A'}</td>
            </tr>
          }
          {/* {row?.reference &&
            <tr>
              <td>Referencia:</td>
              <td>{row?.reference || 'N/A'}</td>
            </tr>
          } */}
          <tr>
            <td>Fecha:</td>
            <td>{formattedDate}</td>
          </tr>
          <tr>
            <td>Hora:</td>
            <td>{formattedTime}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default PaymentDetail