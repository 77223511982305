import axios from 'axios'
import { getUserArchitecture, getDeviceId } from './helpers'

export const Headers = async ({ auth }) => {
  const token = localStorage.getItem('session') || ''
  // const device_id = await getUserArchitecture()
  // console.log('device_id', btoa(JSON.stringify(device_id)))
  // const device_id = await AsyncStorage.getItem('device_id') || ''
  // const device_name = await AsyncStorage.getItem('device_name') || ''
  // const device_model = await AsyncStorage.getItem('device_model') || ''
  // const location = await AsyncStorage.getItem('location') || ''

  const headers = {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY,
    device_id: await getDeviceId(),
    // device_name,
    device_model: btoa(JSON.stringify(await getUserArchitecture())),
    // location
  }

  if (!auth) {
    return headers
  }
  
  return {
    ...headers,
    'Authorization': `Bearer ${token}`
  }
}

export const request = async ({ auth = true, headers, ...config }) => {
  try {
    const instance = axios({
      ...config,
      headers: {
        ...await Headers({ auth }),
        ...headers
      }
    })
    return instance
  } catch (err) {
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(err.response.data)
    } else if (err.request) {
      // The request was made but no response was received
      console.log(err.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', err.message)
    }
    throw new Error(err.message)
  }
}

export default request