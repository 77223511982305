import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyD7eYSoym3s5TV4cJ9ZZRmfWGPZS5Tubps",
  authDomain: "don-app-sbx.firebaseapp.com",
  databaseURL: "https://don-app-sbx-default-rtdb.firebaseio.com",
  projectId: "don-app-sbx",
  storageBucket: "don-app-sbx.appspot.com",
  messagingSenderId: "142613057012",
  appId: "1:142613057012:web:586ad034d07bcc32be7da1",
  measurementId: "G-GY42RJCV8G"
}

const app = initializeApp(firebaseConfig)
export const database = getDatabase(app)
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase