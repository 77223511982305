const images = {
  amazon: require('./amazon.png'),
  arabela: require('./arabela.png'),
  at_t: require('./att.png'),
  avon: require('./avon.png'),
  axtel: require('./axtel.png'),
  bitdefender: require('./bitdefender.png'),
  blim: require('./blim.png'),
  blizzard: require('./blizzard.png'),
  bluetelecom: require('./bluetelecom.png'),
  cfe: require('./cfe.png'),
  ciapacov: require('./ciapacov.png'),
  cinepolis: require('./cinepolis.png'),
  cinepolisk: require('./cinepolisk.png'),
  cinepolisvip: require('./cinepolisvip.png'),
  compartfon: require('./compartfon.png'),
  crunchyroll: require('./crunchyroll.png'),
  diri: require('./diri.png'),
  dish: require('./dish.png'),
  elektra_com: require('./elektra.png'),
  flash_mobile: require('./flash.png'),
  freedompop: require('./freedom.png'),
  fuller: require('./fuller.png'),
  google_play: require('./google_play.png'),
  gugacom: require('./gugacom.png'),
  ilusion: require('./ilusion.png'),
  innova: require('./innova.png'),
  innovasport: require('./innovasport.png'),
  izzi_telecom: require('./izzi.png'),
  jafra: require('./jafra.png'),
  kaspersky: require('./kaspersky.png'),
  lbelcorp: require('./lbelcorp.png'),
  maxcom: require('./maxcom.png'),
  maztiempo: require('./maztiempo.png'),
  megacable: require('./megacable.png'),
  microsoft: require('./microsoft.png'),
  minecraft: require('./minecraft.png'),
  movistar: require('./movistar.png'),
  natura: require('./natura.png'),
  naturalbesa: require('./natural_besa.png'),
  netflix: require('./netflix.png'),
  nettv: require('./nettv.png'),
  netwey: require('./netwey.png'),
  nintendo: require('./nintendo.png'),
  omnibus: require('./omnibus.png'),
  oriflame: require('./oriflame.png'),
  oui: require('./oui.png'),
  pase: require('./pase.png'),
  pillofon: require('./pillofon.png'),
  play_station: require('./play_station.png'),
  rappi: require('./rappi.png'),
  rixty: require('./rixty.png'),
  simpati: require('./simpati.png'),
  sky: require('./sky.png'),
  spotify: require('./spotify.png'),
  stanhome: require('./stanhome.png'),
  startv: require('./star_tv.png'),
  starbucks: require('./starbucks.png'),
  steam: require('./steam.png'),
  swissjust: require('./swissjust.png'),
  tae: require('./tae.png'),
  telcel: require('./telcel.png'),
  televia: require('./televia.png'),
  telmex: require('./telmex.png'),
  terramar: require('./terramar.png'),
  total_play: require('./total_play.png'),
  tupperware: require('./tupperware.png'),
  uber: require('./uber.png'),
  unefon: require('./unefon.png'),
  valor: require('./valor.png'),
  virgin_mobile: require('./virgin.png'),
  vrim: require('./vrim.png'),
  wimo: require('./wimo.png'),
  xbox: require('./xbox.png'),
  yanbal: require('./yanbal.png'),
  yves_rocher: require('./yvesrocher.png'),
  zermat: require('./zermat.png'),
}

export default images;