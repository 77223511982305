import request from 'utils/request'

export const addTeamMembers = async ({ prospect_id, store_id, team_members }) => {
  const data = {
    [prospect_id ? 'prospect_id': 'store_id']: prospect_id || store_id,
    team_members
  }
  
  console.log('REQ', data)

  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/identity/employees`,
    data,
    auth: false,
  })
  return response?.data
}

export const getTeamMembers = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/users`,
  })
  return response?.data
}

export const updateTeamMember = async ({ id, status }) => {
  const response = await request({
    method: 'PUT',
    url: `${process.env.REACT_APP_API_URL}/identity/status`,
    data: {
      id, status,
    },
  })
  return response?.data
}

export const deleteTeamMember = async ({ id }) => {
  console.log({
    user_id: id
  })
  const response = await request({
    method: 'DELETE',
    url: `${process.env.REACT_APP_API_URL}/store/user`,
    data: {
      user_id: id,
    },
  })
  return response?.data
}