import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import Input from '../../../components/Input'
import { getProspectId, getProspect } from 'store/slices/registry'
import { getContract } from 'services/registry'
import Loading from 'components/Loading'
import { generateOTP, validateOTP, signContract } from 'services/identity'
import CircleCheck from 'assets/CircleCheck.svg'

function SignContract({ setError, setStep, setLoading }) {
  const params = useParams()
  const dispatch = useDispatch()
  const { control, handleSubmit, watch } = useForm()
  const [contract, setContract] = useState('')
  const [contractSigned, setContractSigned] = useState(false)
  const [contractLoading, setContractLoading] = useState(false)
  const { email, prospect_id, isLoading: isRegistryLoading } = useSelector(state => state.registry)
  const code = watch('code')

  useEffect(() => {
    if (prospect_id) {
      dispatch(getProspect({ prospect_id }))
    }
  }, [prospect_id])

  const fetchContract = async (id) => {
    setContractLoading(true)
    try {
      const response = await getContract({ prospect_id: id })
      setContract(response?.data?.contract_template || '')
    } catch(error) {
      console.log(error)
    }
    setContractLoading(false)
  }
  
  useEffect(() => {
    if (prospect_id) {
      fetchContract(prospect_id)
    }
  }, [prospect_id])

  useEffect(() => {
    if(params?.id) {
      dispatch(getProspectId({ temp_id: params.id }))
    }
  }, [])

  const triggerOTPGenerator = async () => {
    setLoading(true)
    try {
      // console.log(prospect_id)
      await generateOTP({ prospect_id })
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const res = await validateOTP({
        prospect_id,
        code: data.code,
        metadata: {
          // device_id: await getDeviceId(),
          // device_name: Device.deviceName,
          // device_model: Device.modelName,
          // location: `${latLng.lat},${latLng.lng}`
        }
      })
      // console.log('validateOTP', res.data)
      if (res?.data?.status === 'success') {
        const response = await signContract({ prospect_id })
        setContractSigned(true)
      } else {
        setError('Código no valido')
      }
    } catch(error) {
      setError(error)
    }
    setLoading(false)
  }

  if (contractSigned) {
    return (
      <Box className="align-center flex-column">
        <img src={CircleCheck} alt="" />
        <Box width="100%" maxWidth={350} mt={2}>
          <Box mb={8}>
            <h1>Contrato firmado.</h1>
          </Box>
          <p>Tu contrato ha sido firmado con tu huella, recibirás una copia vía correo electrónico a la dirección:</p>
          <br />
          <strong>
            {email}
          </strong>
        </Box>
        <Box width="100%" mt={7} mb={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setStep(2)}
            fullWidth
            disabled={!code || code.length < 4}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box className="align-center flex-column">
      <Loading loading={contractLoading || isRegistryLoading} />
      <h1 className="onboarding-title">Firma tu contrato.</h1>
      <Box className="contract-wrapper" mb={3} mt={3}>
        <div className="contract" dangerouslySetInnerHTML={{__html: contract}}></div>
      </Box>

      <p className="bodyMedium">
        Ingresa el código de 6 dígitos que enviamos al correo: <strong>{email}</strong>
      </p>
      <br />
      <p>*Recuerda revisar tu bandeja de correo no deseado.</p>
      <Box width='100%' maxWidth={350} mt={2}>
        <Input
          control={control}
          label="Código de verificación"
          name="code"
          placeholder="Ingresa el código de 6 dígitos"
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          fullWidth
          disabled={!code || code.length < 6}
        >
          Continuar
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={triggerOTPGenerator}
          fullWidth
          sx={{ marginY: '15px' }}
        >
          Reenviar código
        </Button>
      </Box>
    </Box>
  )
}

export default SignContract