import { database } from '../../firebaseConfig'
import { ref, onValue, query, limitToLast } from 'firebase/database'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'

import { useAppContext, MODAL_TYPES } from 'providers/AppProvider'
import { removeNotification } from 'services/notifications'
import { setNavigateHome, setBalance, addNotificationId } from 'store/slices/sales'
// import CustomerReceiptMessage from 'components/CustomerReceiptMessage'
import TransactionModal from 'pages/Checkout/modals/TransactionModal'

function Notifications() {
  const app = useAppContext()
  const isAuth = useSelector(state => state.identity.isAuth)
  const {
    preferences: { notification_paths },
    // receipt: { error, ready },
    // balance,
    user_id,
  } = useSelector(state => state.store)
  const { notified_ids } = useSelector(state => state.sales)
  const [notificationPath, setNotificationPath] = useState(null)
  const dispatch = useDispatch()

  const handleNotification = (notification, notification_key) => {
    const date1 = DateTime.fromISO(notification?.created_date, { zone: 'utc' }).toLocal()
    const date2 = DateTime.now()
    const dateMinutesDiff = date2.diff(date1).as('minutes')
    const { notification_type } = notification
    const sale_id = notification?.data?.sale_id
    // console.log('notification', notification)

    if (notification?.type === 'transaction' && !notified_ids?.[sale_id]) {
      const sold_by = notification?.data?.sold_by
      if (dateMinutesDiff < 4 && notification_type === 'success' && sold_by === user_id) {
        // console.log('Notified by firebase')
        dispatch(addNotificationId(sale_id))
        app.showModal({
          component: (
            <TransactionModal
              error={null}
              data={notification?.data}
              notification_key={notification_key}
            />
          ),
          onClose: () => {
            removeNotification({
              notification_key,
              path: `${notification.path}/${notification_key}`
            })
            dispatch(setNavigateHome())
          }
        })
      } else if (dateMinutesDiff < 4 && notification_type === 'error') {
        // console.log('Notified by firebase')
        dispatch(addNotificationId(sale_id))
        app.showModal({
          component: (
            <TransactionModal
              error={{
                title: 'No se pudo procesar la transacción',
                message: notification?.message
              }}
              notification_key={notification_key}
            />
          ),
          onClose: () => {
            removeNotification({
              notification_key,
              path: `${notification.path}/${notification_key}`
            })
          }
        })
      }
    } else {
      // if (notified_ids?.[sale_id]) {
      //   console.log('Avoid notification 2')
      // }
      // console.log('ELSE Notif 2', notification.type)
    }
    // if (notification?.type === 'update_balance' && notification?.data) {
    //   dispatch(setBalance({ ...balance, ...notification?.data }))
    //   removeNotification({
    //     notification_key,
    //     path: `${notification.path}/${notification_key}`
    //   })
    // }
  }

  const getStoreInfo = async () => {
    try {
      if (!notificationPath && notification_paths?.[0]) {
        const notifPath = notification_paths?.[0]
        setNotificationPath(notifPath)
        const recentNotificationsRef = query(ref(database, `${notifPath}`), limitToLast(2))
        onValue(recentNotificationsRef, (snapshot) => {
          const data = snapshot.val()
          if (data) {
            Object.keys(data)?.forEach(notification_key => {
              const notification = data?.[notification_key]
              if (notification?.created_date) {
                handleNotification(notification, notification_key)
              }
            })
          }
        })
      }
    } catch(error) {
      app.showModal({ type: MODAL_TYPES.ERROR, title: 'Error del sistema', message: error })
    }
  }

  useEffect(() => {
    if (isAuth && notification_paths) {
      getStoreInfo()
    }
  }, [isAuth, notification_paths])

  // useEffect(() => {
  //   if (ready && error) {
  //     app.showModal({
  //       component: (
  //         <CustomerReceiptMessage
  //           error={'No se pudo procesar la petición'}
  //         />
  //       ),
  //       onClose: () => {
  //         app.closeModal()
  //       }
  //     })
  //   } else if (ready) {
  //     app.showModal({
  //       component: (
  //         <CustomerReceiptMessage />
  //       ),
  //       onClose: () => {
  //         app.closeModal()
  //       }
  //     })
      
  //   }
  // }, [ready, error])
  
  return (
    <div></div>
  )
}

export default Notifications