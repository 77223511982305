import React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import { TableCell, TableRow } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { toCurrency } from 'utils/formats'
import ProductCard from './ProductCard'
import colors from 'styles/colors'

function RowItem(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell padding="none" width="35px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.short_name}
        </TableCell>
        <TableCell align="left">
          {row?.producer_name}
        </TableCell>
        <TableCell align="left">
          {row?.category}
        </TableCell>
        <TableCell align="right">
          {row?.tray}
        </TableCell>
        <TableCell align="right" sx={{ color: colors.success }}>
          {toCurrency(row?.revenue)}
        </TableCell>
        {/* <TableCell align="right">
          <button>Resurtir</button>
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: colors.whitecloud }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ProductCard product={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default RowItem