// utils/statusColor.js
import { COLORS } from '../styles/colors'

export const activityStatus = (status) => {
  switch (status) {
    case 'completed': {
      return { label: 'Exitosa', textColor: COLORS.success }
    }
    case 'init': {
      return { label: 'Procesando', textColor: COLORS.darkgray }
    }
    case 'error': {
      return { label: 'Fallida', textColor: COLORS.oriolesred }
    }
    default: {
      return { label: 'Procesando', textColor: COLORS.darkgray }
    }
  }
}

export const activityGroup = (item) => {
  const defaultGroup = { 
    iconColor: COLORS.hanpurple, 
    listAmountColor: COLORS.midnight,
    icon: item?.group, 
    label: item?.product 
  }
  switch (item?.group) {
    case 'payment-spei': {
      return { 
        ...defaultGroup,
        icon: 'payment', 
        label: 'Pago por Transferencia',
        listAmountColor: COLORS.success,
      }
    }
    case 'payment-cash': {
      return { 
        ...defaultGroup,
        icon: 'payment', 
        label: 'Pago en Efectivo',
        listAmountColor: COLORS.success,
      }
    }
    case 'finance-charges': {
      return {
        ...defaultGroup,
        iconColor: COLORS.oriolesred, 
        listAmountColor: COLORS.oriolesred,
      }
    }
    case 'discrepancy': {
      return {
        ...defaultGroup,
        iconColor: COLORS.success,
      }
    }
    default: {
      return defaultGroup
    }
  }
}