import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import Home from '../pages/Home'
import Login from '../pages/Login'
import Onboarding from 'pages/Onboarding'
import Logout from 'pages/Logout'
import PasswordRecovery from 'pages/PasswordRecovery'
import RestorePassword from 'pages/RestorePassword'
import CreatePassword from 'pages/CreatePassword'
import Services from 'pages/Services'
import Activity from 'pages/Activity'
import Shelf from 'pages/Shelf'
import Settings from 'pages/Settings'
import Profile from 'pages/Profile'
import Checkout from 'pages/Checkout'
import ShelfReceipt from 'pages/ShelfReceipt'

const ProtectedRoutes = ({ children }) => {
  const { isAuth } = useSelector((state) => state.identity)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuth) {
      navigate('/login')
    }
  }, [isAuth, navigate])

  return isAuth ? children : null
}

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/password_recovery" element={<PasswordRecovery />} />
        <Route path="/restore_password/:token" element={<RestorePassword />} />
        <Route path="/create_password/:uuid" element={<CreatePassword />} />
        <Route path="/onboarding/:id" element={<Onboarding />} />
        <Route path="/" element={
          <ProtectedRoutes>
            <Home />
          </ProtectedRoutes>
        } />
        <Route path="/services" element={
          <ProtectedRoutes>
            <Services />
          </ProtectedRoutes>
        } />
        <Route path="/activity" element={
          <ProtectedRoutes>
            <Activity />
          </ProtectedRoutes>
        } />
        <Route path="/shelf" element={
          <ProtectedRoutes>
            <Shelf />
          </ProtectedRoutes>
        } />
        <Route path="/settings" element={
          <ProtectedRoutes>
            <Settings />
          </ProtectedRoutes>
        } />
        <Route path="/profile" element={
          <ProtectedRoutes>
            <Profile />
          </ProtectedRoutes>
        } />
        <Route path="/checkout/:service_slug/product/:slug" element={
          <ProtectedRoutes>
            <Checkout />
          </ProtectedRoutes>
        } />
        <Route path="/receipt/:receipt_id" element={
          <ProtectedRoutes>
            <ShelfReceipt />
          </ProtectedRoutes>
        } />
      </Routes>
    </Router>
  )
}

export default AppRouter