import { useState } from 'react'
import { useSelector } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import BellIcon from '@mui/icons-material/Notifications'
import GearIcon from '@mui/icons-material/Settings'
import ExitIcon from '@mui/icons-material/ExitToApp'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import SensorWindowIcon from '@mui/icons-material/SensorWindow'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { useNavigate, useLocation } from 'react-router-dom'

import HomeIcon from '@mui/icons-material/Home'
import Logo from 'assets/logo-white.svg'
import { stringAvatar } from 'utils/helpers'

const pages = [
  { name: 'Inicio', path: '/', icon: <HomeIcon /> },
  { name: 'Servicios', path: '/services', icon: <ViewCompactIcon /> },
  { name: 'Anaquel', path: '/shelf', icon: <SensorWindowIcon /> },
  { name: 'Actividad', path: '/activity', icon: <ReceiptLongIcon /> },
]

function HeaderBar() {
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const { store_name } = useSelector((state) => state.store)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position="static" className="header">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box pb={1} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img src={Logo} alt="Logo Comouna" width="100px" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem 
                  key={page.name}
                  onClick={() => {
                    navigate(page.path)
                    handleCloseNavMenu()
                  }}
                  className={page.path === location.pathname ? 'active':''}
                  divider={pages.length - 1 === index}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem
                onClick={() => {
                  navigate('/logout')
                  handleCloseNavMenu()
                }}
              >
                <Typography textAlign="center">Cerrar sesión</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box pb={1} width="100%" sx={{ justifyContent: 'center', display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img src={Logo} alt="Logo Comouna" width="100px" />
          </Box>

          {/* <Box width="100%" sx={{ display: { xs: 'none', md: 'inline-block' } }}> */}
          <Box className="header-desktop" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  navigate(page.path)
                  handleCloseNavMenu()
                }}
                sx={{ my: 2, color: 'white', display: 'inline-block' }}
                className={page.path === location.pathname ? 'active':''}
                startIcon={page.icon}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {/* <Tooltip title="Notifications">
              <IconButton color="inherit">
                <BellIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Configuraciones">
              <IconButton color="inherit" onClick={() => navigate('/settings')}>
                <GearIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Salir">
              <IconButton color="inherit" onClick={() => navigate('/logout')}>
                <ExitIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Perfil">
              <Avatar onClick={() => navigate('/profile')} {...stringAvatar(store_name)} />
            </Tooltip>
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default HeaderBar