import { useState } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import CircleIcon from '@mui/icons-material/Circle'
import Box from '@mui/material/Box'

import SignContract from './steps/SignContract'
import CreateUsers from './steps/CreateUsers'
import Congratulations from './steps/Congratulations'
import CreatePassword from './steps/CreatePassword'
import ShelfInstall from './steps/ShelfInstall'

import { useAppContext, MODAL_TYPES } from 'providers/AppProvider'
import Loading from 'components/Loading'
import './styles.scss'

function Onboarding() {
  const app = useAppContext()
  const [step, setStep] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const { want_shelf, possible_installation_date } = useSelector(state => state.registry)

  const setError = (error) => {
    app.showModal({
      type: MODAL_TYPES.ERROR,
      message: error
    })
  }

  return (
    <Container>
      <Box className="onboarding-content">
        <Loading loading={isLoading} />
        {step === 1 && <SignContract setStep={setStep} setError={setError} setLoading={setLoading} />}
        {step === 2 && <CreatePassword setStep={setStep} setError={setError} setLoading={setLoading} />}
        {step === 3 && <CreateUsers setStep={setStep} setError={setError} setLoading={setLoading} />}
        {step === 4 && <ShelfInstall setStep={setStep} setError={setError} setLoading={setLoading} />}
        {step === 5 && <Congratulations setStep={setStep} setError={setError} setLoading={setLoading} />}
        <Box className="container-circle-icon" width="100%" maxWidth={250} mt={2}>
          <CircleIcon className={`circle-icon ${step === 1 ? 'active' : ''}`} style={{ fontSize: '15px'}} />
          <CircleIcon className={`circle-icon ${step === 2 ? 'active' : ''}`} style={{ fontSize: '15px'}} />
          {want_shelf && <CircleIcon className={`circle-icon ${step === 3 ? 'active' : ''}`} style={{ fontSize: '15px'}} />}
          <CircleIcon className={`circle-icon ${step === 4 || step === 5 ? 'active' : ''}`} style={{ fontSize: '15px'}} />
        </Box>
      </Box>
    </Container>
  )
}

export default Onboarding