import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addTeamMembers,
  getTeamMembers,
  updateTeamMember,
  deleteTeamMember
} from 'services/team'

export const saveTeamMembers = createAsyncThunk(
  'teamMembers/saveTeamMembers',
  async ({ prospect_id, store_id, team_members }) => {
    const res = await addTeamMembers({ prospect_id, store_id, team_members })
    if (res?.success) {
      return res
    } else {
      throw new Error('No se pudieron crear los usuarios')
    }
  }
)

export const getMembers = createAsyncThunk(
  'teamMembers/getMembers',
  async (_, { getState }) => {
    const { token } = getState().identity
    const response = await getTeamMembers({ token })
    return response
  }
)

export const updateMember = createAsyncThunk(
  'teamMembers/updateMember',
  async ({ id, status }) => {
    console.log({ id, status })
    const response = await updateTeamMember({ id, status })
    return response
  }
)

export const removeMember = createAsyncThunk(
  'teamMembers/removeMember',
  async ({ id }, { getState }) => {
    const { token } = getState().identity
    const response = await deleteTeamMember({ token, id })
    return response
  }
)

const initialState = {
  members: [],
  newMembers: [],
  isLoading: false,
  success: null,
  error: null,
  selectedMember: null,

  membersIsLoading: false,
  membersError: null,
  membersSuccess: null,

  memberIsLoading: false,
  memberError: null,

  isDeleting: false,
  deleteError: null,
  deleteSuccess: null
}

const teamMembersSlice = createSlice({
  name: 'teamMembers',
  initialState,
  reducers: {
    addMember(state, { payload }) {
      const { name, phone_number, email } = payload
      state.newMembers.push({
        name,
        phone_number,
        email
      })
    },
    selectMember(state, { payload }) {
      state.selectedMember = payload
    },
    clearMemberDelete(state) {
      state.isDeleting = false
      state.deleteError = null
      state.deleteSuccess = false
    },
    clearMemberUpdate(state) {
      state.memberError = null
    },
    clearNewMember(state) {
      state.isLoading = false
      state.success = null
      state.error = null
    },
    clearTeamMembersState() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveTeamMembers.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(saveTeamMembers.fulfilled, (state, action) => {
      state.success = action.payload?.success || false
      state.isLoading = false
      state.newMembers = []
      if (action.payload?.data) {
        const user_errors = action.payload?.data?.filter((item) => !item.is_created)
        if (user_errors.length > 0) {
          state.error = user_errors
        }
      } else {
        state.error = null
      }
    })
    builder.addCase(saveTeamMembers.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    builder.addCase(getMembers.pending, (state) => {
      state.membersIsLoading = true
      state.membersError = null
    })
    builder.addCase(getMembers.fulfilled, (state, action) => {
      state.membersSuccess = action.payload?.success || false
      state.members = action.payload?.data || []
      state.membersIsLoading = false
      state.membersError = null
    })
    builder.addCase(getMembers.rejected, (state, action) => {
      state.membersSuccess = false
      state.membersIsLoading = false
      state.membersError = action.error.message
    })

    builder.addCase(updateMember.pending, (state) => {
      state.memberIsLoading = true
      state.memberError = null
    })
    builder.addCase(updateMember.fulfilled, (state, action) => {
      // state.success = action.payload?.success || false
      console.log(action.payload)
      state.memberIsLoading = false
      state.memberError = null
    })
    builder.addCase(updateMember.rejected, (state, action) => {
      console.log(action.error.message)
      state.memberIsLoading = false
      state.memberError = action.error.message
    })

    builder.addCase(removeMember.pending, (state) => {
      state.isDeleting = true
      state.deleteError = null
      state.deleteSuccess = null
    })
    builder.addCase(removeMember.fulfilled, (state, action) => {
      console.log(action.payload)
      state.isDeleting = false
      state.deleteError = null
      state.deleteSuccess = true
    })
    builder.addCase(removeMember.rejected, (state, action) => {
      console.log(action.error.message)
      state.isDeleting = false
      state.deleteError = action.error.message
      state.deleteSuccess = false
    })
  },
})

export const {
  addMember,
  selectMember,
  clearMemberDelete,
  clearMemberUpdate,
  clearNewMember,
  clearTeamMembersState,
} = teamMembersSlice.actions
export default teamMembersSlice.reducer