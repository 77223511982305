/* eslint-disable indent */
import CatalogSales from './Catalog'
import InternetAndTv from './Tv'
import OtherServices from './Others'
import PostpaidTelephony from './PostpaidTelephony'
import Services from './Services'
import CellphoneMinutes from './CellphoneMinutes'
import Pins from './Pins'
import Consignment from './Consignment'
import Payment from './Payment'
import GovernmentPayments from './GovernmentPayments'

function TransactionGroupIcon({ iconName, color }) {
  switch(iconName) {
    case 'catalog_sales': {
      return <CatalogSales />
    }
    case 'prepaid_toll_program': {
      return <OtherServices />
    }
    case 'mobile_service': {
      return <PostpaidTelephony />
    }
    case 'energy': {
      return <Services />
    }
    case 'telephony': {
      return <CellphoneMinutes />
    }
    case 'entertainment': {
      return <Pins />
    }
    case 'paytv': {
      return <InternetAndTv />
    }
    case 'government': {
      return <GovernmentPayments />
    }
    case 'consignment': {
      return <Consignment />
    }
    case 'payment': {
      return <Payment color={color} />
    }
    case 'payment-spei': {
      return <Payment color={color} />
    }
    case 'payment-cash': {
      return <Payment color={color} />
    }
    case 'discrepancy': {
      return <Payment color={color} />
    }
    case 'finance-charges': {
      return <Payment color={color} />
    }
    default: {
      return <OtherServices />
    }
  }
}

export default TransactionGroupIcon