import React from 'react'

const PostpaidTelephony = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color || '#541DFF'}
      d="m18.023 14.658-1.085-1.085c.233-.203.417-.439.554-.71.137-.27.206-.558.206-.863 0-.305-.069-.593-.206-.864a2.337 2.337 0 0 0-.554-.71l1.085-1.084c.37.352.659.755.865 1.212.207.456.31.938.31 1.446s-.103.99-.31 1.446c-.206.457-.495.86-.865 1.212Zm2.296 2.296L19.265 15.9a5.493 5.493 0 0 0 1.196-1.78c.28-.67.42-1.376.42-2.12 0-.744-.14-1.45-.42-2.12a5.492 5.492 0 0 0-1.196-1.78l1.054-1.054a6.806 6.806 0 0 1 1.53 2.266c.354.856.532 1.752.532 2.688 0 .936-.178 1.832-.532 2.688a6.806 6.806 0 0 1-1.53 2.266ZM7.308 22.5c-.499 0-.925-.177-1.278-.53a1.741 1.741 0 0 1-.53-1.278V3.308c0-.499.177-.925.53-1.278.353-.353.779-.53 1.278-.53h9.384c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v3.654H17V5.75H7v12.5h10v-1.212h1.5v3.654c0 .499-.177.925-.53 1.278-.353.353-.779.53-1.278.53H7.308ZM7 19.75v.942c0 .077.032.148.096.212a.294.294 0 0 0 .212.096h9.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.212v-.942H7Zm0-15.5h10v-.942a.294.294 0 0 0-.096-.212.294.294 0 0 0-.212-.096H7.308a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v.942Zm0 0V3v1.25Z"
    />
  </svg>
);

export default PostpaidTelephony
