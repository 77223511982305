import Radio from '@mui/material/Radio'
import RadioButtonGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Controller } from 'react-hook-form'

import { RadioItemWrapper, RadioGroupWrapper } from './styles'

function RadioGroup({ control, name, rules, options, mode, label, defaultValue, ...rest }) {
  const titleLeft = mode === 'title-left'
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      rules={rules || {}}
      render={({ field: { onChange, value }, fieldState }) => (
        <RadioButtonGroup /*onValueChange={onChange}*/>
          <RadioGroupWrapper>
            {label && <span className="titleMedium" style={{}}>{label}</span>}
            {options.map((item, index) => {
              const selected = value === item.value ? true : false
              if (titleLeft) {
                return (
                  <RadioItemWrapper key={index} onClick={() => onChange(item.value)}>
                    <Radio value={item.value} checked={selected} />
                    <span className="bodyLarge radio-item">{item.label}</span>
                  </RadioItemWrapper>
                )
                // return <FormControlLabel value={item.value} control={<Radio />} label={item.label} key={index}/>
              }
              return (
                <RadioItemWrapper key={index} onClick={() => onChange(item.value)}>
                  <Radio value={item.value} checked={selected} />
                  <span className="bodyLarge radio-item">{item.label}</span>
                </RadioItemWrapper>
              )
            })}
            {fieldState.invalid && (
              <p className="field-error">
                Campo requerido
              </p>
            )}
          </RadioGroupWrapper>
        </RadioButtonGroup>
      )}
      {...rest}
    />
  )
}

export default RadioGroup