import { Box, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import ErrorIcon from 'assets/transaction/error.svg'
import SuccessIcon from 'assets/transaction/success.svg'

function TransactionModal({ error, onDismiss, data, notification_key }) {
  const { description, auth_number, sale_id, receipt_link } = data || {}

  const handleReceipt = () => {
    if (receipt_link) {
      window.location.href = `${receipt_link}`
    }
    onDismiss()
  }

  const handleClose = () => {
    onDismiss()
    setTimeout(() => {
      window.location.href = '/services'
    }, 800)
  }

  const success = !error && description

  if (success) {
    return (
      <Box px={4} pb={4} pt={2} className="transaction-modal">
        <Box className="flex-row">
          <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <img src={SuccessIcon} width={80} alt="Success Icon" />
        <Box className="title" my={3}>Transacción exitosa</Box>
        <p>
          Número de autorización <b>#{auth_number}</b>
        </p>
        <p className="paragraph">
          La transacción <b>{description}</b> fue procesada con éxito.
        </p>
        <Box pt={2} className="flex-row" sx={{ gap: '20px', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleClose}>Entendido</Button>
          {/* <Button variant="contained" onClick={handleReceipt} disabled={!receipt_link}>Enviar recibo</Button> */}
        </Box>
      </Box>
    )
  }

  return (
    <Box px={4} pb={4} pt={2} className="transaction-modal">
      <Box className="flex-row">
        <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <img src={ErrorIcon} width={80} alt="Error Icon" />
      <Box className="title" my={3}>
        {error?.title || 'Transacción fallida'}
      </Box>
      <p className="paragraph">
        {error?.message || 'Hubo un error al procesar la transacción.'}
      </p>
      <Box pt={2} className="flex-row" sx={{ gap: '20px', justifyContent: 'flex-end' }}>
        <Button onClick={onDismiss}>Cerrar</Button>
      </Box>
    </Box>
  )
}

export default TransactionModal