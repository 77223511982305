import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { Search, Tune, SaveAlt } from '@mui/icons-material'
import Button from '@mui/material/Button'

import HeaderBar from 'components/HeaderBar'
import Input from 'components/Input'
import { StyledTableCell } from 'styles/shared'
import { fetchTransactions } from 'store/slices/activity'
import { MODAL_TYPES, useAppContext } from 'providers/AppProvider'
import RowItem from './RowItem'
import { filterActivities, clearFilter } from 'store/slices/activity'
import { longFullDate } from 'utils/formats'
import FiltersModal from './modals/FiltersModal'
import DownloadReportModal from 'components/DownloadReportModal'

function Activity() {
  const app = useAppContext()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { activities, filtered_activities, current_filter, isLoading } = useSelector(state => state.activity)
  const { receipt } = useSelector(state => state.sales)
  const { isLoading: receiptIsLoading, error: receiptError } = receipt || {}

  useEffect(() => {
    if (receiptError) {
      app.setNotification({
        type: MODAL_TYPES.ERROR,
        message: receiptError,
      })
    }
  }, [receiptError])

  useEffect(() => {
    dispatch(fetchTransactions())
  }, [])

  useEffect(() => {
    app.setIsLoading(isLoading || receiptIsLoading)
  }, [app, isLoading, receiptIsLoading])

  const handleSearch = data => {
    const { search } = data
    if (search) {
      dispatch(filterActivities({ reference: search }))
    } else {
      dispatch(clearFilter())
    }
  }

  const groupActivitiesByDate = (activitiesList) => {
    return activitiesList.reduce((groups, activity) => {
      const date = longFullDate(activity?.date)
      if (!groups?.[date]) {
        groups[date] = []
      }
      groups[date].push(activity)
      return groups
    }, {})
  }

  const groupedActivities = groupActivitiesByDate(filtered_activities || activities)

  const openFilterModal = () => {
    app.showModal({
      component: <FiltersModal />,
    })
  }

  const handleDownloadAccountStatus = async () => {
    app.showModal({
      component: <DownloadReportModal />,
    })
  }

  return (
    <div className="container">
      <HeaderBar />
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        <Box mb={3} className="flex-row align-center" width="100%">
          <Box flexGrow={1} display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between' }} pr={2}>
            <span className="subtitle">Actividad</span>
            <Button size="small" variant="contained" onClick={handleDownloadAccountStatus} startIcon={<SaveAlt />}>
              Estado de cuenta
            </Button>
          </Box>
          <Box component="form" flexGrow={1} display="flex" className="activity-search" onSubmit={handleSubmit(handleSearch)}>
            <Input
              label="Buscar por número o referencia"
              control={control}
              name="search"
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleSubmit(handleSearch)}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
            <IconButton onClick={openFilterModal}>
              <Tune />
            </IconButton>
          </Box>
        </Box>
        <Box pb={4}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell>Transacción</StyledTableCell>
                  <StyledTableCell align="right">Monto</StyledTableCell>
                  <StyledTableCell align="right">Estado</StyledTableCell>
                  <StyledTableCell align="right">Fecha</StyledTableCell>
                  <StyledTableCell align="right">Hora</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(groupedActivities)?.map(date => (
                  <React.Fragment key={date}>
                    <TableRow>
                      <TableCell colSpan={6} style={{ backgroundColor: '#f5f5f5' }}>
                        {date}
                      </TableCell>
                    </TableRow>
                    {groupedActivities?.[date]?.map((row, index) => (
                      <RowItem key={`${row?.id}-${index}`} row={row} />
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </div>
  )
}

export default Activity
