import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Container } from '@mui/material'
import StorefrontIcon from '@mui/icons-material/Storefront'
import GroupIcon from '@mui/icons-material/Group'
import LockIcon from '@mui/icons-material/Lock'

import HeaderBar from 'components/HeaderBar'
import colors from 'styles/colors'

function Settings() {
  const { isLoading, store_name, email, menu, error } = useSelector(store => store.store)

  return (
    <div className="container">
      <HeaderBar />
      <Container maxWidth="xl">
        <Box className="subtitle" mt={3} mb={3}>
          Configuración
        </Box>
        <Box>
          <Box className="bodyLarge">
            <span style={{ color: colors.darkpeach }}>{store_name},</span> <span>{email}</span> • <Link to="/profile">Ir a tu perfil</Link>
          </Box>
          <Box className="configuration-cards" mt={3}>
            <Box className="card" flex={1}>
              <StorefrontIcon fontSize="small" sx={{ color: colors.darkgrey }} />
              <Box className="bodyLarge" sx={{ color: colors.deepblue }}>
                Información de la cuenta
              </Box>
              <Box mt={1} className="bodySmall">
                Revisa la información de negocio
              </Box>
            </Box>
            <Box className="card" flex={1}>
              <GroupIcon fontSize="small" sx={{ color: colors.darkgrey }} />
              <Box className="bodyLarge" sx={{ color: colors.deepblue }}>
                Usuarios
              </Box>
              <Box mt={1} className="bodySmall">
                Agrega, elimina y edita usuarios
              </Box>
            </Box>
            <Box className="card" flex={1}>
              <LockIcon fontSize="small" sx={{ color: colors.darkgrey }} />
              <Box className="bodyLarge" sx={{ color: colors.deepblue }}>
                Seguridad
              </Box>
              <Box mt={1} className="bodySmall">
                Cambia tu contraseña
              </Box>
            </Box>
            <Box className="card" flex={1}>
              <LockIcon fontSize="small" sx={{ color: colors.darkgrey }} />
              <Box className="bodyLarge" sx={{ color: colors.deepblue }}>
                Información legal
              </Box>
              <Box mt={1} className="bodySmall">
                Contrato, Politicas, etc
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  )
}

export default Settings