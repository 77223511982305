import { createContext, useContext,  useState, useMemo, useCallback } from 'react'
import ModalProvider from '../ModalProvider'
import { TYPES } from 'components/Modal'
import Loading from 'components/Loading'

export const MODAL_TYPES = TYPES

export const AppContext = createContext(null)

export default function AppProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    type: TYPES.DEFAULT, 
    title: '',
    message: '',
    component: null,
    onClose: () => {},
    backgroundColor: ''
  })

  const showModal = useCallback(({ type, title, message, component, onClose, backgroundColor }) => {
    setModal({ title, type, message, component, onClose, backgroundColor })
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    modal?.onClose?.()
    setModal({ type: TYPES.DEFAULT, title: '', message: '', component: null, backgroundColor: '' })
    setIsOpen(false)
  }, [modal])

  const contextValue = useMemo(() => ({
    isOpen,
    modal,
    closeModal,
    showModal,
    setIsLoading
  }), [isOpen, modal, closeModal, showModal])

  return (
    <AppContext.Provider value={contextValue}>
      <ModalProvider>
        {children}
      </ModalProvider>
      <Loading loading={isLoading} />
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
