import request from 'utils/request'

export const getShelfInfo = async ({ shelf_number }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/shelve/${shelf_number}`,
  })
  return response?.data
}

export const getConsignmentById = async ({ consignment_id }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/consignment/${consignment_id}`,
  })
  return response?.data
}

export const getConsignments = async ({ page, size }) => {
  const params = (page && size) ? `?page=${page}&size=${size}` : ''
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/consignment/${params}`,
  })
  return response?.data
}

export const setConsignmentSettle = async ({ consignment_id, status, device_id, location, version_app }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/store/consignment/settle`,
    data: {
      consignment_id,
      status,
      audit_data: {
        location,
        device_id,
        version_app,
      }
    },
  })
  return response?.data
}

export const qualifySupplier = async ({ consignment_id, rate, attribute, comments }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/store/nps`,
    data: {
      id: consignment_id,
      rate,
      attribute,
      comments
    },
  })
  return response?.data
}
