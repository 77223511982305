import React from 'react'

const Consignment = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color || '#541DFF'}
      d="M4.5 19.5V7.6L2.646 3.542l1.358-.638L6.142 7.55h11.716l2.138-4.646 1.358.638L19.5 7.6v11.9h-15Zm5.5-6.75h4c.212 0 .39-.072.534-.216A.726.726 0 0 0 14.75 12a.725.725 0 0 0-.216-.535.726.726 0 0 0-.534-.215h-4a.725.725 0 0 0-.535.216.726.726 0 0 0-.215.534c0 .213.072.39.215.534a.726.726 0 0 0 .535.216ZM6 18h12V9.05H6V18Z"
    />
  </svg>
)

export default Consignment