import React from 'react'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import TransactionGroupIcon from 'assets/transaction_group_icons'
import { activityStatus, activityGroup } from 'utils/status'
import { toCurrency, formatDateAndTime } from 'utils/formats'
import Detail from './Detail'

function RowItem(props) {
  const { row } = props
  const [open, setOpen] = React.useState(false)
  const statusInfo = activityStatus(row?.status)
  const groupInfo = activityGroup(row)

  const { date, time } = formatDateAndTime(row.date)

  return (
    <React.Fragment>
      <TableRow>
        <TableCell padding="none" align="center" width="35px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Box className="flex-row align-center">
            <TransactionGroupIcon iconName={row?.group} color={groupInfo.iconColor} />
            <Box ml={1}>{groupInfo.label}</Box>
          </Box>
        </TableCell>
        <TableCell align="right" sx={{ color: groupInfo.listAmountColor }}>{toCurrency(row?.amount)}</TableCell>
        <TableCell align="right">
          <span style={{ color: statusInfo.textColor }}>{statusInfo.label}</span>
        </TableCell>
        <TableCell align="right">{date}</TableCell>
        <TableCell align="right">{time}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Detail row={{...row, groupInfo }} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default RowItem