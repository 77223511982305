import React from 'react'
import { TableCell, TableRow } from '@mui/material'

import { formatMedDate } from 'utils/formats'
import colors from 'styles/colors'

function RowItem(props) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="left">
          <span style={{ color: colors.deepblue }}>
            {row.producer_name}
          </span>
        </TableCell>
        <TableCell align="left">{formatMedDate(row.created_date)}</TableCell>
        <TableCell align="left">{row?.receipt_user?.user_name}</TableCell>
        <TableCell align="center">
          <a href={`/receipt/${row?.id}`}>Ver</a>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default RowItem