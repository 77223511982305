import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import ServiceGroupIcon from 'assets/service_group_icons'
import { toCurrency } from 'utils/formats'

function ConfirmationModal({ onDismiss, serviceSlug, onConfirm, data }) {
  return (
    <Box px={4} pb={4} pt={2} className="confirmation-modal">
      <Box mb={3} className="flex-row">
        <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box mb={4} pl={1}>
        <ServiceGroupIcon iconName={serviceSlug} />
      </Box>
      <Box className="title" mb={2}>
        Confirma la venta
      </Box>
      <Box py={3} className="paragraph">
        Estás por vender <b>{toCurrency(data?.totalAmount)}</b> {data?.plan_name && <>de <b>{data?.plan_name}</b></>} de <b>{data?.company}</b> al número <b>{data?.phone_number}</b>
      </Box>
      <Box mt={1} className="flex-row" sx={{ justifyContent:'flex-end', gap: '20px' }}>
        <Button onClick={onDismiss}>Cancelar</Button>
        <Button variant="contained" onClick={onConfirm}>Confirmar</Button>
      </Box>
    </Box>
  )
}

export default ConfirmationModal