import { Button, Box, IconButton, Snackbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CopyAll, Close, CopyAllOutlined, HelpOutline, WhatsApp } from '@mui/icons-material'

import { getPaymentInfoData } from 'store/slices/store'
import { toCurrency } from 'utils/formats'
import colors from 'styles/colors'

function PaymentByBankTransfer() {
  const dispatch = useDispatch()
  const [toastVisible, setToastVisible] = useState(false)
  const { paymentInfo, id } = useSelector(state => state.store)

  useEffect(() => {
    dispatch(getPaymentInfoData())
  }, [])

  const bank_info = {
    amount: toCurrency(Math.abs(paymentInfo?.amount)),
    account: paymentInfo?.spei_reference,
    bank: 'Pagos Móviles por STP/Sistema',
    account_owner: paymentInfo?.account_owner,
    reference: paymentInfo?.reference
  }
  const { amount, account, bank, account_owner, reference } = bank_info

  const share_text = `
Datos para Transferencia (SPEI):

💲 Cantidad: ${amount}
🔢 CLABE: ${account}
🏦 Banco/Institución: ${bank}
👤 ${account_owner}
📝 Referencia: ${reference}
  `

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(`${text}`)
      setToastVisible(true)
    } catch (error) {
      console.error('Error copying to clipboard:', error)
    }
  }

  const shareText = async () => {
    // try {
    //   await Share.share({
    //     message: share_text
    //   })
    // } catch (error) {
    //   console.error('Error sharing:', error);
    // }
  }

  const copyAllText = async () => {
    await navigator.clipboard.writeText(share_text)
    setToastVisible(true)
  }

  const openSupport = () => {
    // const text = `Hola, necesito ayuda y este es mi identificador: ${id}`
    window.location.href = `https://t.me/comouna`
  }

  const shareWhatsapp = () => {
    window.location.href = `whatsapp://send?text=${share_text}`
  }

  const CopyButton = ({ copyText }) => {
    return (
      <IconButton onClick={() => copyToClipboard(copyText)}>
        <CopyAll />
      </IconButton>
    )
  }

  return (
    <div>
      <table width="100%">
        <tbody className="bodySmall">
          <tr>
            <td>Cantidad por Pagar (MXN)</td>
            <td className="bodyLarge">
              {amount}
              <CopyButton copyText={amount} />
            </td>
          </tr>
          <tr>
            <td>No. de cuenta CLABE</td>
            <td className="bodyLarge">
              {account}
              <CopyButton copyText={account} />
            </td>
          </tr>
          <tr>
            <td>Banco/Institución</td>
            <td>
              {bank}
              <CopyButton copyText={bank} />
            </td>
          </tr>
          <tr>
            <td>Beneficiario</td>
            <td>
              {account_owner}
              <CopyButton copyText={account_owner} />
            </td>
          </tr>
          <tr>
            <td>Referencia</td>
            <td>
              {reference}
              <CopyButton copyText={reference} />
            </td>
          </tr>
        </tbody>
      </table>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }} my={3}>
        <Button variant="contained" color="primary" sx={{ flex: 1 }} startIcon={<WhatsApp />} onClick={shareWhatsapp}>
          Compartir por Whatsapp
        </Button>
        <Button variant="contained" color="secondary" sx={{ flex: 1 }} startIcon={<CopyAllOutlined />} onClick={copyAllText}>
          Copiar todo
        </Button>
        <Button variant="contained" color="secondary" sx={{ flex: 1 }} startIcon={<HelpOutline />} onClick={openSupport}>
          Ayuda
        </Button>
      </Box>

      <Box>
        <Box className="bodyLarge">
          Instrucciones
        </Box>
        <Box className="bodySmall" my={2}>
          En tu banca en linea:
        </Box>
        <ol className="bodySmall">
          <li>Elige transferencia a cuenta CLABE</li>
          <li>Usa los datos de arriba para llenar los campos solicitados</li>
          <li>Si tienes dudas utiliza el botón de ayuda</li>
        </ol>
      </Box>
      <Snackbar
        open={toastVisible}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setToastVisible(false)}
        message="Datos copiados con éxito"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setToastVisible(false)}>
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </div>
  )
}

export default PaymentByBankTransfer