import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
// import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import HeaderBar from 'components/HeaderBar'
import ProductsTable from 'components/ProductsTable'
import ReceiptsTable from 'components/ReceiptsTable'
import { getReceipts, getShelf, clearReceipt } from 'store/slices/shelf'
import colors from 'styles/colors'
import { useAppContext, MODAL_TYPES } from 'providers/AppProvider'
import { formatMedDate } from 'utils/formats'
import ReceiptModal from './modals/ReceiptModal'
import ShelfImage from 'assets/shelf-image.png'

function Shelf() {
  const app = useAppContext()
  const dispatch = useDispatch()
  const { 
    receipts, 
    installation_date, 
    products, 
    isLoading, 
    receiptIsLoading, 
    receiptError,
    receipt: { id: receipt_id }
  } = useSelector(state => state.shelf)
  const { shelves } = useSelector(state => state.store)
  const shelf = shelves?.[0] || {}
  const shelf_number = shelf?.shelf_number || ''

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    dispatch(getReceipts({ page: 0, size: 0 }))
    dispatch(clearReceipt())
  }, [])

  useEffect(() => {
    if (shelf_number) {
      dispatch(getShelf({ shelf_number }))
    }
  }, [shelf_number])

  // useEffect(() => {
  //   if (receipt_id) {
  //     app.closeModal()
  //     navigate(`/receipt/${receipt_id}`)
  //   }
  // }, [receipt_id])

  useEffect(() => {
    if (receiptError) {
      app.showModal({ type: MODAL_TYPES.ERROR, message: receiptError })
    }
  }, [receiptError])

  useEffect(() => {
    app.setIsLoading(isLoading || receiptIsLoading)
  }, [isLoading, receiptIsLoading])


  const handleValidateReceipt = () => {
    app.showModal({
      component: (
        <ReceiptModal />
      )
    })
  }

  if (!shelf_number) {
    return (
      <div className="container">
        <HeaderBar />
        <Container maxWidth="xl" sx={{ my: 2 }}>
          <Box mb={3} className="flex-row align-center">
            <span className="subtitle">Tu Anaquel</span>
            <Button variant="contained" color="secondary" sx={{ marginLeft: 'auto' }} onClick={() => window.location.href = 'https://www.comouna.mx/anaquel-comouna'}>
              Conocer más
            </Button>
            <Button variant="contained" sx={{ marginLeft: '15px' }} onClick={() => window.location.href = 'https://t.me/Comouna'}>
              Solicitar instalación
            </Button>
          </Box>
          <div className="shelf-empty-state">
            <Box className="main text-center" p={3} mb={3}>
              <img src={ShelfImage} alt="Anaquel" style={{ height: '250px'}} />
              <Box className="headlineSmall" my={2}>
                <b>Crece tu oferta en tienda y mejora tus ingresos.</b>
              </Box>
              <Box className="bodyLarge" mb={2}>
                El Anaquel Comouna no tiene costo y te permite ofrecer productos locales a consignación, pagando sólo lo que vendes.
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    )
  }

  return (
    <div className="container">
      <HeaderBar />
      <Container maxWidth="xl" sx={{ my: 2 }}>
        <Box mb={3} className="flex-row align-center">
          <span className="subtitle">Tu Anaquel</span>
          <Button variant="contained" sx={{ marginLeft: 'auto' }} onClick={handleValidateReceipt}>
            Confirmar conteo o resurtido
          </Button>
        </Box>
        <Box className="shelf-cards" mb={3}>
          <Box minHeight="90px" className="card align-center flex-column justify-center">
            <Box className="title" sx={{ color: colors.hanpurple }} mb={1}>
              {shelf_number}
            </Box>
            <Box className="bodySmall">
              Número de Anaquel
            </Box>
          </Box>
          <Box minHeight="90px" className="card align-center flex-column justify-center">
            <Box mb={1} className="bodySmall">
              Instalación: <span style={{ color: colors.hanpurple }} className="capitalize">{formatMedDate(installation_date)}</span>
            </Box>
            <Box className="bodySmall">
              Recepción estimada de producto: <span style={{ color: colors.hanpurple }}>N/A</span>
            </Box>
          </Box>
        </Box>
        
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} variant="fullWidth" >
              <Tab label="Productos" value="1" />
              <Tab label="Recibos" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <Box mt={3}>
              {products && 
                <ProductsTable rows={products} />
              }
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <Box mt={3}>
              {receipts && 
                <ReceiptsTable rows={receipts} />
              }
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </div>
  )
}

export default Shelf