import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getMenu } from 'services/store'
import { transaction, sendReceipt, getProductBySlug, verifyTransaction, getTransactionBalance } from 'services/sales'

export const getMenuItems = createAsyncThunk(
  'store/getMenuItems',
  async () => {
    const response = await getMenu()
    return response
  }
)

export const startTransaction = createAsyncThunk(
  'store/startTransaction',
  async ({
    store_id,
    sku,
    reference,
    receipt_phone,
    amount
  }) => {
    const response = await transaction({
      store_id,
      sku,
      reference,
      receipt_phone,
      amount
    })
    return response
  }
)

export const sendReceiptData = createAsyncThunk(
  'store/sendReceiptData',
  async ({ notification_key, sale_id, other_phone }) => {
    const response = await sendReceipt({ notification_key, sale_id, other_phone })
    return response
  }
)

export const verifyTransactionData = createAsyncThunk(
  'store/verifyTransactionData',
  async ({ sale_id }, { getState }) => {
    // console.log('sale_id', sale_id)
    const { verify_transaction } = getState().sales
    // console.log('verifyTransactionData call', { sale_id, verify_transaction })
    if (verify_transaction?.status !== 'init') {
      const response = await verifyTransaction({ sale_id })
      return response
    }
    return {}
  }
)

export const fetchTransactionBalance = createAsyncThunk(
  'store/fetchTransactionBalance',
  async ({ sku, reference }) => {
    const response = await getTransactionBalance({ sku, reference })
    return response
  }
)

export const fetchProductBySlug = createAsyncThunk(
  'store/fetchProductBySlug',
  async (slug) => {
    const response = await getProductBySlug({ slug })
    return response
  }
)

const menuInit = {
  isLoading: false,
  error: null,
  // success: false,
  items: null,
  currentMenu: null,
  cellphonePlans: null,
  checkoutOptions: null,
  selectedProduct: null,
}

const transactionInit = {
  isLoading: false,
  error: null,
}

const verifyTransactionInit = {
  isLoading: false,
  error: null,
  status: '',
  message: '',
  data: {
    receipt_link: '',
    description: '',
    auth_number: '',
    sale_id: ''
  }
}

const receiptInit =  {
  isLoading: false,
  error: null,
  ready: null,
}

const productInit = {
  service_fee: 0
}

const initialState = {
  scannedCode: null,
  top_products: [],
  menu: menuInit,
  transaction: transactionInit,
  sale_id: null,
  verify_transaction: verifyTransactionInit,
  receipt: receiptInit,
  // product: productInit,
  service_fee: 0,
  notified_ids: {},

  isLoading: false,
  error: null,

  consulted_amount: null,
  isConsultingAmount: false,
  consultAmountError: null,
}

const salesSlice = createSlice({
  name: 'sales',
  initialState: initialState,
  reducers: {
    setCurrentItems: (state, action) => {
      state.menu.currentMenu = action.payload
    },
    setCellphonePlans: (state, action) => {
      state.menu.cellphonePlans = action.payload
    },
    setCheckoutOptions: (state, action) => {
      state.menu.checkoutOptions = action.payload
    },
    setSelectedProduct: (state, action) => {
      state.scannedCode = null
      state.menu.selectedProduct = action.payload
    },
    setScannedCode: (state, action) => {
      state.scannedCode = action.payload
    },
    addNotificationId: (state, action) => {
      state.notified_ids[action.payload] = true
    },
    setNavigateHome: (state) => {
      state.navigateHome = true
    },
    clearProduct: (state) => {
      state.product = productInit
      state.scannedCode = null
      state.menu.selectedProduct = null
      state.service_fee = 0
      state.consulted_amount = null
    },
    clearCheckout: (state) => {
      state.menu = { ...menuInit, items: state.menu.items }
      state.transaction = transactionInit
      state.receipt = receiptInit
      state.navigateHome = null
      state.scannedCode = null
      state.product = productInit
      state.service_fee = 0
      state.consulted_amount = null
      state.consultAmountError = null
      // state.transaction.error = null
      // state.verify_transaction = verifyTransactionInit
    },
    clearErrors: (state) => {
      state.transaction.error = null
      state.receipt.error = null
      state.menu.error = null
      state.consultAmountError = null
    },
    setBalance: (state, action) => {
      state.balance = action.payload
    },
    clearVerifyTransaction: (state) => {
      state.verify_transaction = verifyTransactionInit
    },
    clearStoreState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuItems.pending, (state) => {
      state.menu.isLoading = true
      state.menu.error = null
    })
    builder.addCase(getMenuItems.fulfilled, (state, action) => {
      const { data } = action.payload
      // console.log('getMenuItems.fulfilled', data)
      state.menu.isLoading = false
      state.menu.items = data
    })
    builder.addCase(getMenuItems.rejected, (state, action) => {
      state.menu.isLoading = false
      state.menu.error = action.error.message
    })

    builder.addCase(startTransaction.pending, (state) => {
      state.transaction.isLoading = true
      state.transaction.error = null
    })
    builder.addCase(startTransaction.fulfilled, (state, action) => {
      const { data } = action.payload
      state.scannedCode = null
      state.transaction.isLoading = false
      if (!data?.success && data?.message) {
        state.transaction.error = data.message
      } else {
        state.sale_id = data?.sale_id
        state.transaction.error = null
      }
    })
    builder.addCase(startTransaction.rejected, (state, action) => {
      state.scannedCode = null
      state.transaction.isLoading = false
      state.transaction.error = action.error.message
    })


    builder.addCase(sendReceiptData.pending, (state) => {
      state.receipt.isLoading = true
      state.receipt.error = null
      state.receipt.ready = false
    })
    builder.addCase(sendReceiptData.fulfilled, (state) => {
      state.receipt.isLoading = false
      state.receipt.error = null
      state.receipt.ready = true
    })
    builder.addCase(sendReceiptData.rejected, (state, action) => {
      console.log(action.error)
      state.receipt.isLoading = false
      state.receipt.error = action.error.message
      state.receipt.ready = true
    })

    builder.addCase(verifyTransactionData.pending, (state) => {
      state.verify_transaction.isLoading = true
      state.verify_transaction.error = null
    })
    builder.addCase(verifyTransactionData.fulfilled, (state, { payload }) => {
      // console.log('verifyTransactionData', JSON.stringify(payload))
      if (payload?.data?.status !== 'init') {
        const { data } = payload
        state.verify_transaction.status = data?.status
        state.verify_transaction.message = data?.message
        state.verify_transaction.data = {
          ...data,
          receipt_link: data?.receipt_link,
          description: data?.description,
          auth_number: data?.auth_number,
          sale_id: data?.sale_id
        }
      }
      state.verify_transaction.message = payload?.message
      state.verify_transaction.isLoading = false
    })
    builder.addCase(verifyTransactionData.rejected, (state, action) => {
      console.log(action.error)
      state.verify_transaction.isLoading = false
      state.verify_transaction.error = action.error.message
    })
    
    builder.addCase(fetchTransactionBalance.pending, (state) => {
      state.isConsultingAmount = true
      state.consultAmountError = null
    })
    builder.addCase(fetchTransactionBalance.fulfilled, (state, { payload }) => {
      // console.log('fetchTransactionBalance', payload)
      state.isConsultingAmount = false
      if (!payload?.success) {
        state.consultAmountError = payload?.data?.message
      } else {
        state.consulted_amount = payload?.data
      }
    })
    builder.addCase(fetchTransactionBalance.rejected, (state, action) => {
      if (action?.error?.code === 'ERR_BAD_REQUEST') {
        state.consultAmountError = 'Referencia invalida'
      } else {
        state.consultAmountError = action.error.message
      }
      state.isConsultingAmount = false
      state.consulted_amount = null
    })

    builder.addCase(fetchProductBySlug.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(fetchProductBySlug.fulfilled, (state, { payload }) => {
      console.log('fetchProductBySlug.fulfilled', payload)
      state.isLoading = false
      // state.menu.selectedProduct = payload?.topups
      state.menu.checkoutOptions = payload
    })
    builder.addCase(fetchProductBySlug.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })
  },
})

export const {
  setCurrentItems,
  setCellphonePlans,
  setCheckoutOptions,
  setSelectedProduct,
  setScannedCode,
  addNotificationId,
  setNavigateHome,
  clearCheckout,
  setBalance,
  clearErrors,
  clearVerifyTransaction,
  clearProduct,
  clearStoreState,
} = salesSlice.actions
export default salesSlice.reducer