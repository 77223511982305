import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getShelfInfo,
  getConsignmentById,
  setConsignmentSettle,
  qualifySupplier,
  getConsignments
} from 'services/shelf'

export const getShelf = createAsyncThunk(
  'shelf/getShelf',
  async ({ shelf_number }) => {
    const response = await getShelfInfo({ shelf_number })
    return response
  }
)

export const getReceiptById = createAsyncThunk(
  'shelf/getReceiptById',
  async ({ consignment_id }) => {
    const response = await getConsignmentById({ consignment_id })
    return response
  }
)

export const settleConsignment = createAsyncThunk(
  'shelf/settleConsignment',
  async ({ consignment_id, status, device_id, location, version_app }) => {
    const response = await setConsignmentSettle({
      status,
      consignment_id,
      device_id,
      location,
      version_app
    })
    return response
  }
)

export const rateConsignment = createAsyncThunk(
  'shelf/rateConsignment',
  async ({ consignment_id, rate, attribute, comments }) => {
    const response = await qualifySupplier({ consignment_id, rate, attribute, comments })
    if (response.success) {
      return response
    } else {
      throw new Error('Error inesperado')
    }
  }
)

export const getReceipts = createAsyncThunk(
  'shelf/getReceipts',
  async ({ page = 0, size = 0 }) => {
    const response = await getConsignments({ page, size })
    if (response.success) {
      return response
    } else {
      throw new Error('Error inesperado')
    }
  }
)

const initialState = {
  id: '',
  shelf_number: '',
  installation_date: null,
  shelf_dimensions: null,
  shelf_type: '',
  products: [],

  isLoading: false,
  error: null,

  receipt: {
    id: '',
    shelf_number: '',
    created_date: '',
    vendor_id: '',
    status: '',
    to_pay: 0,
    total_cost: 0,
    estimated_profit: 0,
    order_details: [],
    receipt_user: null,
    producer_logo: null
  },

  receipts: [],

  receiptIsLoading: false,
  receiptError: null,

  settleIsLoading: false,
  settleError: null,
  settleStatus: '',

  rateIsLoading: false,
  rateError: null,
  rateSuccess: null
}

const shelfSlice = createSlice({
  name: 'shelf',
  initialState,
  reducers: {
    resetSettle: (state) => {
      state.settleStatus = ''
      state.settleIsLoading = false
      state.settleError = null
      state.rateSuccess = null
      state.rateError = null
    },
    clearReceipt: (state) => {
      state.receipt = initialState.receipt
    },
    clearShelfState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getShelf.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getShelf.fulfilled, (state, action) => {
      const { data } = action.payload
      state.id = data?.id
      state.products = data?.products || []
      state.installation_date = data?.installation_date

      state.isLoading = false
      state.error = null
    })
    builder.addCase(getShelf.rejected, (state, action) => {
      console.log(action.error)
      state.isLoading = false
      state.error = action.error.message
    })

    builder.addCase(getReceiptById.pending, (state) => {
      state.receiptIsLoading = true
      state.receiptError = null
    })
    builder.addCase(getReceiptById.fulfilled, (state, action) => {
      const { data } = action.payload
      // console.log('payload', data)
      state.receiptIsLoading = false

      state.receipt.id = data?.id
      state.receipt.shelf_number = data?.shelf_number
      state.receipt.created_date = data?.created_date
      state.receipt.vendor_id = data?.vendor_id
      state.receipt.status = data?.status
      state.receipt.producer_name = data?.producer_name
      state.receipt.estimated_profit = data?.estimated_profit
      state.receipt.to_pay = data?.to_pay
      state.receipt.total_cost = data?.total_cost
      state.receipt.order_details = data?.order_details || []
      state.receipt.receipt_user = data?.receipt_user || null
      state.receipt.producer_logo = data?.producer_logo || null
    })
    builder.addCase(getReceiptById.rejected, (state, action) => {
      console.log(action.error)
      state.receiptIsLoading = false
      state.receiptError = action.error.message
    })

    builder.addCase(settleConsignment.pending, (state) => {
      state.settleIsLoading = true
      state.settleError = null
    })
    builder.addCase(settleConsignment.fulfilled, (state, action) => {
      console.log('settleConsignment', action.payload)
      state.settleStatus = action.payload?.status
      state.receipt.status = action.payload?.status
      state.settleIsLoading = false
      state.settleError = null
    })
    builder.addCase(settleConsignment.rejected, (state, action) => {
      console.log(action.settleError)
      state.settleIsLoading = false
      state.settleError = action.error.message
    })

    builder.addCase(rateConsignment.pending, (state) => {
      state.rateIsLoading = true
      state.rateError = null
      state.rateSuccess = null
    })
    builder.addCase(rateConsignment.fulfilled, (state, action) => {
      state.rateSuccess = true
      state.rateIsLoading = false
      state.rateError = null
    })
    builder.addCase(rateConsignment.rejected, (state, action) => {
      console.log(action)
      state.rateSuccess = null
      state.rateIsLoading = false
      state.rateError = action.error.message
    })

    builder.addCase(getReceipts.pending, (state) => {
      state.rateIsLoading = true
      state.rateError = null
      state.rateSuccess = null
    })
    builder.addCase(getReceipts.fulfilled, (state, action) => {
      console.log('getReceipts')
      state.receipts = action.payload?.data?.Data
      state.rateIsLoading = false
      state.rateError = null
    })
    builder.addCase(getReceipts.rejected, (state, action) => {
      console.log(action)
      state.rateSuccess = null
      state.rateIsLoading = false
      state.rateError = action.error.message
    })
  },
})

export const { resetSettle, clearReceipt, clearShelfState } = shelfSlice.actions
export default shelfSlice.reducer