import React from 'react'
import { DateTime } from 'luxon'
import { Box, Button } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'

import { useAppContext } from 'providers/AppProvider'
import SendReceiptModal from './modals/SendReceiptModal'
import SupportModal from './modals/SupportModal'

import PaymentDetail from './templates/PaymentDetail'
import CellphonePlanDetail from './templates/CellphonePlanDetail'
import ConsignmentDetail from './templates/ConsignmentDetail'
import DiscrepancyDetail from './templates/DiscrepancyDetail'
import FinanceDetail from './templates/FinanceDetail'
import ReferencedDetail from './templates/ReferencedDetail'

function Detail({ row }) {
  const app = useAppContext()
  const isPayment = row?.groupInfo?.icon === 'payment'
  const isCellphonePlan = row?.group === 'telephony'
  const hasError = row?.status === 'error'
  const isConsignment = row?.group === 'consignment'
  const formattedDate = DateTime.fromISO(row?.date, { zone: 'utc' }).toLocal().toFormat('dd/MM/yyyy')
  const formattedTime = DateTime.fromISO(row?.date, { zone: 'utc' }).toLocal().toFormat('HH:mm:ss')
  const isFinance = row?.group === 'finance-charges'
  const isDiscrepancy = row?.group === 'discrepancy'

  const handleSendReceipt = () => {
    app.showModal({ component: <SendReceiptModal sale_id={row?.id} /> })
  }

  const openSupportModal = () => {
    app.showModal({ component: <SupportModal openSupport={openSupport} /> })
  }

  const openSupport = () => {
    try {
      window.location.href = `https://t.me/comouna`
    } catch (error) {
      alert('No se pudo abrir WhatsApp')
    }
  }

  const renderDetailTemplate = () => {
    const customRow = {
      ...row,
      hasError
    }
    if (isPayment) {
      return <PaymentDetail row={customRow} formattedDate={formattedDate} formattedTime={formattedTime} />
    } else if (isCellphonePlan) {
      return <CellphonePlanDetail row={customRow} formattedDate={formattedDate} formattedTime={formattedTime} />
    } else if (isConsignment) {
      return <ConsignmentDetail row={customRow} formattedDate={formattedDate} formattedTime={formattedTime} />
    } else if (isDiscrepancy) {
      return <DiscrepancyDetail row={customRow} formattedDate={formattedDate} formattedTime={formattedTime} />
    } else if (isFinance) {
      return <FinanceDetail row={customRow} formattedDate={formattedDate} formattedTime={formattedTime} />
    } else {
      return <ReferencedDetail row={customRow} formattedDate={formattedDate} formattedTime={formattedTime} />
    }
  }

  return (
    <Box p={2} className="activity-detail">
      {renderDetailTemplate()}
      <Box mt={2}>
        {(!isPayment && !hasError && !isConsignment && !isFinance && !isDiscrepancy) && 
          <Button variant="contained" sx={{ marginRight: '20px' }} onClick={handleSendReceipt}>
            Enviar comprobante
          </Button>
        }
        <Button variant="contained" color="secondary" startIcon={<HelpOutline />} onClick={openSupportModal}>
          Ayuda
        </Button>
      </Box>
    </Box>
  )
}

export default Detail
