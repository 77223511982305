import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getDeeplinkId, getLead, getEmployeeByTempId } from '../../services/registry'

export const getProspectId = createAsyncThunk(
  'registry/getProspectId',
  async ({ temp_id }) => {
    const res = await getDeeplinkId({ temp_id } )
    if (res?.data) {
      return res.data
    } else {
      throw new Error('No se encuentra el prospecto')
    }
  }
)

export const getEmployeeId = createAsyncThunk(
  'registry/getEmployeeId',
  async ({ temp_id }) => {
    const res = await getDeeplinkId({ temp_id } )
    if (res?.data) {
      return res.data
    } else {
      throw new Error('No se encuentra el prospecto')
    }
  }
)

export const getProspect = createAsyncThunk(
  'registry/getProspect',
  async ({ prospect_id }) => {
    const res = await getLead({ prospect_id } )
    if (res?.data) {
      return res.data
    } else {
      throw new Error('No se encuentra el prospecto')
    }
  }
)

export const getEmployee = createAsyncThunk(
  'registry/getEmployee',
  async ({ employee_id }) => {
    const res = await getEmployeeByTempId({ employee_id } )
    if (res?.data) {
      return res.data
    } else {
      throw new Error('No se encuentra el empleado')
    }
  }
)

const initialState = {
  isLoading: false,
  error: null,
  isEmployeeLoading: false,

  prospect_id: '',
  employee_id: '',
  email: '',
  username: '',
  password: '',
  possible_installation_date: null,
  want_shelf: null,

  employee: {
    phone_number: ''
  }
}

const registrySlice = createSlice({
  name: 'registry',
  initialState,
  reducers: {
    savePassword(state, action) {
      state.password = action.payload.password
    },
    clearRegistryState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProspectId.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProspectId.fulfilled, (state, { payload }) => {
      console.log('getProspectId.fulfilled', payload)
      state.isLoading = false
      state.prospect_id = payload || ''
    })
    builder.addCase(getProspectId.rejected, (state, action) => {
      console.log('getProspectId.rejected', action.error)
      state.isLoading = false
      state.error = action.error.message
    })

    builder.addCase(getEmployeeId.pending, (state) => {
      state.isEmployeeLoading = true
      state.employee_id = ''
    })
    builder.addCase(getEmployeeId.fulfilled, (state, action) => {
      state.isEmployeeLoading = false
      state.employee_id = action.payload || ''
    })
    builder.addCase(getEmployeeId.rejected, (state, action) => {
      console.log(action.error)
      state.isEmployeeLoading = false
      state.error = action.error.message
    })

    builder.addCase(getProspect.pending, (state) => {
      state.isLoading = true
      state.possible_installation_date = null
      state.want_shelf = null
    })
    builder.addCase(getProspect.fulfilled, (state, { payload }) => {
      console.log('getProspect.fulfilled')
      state.isLoading = false
      state.email = payload?.email || ''
      state.username = payload?.phone || ''
      state.possible_installation_date = payload?.possible_installation_date || null
      state.want_shelf = payload?.want_shelf || false
    })
    builder.addCase(getProspect.rejected, (state, action) => {
      console.log('getProspect.rejected', action.error)
      state.isLoading = false
      state.error = action.error.message
    })

    builder.addCase(getEmployee.pending, (state) => {
      state.isEmployeeLoading = true
    })
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      state.isEmployeeLoading = false
      state.employee = action.payload
    })
    builder.addCase(getEmployee.rejected, (state, action) => {
      console.log(action.error)
      state.isEmployeeLoading = false
      state.error = action.error.message
    })
  },
})

export const { savePassword, clearRegistryState } = registrySlice.actions
export default registrySlice.reducer