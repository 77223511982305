import { Box } from '@mui/material'
import { toCurrency } from 'utils/formats'
import colors from 'styles/colors'

function ConsignmentDetail({ row, formattedDate, formattedTime }) {
  const hasDetails = row?.details !== null && row?.details !== undefined
  const details = hasDetails ? JSON.parse(row.details) : {}

  return (
    <>
      <table width="100%" className="subtable">
        <tbody>
          <tr>
            <td>Monto:</td>
            <td>
              {toCurrency(row?.amount + row?.fee)}
            </td>
          </tr>
          <tr>
            <td>Por pagar:</td>
            <td>{toCurrency(row?.to_pay)}</td>
          </tr>
          <tr>
            <td>Comisión ganada:</td>
            <td>{toCurrency(row?.fee)}</td>
          </tr>
          {(row?.reference) &&
            <tr>
              <td style={{ minWidth: 100 }}>
                Recibo:
              </td>
              <td>
                {row?.reference || 'N/A'}
              </td>
            </tr>
          }
          <tr>
            <td>Fecha:</td>
            <td>{formattedDate}</td>
          </tr>
          <tr>
            <td>Hora:</td>
            <td>{formattedTime}</td>
          </tr>
          <tr>
            <td>Responsable:</td>
            <td>{row?.delivery_user || 'N/A'}</td>
          </tr>
        </tbody>
      </table>
      {details?.consigment && (
        <>
          <Box my={2} className="bodyLarge">
            <b>Ventas reconocidas</b>
          </Box>
          <table width="100%" className="subtable" style={{ marginTop: '20px' }}>
            <thead>
              <tr>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>Precio tienda</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {details?.consigment?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.name}</td>
                  <td>{item?.units}</td>
                  <td>{toCurrency(item?.store_cost)}</td>
                  <td>{toCurrency(item?.store_cost * item?.units)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  )
}

export default ConsignmentDetail