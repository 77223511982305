import request from 'utils/request'

export const getInfo = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/info`,
  })
  return response?.data
}

export const getMenu = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/menu`,
  })
  return response?.data
}

export const transaction = async ({
  store_id,
  sku,
  reference,
  receipt_phone,
  amount
}) => {
  console.log('REQ', {
    store_id,
    sku,
    reference,
    receipt_phone,
    amount
  })
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/store/generate_tx`,
    data: {
      store_id,
      sku,
      reference,
      receipt_phone,
      amount
    },
  })
  return response?.data
}


export const getPaymentInfo = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/payment_info`,
  })
  return response?.data
}