import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, InputAdornment, IconButton, Button } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import Input from 'components/Input'
import PasswordRule from 'components/PasswordRule'
import { containsUppercase, containsLowercase, containsNumber, minLength } from 'utils/validations'
import Logo from 'assets/logo.svg'
import Loading from 'components/Loading'
import { restorePassword } from 'store/slices/identity'
import CircleCheck from 'assets/CircleCheck.svg'

function RestorePassword() {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { handleSubmit, watch, control } = useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { 
    passRestError,
    passRestSuccess,
    passRestIsLoading,
  } = useSelector(state => state.identity)

  const password = watch('password')
  const passwordConfirm = watch('confirm_password')

  const passwordsMatch = password === passwordConfirm
  const passwordComplex = containsUppercase(password) && containsLowercase(password) && containsNumber(password)

  useEffect(() => {
    setLoading(passRestIsLoading)
  }, [passRestIsLoading])

  const onSubmit = async (data) => {
    setLoading(true)
    if (containsUppercase(data.password) && containsLowercase(data.password) && containsNumber(data.password)) {
      dispatch(restorePassword({ deeplink_id: params?.token, password: data.password }))
    }
    setLoading(false)
  }

  if (passRestSuccess) {
    return (
      <Box className="align-center justify-center" minHeight="100vh">
        <Box maxWidth={350} className="align-center text-center flex-column" mt={2}>
          <Box mb={3}>
            <img src={CircleCheck} alt=""/>
          </Box>
          <h1 className="title-success">
            Tu contraseña fue cambiada con éxito
          </h1>
          <Box mt={5} className="bodySmall" width="100%">
            <Button onClick={() => navigate('/login')} fullWidth variant="contained">
              Ingresar
            </Button>
          </Box>
        </Box>
      </Box>  
    )
  }

  return (
    <Box className="align-center justify-center" minHeight="100vh">
      <Box width='100%' maxWidth={350} className="align-center flex-column">
        <Loading loading={isLoading || passRestIsLoading} />
        <img src={Logo} alt="Logo Comouna" width="270px" />
        <Box sx={{ textAlign: 'left' }} width="100%" mt={4} mb={2}>
          <span className="password-title">Crea tu contraseña</span>
        </Box>
        <Input
          label="Contraseña"
          name="password"
          control={control}
          rules={{
            required: true,
            validate: {
              minLength: value => minLength(value, 8) || 'Debe contener al menos 8 caracteres',
            }
          }}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          autoFocus
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(prev => !prev)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />

        <Input
          label="Confirmar Contraseña"
          name="confirm_password"
          control={control}
          rules={{
            required: true,
            validate: {
              minLength: value => minLength(value, 8) || 'Debe contener al menos 8 caracteres',
            }
          }}
          type={showPassword2 ? 'text' : 'password'}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword2(prev => !prev)}
              >
                {showPassword2 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />

        <Box className="rules-wrapper" width="100%">
          {(!passwordsMatch || passRestError) && 
            <>
              <span className="bodyLarge">Error</span>
              {!passwordsMatch && (
                <PasswordRule
                  password={'password'}
                  validation={() => false}
                  message="Las contraseñas no coinciden"
                />
              )}
              {passRestError && (
                <PasswordRule
                  password={'password'}
                  validation={() => false}
                  message="Error del sistema, intente de nuevo"
                />
              )}
            </>
          }
          <Box className="bodyLarge">Debe contener</Box>
          <PasswordRule
            password={password}
            validation={() => minLength(password, 8)}
            message="al menos 8 caracteres"
          />
          <PasswordRule
            password={password}
            validation={containsUppercase}
            message="al menos una mayúscula"
          />
          <PasswordRule
            password={password}
            validation={containsLowercase}
            message="al menos una minúscula"
          />
          <PasswordRule
            password={password}
            validation={containsNumber}
            message="al menos un número"
          />
        </Box>
        
        <Box mb={3} width="100%">
          <Button
            className='btn-main' 
            onClick={handleSubmit(onSubmit)} 
            fullWidth 
            variant="contained"
            disabled={!passwordComplex || !passwordsMatch}
          >
            Crear cuenta
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default RestorePassword