import React from 'react'

const CatalogSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color || '#541DFF'}
      d="M13.904 21.054c-.288.287-.645.43-1.07.43-.425 0-.781-.143-1.069-.43l-8.8-8.8a1.623 1.623 0 0 1-.34-.5 1.487 1.487 0 0 1-.125-.604V4c0-.409.148-.761.443-1.057A1.443 1.443 0 0 1 4 2.5h7.15a1.572 1.572 0 0 1 1.085.446l8.8 8.816c.293.293.442.652.445 1.075.003.424-.139.78-.426 1.067l-7.15 7.15ZM12.83 20l7.155-7.15L11.155 4H4v7.15L12.83 20ZM6.5 7.75c.347 0 .642-.121.886-.364.243-.243.365-.538.365-.885s-.121-.642-.364-.886A1.202 1.202 0 0 0 6.5 5.25c-.347 0-.642.121-.886.364a1.202 1.202 0 0 0-.365.885c0 .347.121.642.364.886.243.243.538.365.885.365Z"
    />
  </svg>
)

export default CatalogSvg
