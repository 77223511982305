import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

import Input from 'components/Input'
import OptionsPicker from 'components/OptionsPicker'
import colors from 'styles/colors'

function Pin({ control, watch, configuration }) {
  const { menu: { checkoutOptions } } = useSelector(state => state.sales)
  console.log('checkoutOptions', checkoutOptions)
  // const { payment_info } = configuration || {}

  const amounts = checkoutOptions?.topups?.map((item) => {
    return {
      ...item,
      label: item?.gesto_product_copy,
    }
  })

  return (
    <Box>
      <Box className="service-details">
        <p className="title">Detalles</p>
        <br />
        <p>Comisión a cobrar por servicio: $0.00</p>
        <br />
        <p><PriorityHighIcon sx={{ color: colors.oriolesred, fontSize: '16px' }} /> Llegará un mensaje al número celular ingresado con las instrucciones.</p>
      </Box>
      
      <Box className="headlineSmall" my={2}>
        1: Selecciona producto
      </Box>

      <OptionsPicker options={amounts} control={control} name="option_amount" />

      <Box className="headlineSmall" my={2}>
        2: Ingresa datos
      </Box>

      <Box className="flex-row" sx={{ gap: '20px'}}>
        <Input
          name="phone_number"
          control={control}
          rules={{
            required: true,
            validate: {
              numbers: (value) => /^[0-9]+$/.test(value) || 'Debe contener solo números',
              min: (value) => /^.{10}$/.test(value) || 'Debe contener 10 digitos'
            }
          }}
          label="Ingresa número celular de tu cliente"
          fullWidth
        />
        <Input
          name="confirm_phone"
          control={control}
          rules={{
            required: true,
            validate: {
              numbers: (value) => /^[0-9]+$/.test(value) || 'Debe contener solo números',
              min: (value) => /^.{10}$/.test(value) || 'Debe contener 10 digitos'
            }
          }}
          label="Confirma número celular"
          fullWidth
        />
      </Box>

    </Box>
  )
}

export default Pin
