import './styles.scss'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Box from '@mui/material/Box'
import colors from 'styles/colors'
import { Children, cloneElement } from 'react'

export const TYPES = {
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARNING: 'WARNING'
}

const mapDialogType = (type) => {
  switch(type) {
    case TYPES.SUCCESS: {
      return {
        icon: <CheckCircleOutlineIcon style={{ color: colors.success }} sx={{ marginRight: '10px' }} />,
        title: 'Operación exitosa'
      }
    }
    case TYPES.ERROR: {
      return {
        icon: <ErrorOutlineIcon style={{ color: colors.error }} sx={{ marginRight: '10px' }} />,
        title: 'Ocurrió un error'
      }
    }
    default: {
      return {
        icon: null,
        title: ''
      }
    }
  }
}

function Modal({ children, onDismiss, isOpen, type, message, component, title }) {
  const { icon, title: defaultTitle } = mapDialogType(type)

  const childrenWithProps = Children.map(component, (child, index) => {
    return cloneElement(child, {
      onDismiss: () => onDismiss(),
      index,
    })
  })

  const modalTitle = title || defaultTitle

  if (component) {
    return (
      <Dialog open={isOpen} onClose={onDismiss} className="modal">
        {childrenWithProps}
      </Dialog>
    )
  }
  
  if (message || children || modalTitle) {
    return (
      <Dialog open={isOpen} onClose={onDismiss} className="modal">
        <DialogContent>
          <Box minWidth={300}>
            {modalTitle &&
              <div className='title'>
                {icon} {modalTitle}:
              </div>
            }
            {message && <div>{message}</div>}
            {children}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss} type="submit" variant="contained">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return null

}

export default Modal