
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { recoverPassword } from 'store/slices/identity'
import Input from 'components/Input'
import Loading from 'components/Loading'
import Logo from 'assets/logo.svg'
import { Button } from '@mui/material'
import CircleCheck from 'assets/CircleCheck.svg'
import { useAppContext, MODAL_TYPES } from 'providers/AppProvider'

function PasswordRecovery() {
  const app = useAppContext()
  const { control, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const { passwordRecovery: { isLoading, error, success }} = useSelector(state => state.identity)

  const onSubmit = (data) => {
    dispatch(recoverPassword({ email: data?.email }))
  }
  
  useEffect(() => {
    if (error) {
      app.showModal({
        type: MODAL_TYPES.ERROR,
        message: error
      })
    }
  }, [error])

  if (success) {
    return (
      <Box className="align-center justify-center" minHeight="100vh">
        <Box maxWidth={350} className="align-center text-center flex-column" mt={2}>
          <Box mb={3}>
            <img src={CircleCheck} alt=""/>
          </Box>
          <h1 className="title-success">
            Te enviamos un correo para cambiar tu contraseña
          </h1>
          <Box mt={5} className="bodySmall" >
            Recuerda revisar tu carpeta de SPAM o correo no deseado.
          </Box>
        </Box>
      </Box>  
    )
  }

  return (
    <Box className="align-center justify-center" minHeight="100vh">
      <Loading loading={isLoading} />
      <Box maxWidth={350} mt={2} className="align-center flex-column">
        <Box mb={4}>
          <img src={Logo} alt="Logo Comouna" width="270px" />
        </Box>
        <Box mb={3} width="100%">
          <span className="password-title">Cambiar contraseña</span>
        </Box>
        <Box mb={2}>
          Escribe los datos con los que creaste tu cuenta, recibirás un correo para recuperar tu contraseña.
        </Box>
        <Input
          name="email"
          control={control}
          label="Correo electrónico"
          rules={{
            required: true,
          }}
          fullWidth
        />
        <Box mt={3} className="bodySmall">
          ¿No recuerdas tus datos? Escríbenos a <a href="mailto:contacto@don-app.com.mx">contacto@don-app.com.mx</a>
        </Box>
        <Box mt={3} width="100%">
          <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={isLoading} fullWidth>
            Continuar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PasswordRecovery