import request from 'utils/request'

export const removeNotification = async ({ notification_key, path }) => {
  try {
    const response = await request({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/notifications/${notification_key}`,
      data: {
        path
      }
    })
    return response?.data
  } catch(error) {
    console.log(error.response)
  }
}