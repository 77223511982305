import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import colors from 'styles/colors'

function ConfirmationModal({ onDismiss, onConfirm }) {
  return (
    <Box px={4} pb={4} pt={2} className="confirmation-modal">
      <Box mb={3} className="flex-column align-center">
        <WarningAmberIcon style={{ fontSize: '55px', color: colors.primary }}/>
        <Box className="headlineSmall" mt={1}>¡Importante!</Box>
      </Box>
      <Box className="bodyLarge" mb={3} style={{ color: colors.gray_4 }}>
        <p>
          Al{' '}<b>continuar</b>{' '}confirmas que el conteo o surtido, las ventas reconocidas y los saldos por pagar.{'\n\n'}
        </p>
        <br />
        <p>
          <b>Aceptar surtidos sin registrar puede resultar en el retiro del anaquel y perder oportunidades de venta.</b>
        </p>
      </Box>
      <Box className="flex-row" sx={{ justifyContent:'flex-end', gap: '20px' }}>
        <Button variant="outlined" onClick={onDismiss}>
          Cancelar
        </Button>
        <Button onClick={onConfirm} variant="contained">
          Entiendo
        </Button>
      </Box>
    </Box>
  )
}

export default ConfirmationModal