import { formatDate } from '../utils/formats'
import request from 'utils/request'

export const getContract = async ({ prospect_id }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/contracts/${prospect_id}`,
    auth: false
  })
  
  return response?.data
}

export const getDeeplinkId = async ({ temp_id }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/deeplink/${temp_id}`,
    auth: false
  })
  
  return response?.data
}

export const getEmployeeByTempId = async ({ employee_id }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/employee/${employee_id}`,
    auth: false
  })
  
  return response?.data
}

export const getLead = async ({ prospect_id }) => {
  try {
    const response = await request({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/store/prospect/${prospect_id}`,
      auth: false
    })
  
    return response?.data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
}

export const addShelfInstallationDate = async ({ prospect_id, shelf_installation_date }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/lead/dateinstall/${prospect_id}`,
    data: {
      shelf_installation_date: formatDate(shelf_installation_date)
    },
    auth: false
  })

  return response?.data
}
