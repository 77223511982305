import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Box, Button } from '@mui/material'
import { SaveAlt } from '@mui/icons-material'
import { useNavigate, Link } from 'react-router-dom'

import HeaderBar from 'components/HeaderBar'
import { getStore } from 'store/slices/store'
import { getMenuItems } from 'store/slices/sales'
import { fetchProfile } from 'store/slices/identity'
import BalanceItem from 'components/BalanceItem'
import colors from 'styles/colors'
import { formatDay, latestDateFormattedOrCurrent } from 'utils/formats'
import AccordionCard from 'components/AccordionCard'
import PaymentByBankTransfer from 'components/PaymentByBankTransfer'
import PaymentByCashDeposit from 'components/PaymentByCashDeposit'
import { useAppContext, MODAL_TYPES } from 'providers/AppProvider'
import { ServiceImage } from 'pages/Services'
import DownloadReportModal from 'components/DownloadReportModal'

function Home() {
  const app = useAppContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { profileIsLoading } = useSelector(state => state.identity)
  const { balance, top_products, isLoading, store_name, error, errorCode } = useSelector(store => store.store)
  // const { menu } = useSelector(store => store.sales)
  const { payment_day, fees, sales, due, next_payment_date, status } = balance || {}

  // const initialLoading = useMemo(() => isLoading || profileIsLoading, [isLoading, profileIsLoading])
  // const initialFetch = useMemo(() => {
  //   return () => {
  //     if (!isLoading) {
  //       dispatch(getStore())
  //     }
  //     if (!profileIsLoading) {
  //       dispatch(fetchProfile())
  //     }
  //   }
  // }, [isLoading, profileIsLoading])

  useEffect(() => {
    if (!isLoading) {
      dispatch(getStore())
    }
    if (!profileIsLoading) {
      dispatch(fetchProfile())
    }
    // initialFetch()
  }, [])

  useEffect(() => {
    if (errorCode === 'ERR_BAD_REQUEST') {
      navigate('/logout')
    }
  }, [errorCode])

  const displayDate = latestDateFormattedOrCurrent(next_payment_date)

  useEffect(() => {
    app.setIsLoading(profileIsLoading || isLoading)
  }, [isLoading, profileIsLoading])

  useEffect(() => {
    if (error) {
      app.showModal({ type: MODAL_TYPES.ERROR, message: error })
    }
  }, [error])

  const handleCheckout = (item) => {
    const category = item?.slug_category || 'others'
    if (item?.screen_name === 'CellphonePlan') {
      navigate(`/checkout/${category}/product/${item.slug}?form_type=cellphone_minutes`)
    } else {
      navigate(`/checkout/${category}/product/${item.slug}?form_type=${item?.form_type}`)
    }
  }

  const handleDownloadAccountStatus = async () => {
    app.showModal({
      component: <DownloadReportModal />,
    })
  }

  return (
    <div className="container">
      <HeaderBar />
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        <Box pb={3} className="welcome" display="flex" sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box>
            <span className="message">Bienvenido/a,</span>
            <div>
              <span className="user-name">{store_name}</span>
            </div>
          </Box>
          <Box pt={1}>
            <Button size="small" variant="contained" onClick={handleDownloadAccountStatus} startIcon={<SaveAlt />}>
              Estado de cuenta
            </Button>
          </Box>
        </Box>
        <Box className="balance-cards">
          <Box className="card text-center">
            <Box mb={1}>
              <span className="title">
                {status === 'due' ? 'Vencido' : 'Al corriente'}
              </span>
              <Box 
                className="circle-status"
                ml={1}
                sx={{ background: status === 'due' ? colors.error : colors.success }}
              />
            </Box>
            <Box className="bodySmall" mb={1}>
              Próximo pago: {displayDate}
            </Box>
            <Box className="bodySmall">
              Día de pago: {formatDay(payment_day)}
            </Box>
          </Box>
          <Box className="card">
            <BalanceItem
              amount={fees || 0}
              label="Comisiones de la semana"
              color={colors.success}
            />
          </Box>
          <Box className="card">
            <BalanceItem
              amount={sales || 0}
              label="Ventas totales de la semana"
              color={colors.midnight}
            />
          </Box>
          <Box className="card">
            <BalanceItem
              amount={Math.abs(due) || 0}
              label="Total por pagar"
              color={colors.oriolesred}
            />
          </Box>
        </Box>

        <Box py={3} sx={{ justifyContent: 'space-between' }} display="flex">
          <span className="subtitle">
            Servicios más usados
          </span>
          <Link to="/services" className="see-more">
            ver todos
          </Link>
        </Box>

        <Box className="services-grid">
          {top_products?.map((product, index) => {
            return (
              <Box key={index} className="product-card" onClick={() => handleCheckout(product)}>
                <ServiceImage service={product} />
              </Box>
            )
          })}
        </Box>

        <Box py={3} sx={{ justifyContent: 'space-between' }} display="flex">
          <span className="subtitle">
            Medios de pago
          </span>
        </Box>

        <Box mb={2}>
          <AccordionCard
            title={
              <Box className="subtitle-small">Transferencia (SPEI)</Box>
            }
            content={
              <PaymentByBankTransfer />
            }
          />
        </Box>
        <Box mb={2}>
          <AccordionCard
            title={
              <Box className="subtitle-small">Depósito en efectivo</Box>
            }
            content={
              <PaymentByCashDeposit />
            }
          />
        </Box>
      </Container>
    </div>
  )
}

export default Home