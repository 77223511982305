import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

const ImageWithFallback = memo(({ source, fallbackText, style, imageStyle, textStyle }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = useCallback(() => {
    setImageError(true);
  }, []);

  if (imageError || !source) {
    return <span style={textStyle}>{fallbackText}</span>;
  }

  return (
    <img
      src={typeof source === 'string' ? source : source.uri}
      alt={fallbackText}
      style={{ ...style, ...imageStyle }}
      onError={handleImageError}
    />
  );
});

ImageWithFallback.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fallbackText: PropTypes.string,
  style: PropTypes.object,
  imageStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

ImageWithFallback.defaultProps = {
  fallbackText: 'Imagen no disponible',
};

export default ImageWithFallback;
