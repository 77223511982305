import { useAppContext } from 'providers/AppProvider'
import Modal from 'components/Modal'
import Notifications from 'components/Notifications'

export default function ModalProvider({ children }) {
  const app = useAppContext()

  return (
    <>
      <Notifications />
      <Modal
        isOpen={app.isOpen}
        onDismiss={app.closeModal}
        type={app.modal.type}
        title={app.modal.title}
        message={app.modal.message}
        component={app.modal.component}
        backgroundColor={app.modal.backgroundColor}
      />
      {children}
    </>
  )
}