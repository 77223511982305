import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box, Button } from '@mui/material'

import { addShelfInstallationDate } from '../../../services/registry'
import RadioGroup from '../../../components/RadioGroup'

function ShelfInstall({ setError, setStep, setLoading }) {
  const { control, handleSubmit, watch } = useForm()
  const { prospect_id, possible_installation_date } = useSelector(state => state.registry)
  const install_date = watch('install_date')

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await addShelfInstallationDate({
        prospect_id,
        shelf_installation_date: data.install_date
      })
      setStep(5)
    } catch (error) {
      console.log(error)
      setError('Error del sistema')
    }
    setLoading(false)
  }

  const installDates = possible_installation_date?.map(date => {
    return {
      label: DateTime.fromISO(date, { zone: 'utc' }).toLocal().setZone('utc').toFormat('ccc dd/MM/yyyy'),
      value: DateTime.fromISO(date, { zone: 'utc' }).toLocal().setZone('utc').toFormat('dd/MM/yyyy')
    }
  })
  
  return (
    <Box maxWidth={350}>
      <h1 className="onboarding-title">
        Instalación de anaquel.
      </h1>
      <Box className="bodyMedium text-darkgrey" mt={4}>
        El anaquel es tu pasaporte para ganancias adicionales. Al ofrecer productos locales, impulsas tu tienda y fortaleces la comunidad, generando ingresos extra sin inversión inicial. Selecciona fecha y hora para su instalación
      </Box>
      <Box className="install-dates">
        <Box mt={4} className="bodyMedium text-deepblue">
          Selecciona una fecha para su instalación.
        </Box>
        {installDates &&
          <RadioGroup
            name="install_date"
            control={control}
            options={installDates}
            rules={{ required: true }}
          />
        }
      </Box>
      <Box mt={5} mb={3}>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!install_date}
          fullWidth
        >
          Continuar
        </Button>
      </Box>
    </Box>
  )
}

export default ShelfInstall