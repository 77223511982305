import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getInfo, getPaymentInfo } from 'services/store'

export const getStore = createAsyncThunk(
  'store/getStore',
  async () => {
    const response = await getInfo()
    return response
  }
)

export const getPaymentInfoData = createAsyncThunk(
  'store/getPaymentInfoData',
  async () => {
    const response = await getPaymentInfo()
    return response
  }
)


const initialState = {
  id: '',
  user_id: '',
  owner_name: '',
  store_name: '',
  phone: '',
  balance: null,
  support_number: '',
  email: '',
  address: null,
  tax_info: null,
  is_prepaid: null,
  preferences: {
    notification_paths: []
  },
  shelves: [],
  top_products: [],
  notified_ids: {},

  isLoading: false,
  error: null,
  errorCode: null,

  isPaymentInfoLoading: false,
  paymentInfoError: null,
  paymentInfo: {
    account_owner: '',
    amount: '',
    cash_reference: '',
    reference: '',
    spei_reference: '',
  },
}

const storeSlice = createSlice({
  name: 'store',
  initialState: initialState,
  reducers: {
    clearStoreState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStore.pending, (state) => {
      state.isLoading = true
      state.error = null
      state.errorCode = null
    })
    builder.addCase(getStore.fulfilled, (state, action) => {
      // console.log('store.fulfilled')
      state.error = null
      state.isLoading = false

      const { data } = action.payload
      state.id = data?.id
      state.user_id = data?.user_id
      state.owner_name = data?.owner_name
      state.store_name = data?.store_name
      state.phone = data?.phone
      state.balance = data?.balance
      state.top_products = data?.top_products
      state.preferences = data?.preferences
      state.shelves = data?.shelves || []
      state.support_number = data?.support_number
      state.email = data?.email
      state.address = data?.address
      state.tax_info = data?.tax_info
      state.is_prepaid = data?.is_prepaid
    })
    builder.addCase(getStore.rejected, (state, action) => {
      console.log(action.error)
      state.isLoading = false
      state.errorCode = action.error.code
      if (action?.error?.code === 'ERR_BAD_RESPONSE') {
        state.error = 'Algo salió mal, por favor intentalo más tarde.'
      } else {
        state.error = action.error.message
      }
      // state.error = action.error.message
    })

    builder.addCase(getPaymentInfoData.pending, (state) => {
      state.isPaymentInfoLoading = true
      state.paymentInfoError = null
    })
    builder.addCase(getPaymentInfoData.fulfilled, (state, { payload }) => {
      state.isPaymentInfoLoading = false

      state.paymentInfo.account_owner = payload?.account_owner
      state.paymentInfo.amount = payload?.amount
      state.paymentInfo.cash_reference = payload?.cash_reference
      state.paymentInfo.reference = payload?.reference
      state.paymentInfo.spei_reference = payload?.spei_reference

    })
    builder.addCase(getPaymentInfoData.rejected, (state, action) => {
      console.log(action.error)
      state.isPaymentInfoLoading = false
      state.paymentInfoError = action.error.message
    })

  },
})

export const {
  clearStoreState,
} = storeSlice.actions
export default storeSlice.reducer