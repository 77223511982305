import React from 'react'

const GovernmentPayments = (props) => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M2.99997 19.5C2.30128 19.5 1.70993 19.258 1.22595 18.7741C0.741983 18.2901 0.5 17.7002 0.5 17.0046V14.5001H3.5V0.692383L4.88462 1.88468L6.28845 0.692383L7.6923 1.88468L9.09613 0.692383L10.5 1.88468L11.9038 0.692383L13.3077 1.88468L14.7115 0.692383L16.1153 1.88468L17.5 0.692383V17C17.5 17.6987 17.258 18.2901 16.774 18.7741C16.29 19.258 15.6987 19.5 15 19.5H2.99997ZM15 18C15.2833 18 15.5208 17.9042 15.7125 17.7125C15.9041 17.5209 16 17.2834 16 17V3.00003H4.99998V14.5001H14V17C14 17.2834 14.0958 17.5209 14.2875 17.7125C14.4791 17.9042 14.7166 18 15 18ZM6.1923 6.75001V5.25006H11.8654V6.75001H6.1923ZM6.1923 9.75001V8.25006H11.8654V9.75001H6.1923ZM13.9423 6.88463C13.6974 6.88463 13.4888 6.79842 13.3163 6.62598C13.1439 6.45355 13.0577 6.2449 13.0577 6.00003C13.0577 5.75517 13.1439 5.54652 13.3163 5.37408C13.4888 5.20165 13.6974 5.11543 13.9423 5.11543C14.1871 5.11543 14.3958 5.20165 14.5682 5.37408C14.7407 5.54652 14.8269 5.75517 14.8269 6.00003C14.8269 6.2449 14.7407 6.45355 14.5682 6.62598C14.3958 6.79842 14.1871 6.88463 13.9423 6.88463ZM13.9423 9.88463C13.6974 9.88463 13.4888 9.79842 13.3163 9.62598C13.1439 9.45355 13.0577 9.2449 13.0577 9.00003C13.0577 8.75517 13.1439 8.54652 13.3163 8.37408C13.4888 8.20165 13.6974 8.11543 13.9423 8.11543C14.1871 8.11543 14.3958 8.20165 14.5682 8.37408C14.7407 8.54652 14.8269 8.75517 14.8269 9.00003C14.8269 9.2449 14.7407 9.45355 14.5682 9.62598C14.3958 9.79842 14.1871 9.88463 13.9423 9.88463ZM2.99997 18H12.5V16H1.99997V17C1.99997 17.2834 2.09581 17.5209 2.28747 17.7125C2.47914 17.9042 2.71664 18 2.99997 18Z" 
      fill={props?.color || '#541DFF'}
    />
  </svg>
)

export default GovernmentPayments