import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DateTime } from 'luxon'
import { HelpOutline } from '@mui/icons-material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Container, Box, Tooltip, IconButton, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { settleConsignment, resetSettle, getReceiptById } from 'store/slices/shelf'
// import Divider from 'styles/shared'
import TransactionMessageConsignment from './modals/TransactionMessageConsignment'
import WarningModal from './modals/WarningModal'
import { toCurrency } from 'utils/formats'
import { useAppContext, MODAL_TYPES } from 'providers/AppProvider'
import HeaderBar from 'components/HeaderBar'
import colors from 'styles/colors'

// import DotsHorizontal from 'assets/DotsHorizontal'

function ShelfReceipt({ navigation }) {
  // const app_version = Constants.expoConfig.version
  // const [receiptId, setReceiptId] = useState('')
  const dispatch = useDispatch()
  const app = useAppContext()
  const navigate = useNavigate()
  const { receipt_id } = useParams()
  const [latLng, setLatlng] = useState({ lat: null, lng: null })
  const [wasteList, setWasteList] = useState([])
  const [recognizedSalesList, setRecognizedSalesList] = useState([])
  const [restockList, setRestockList] = useState([])
  const [recallList, setRecallList] = useState([])
  const [updatedStockList, setUpdatedStockList] = useState([])

  const {
    receipt,
    receiptIsLoading,
    settleIsLoading,
    settleError,
    settleStatus
  } = useSelector(state => state.shelf)
  
  const {
    // shelf_number,
    id,
    producer_name,
    created_date,
    order_details,
    estimated_profit,
    to_pay,
    total_cost,
    status,
    receipt_user,
    producer_logo
  } = receipt

  useEffect(() => {
    dispatch(getReceiptById({ consignment_id: receipt_id }))
  }, [])

  useEffect(() => {
    app.setIsLoading(settleIsLoading || receiptIsLoading)
  }, [settleIsLoading, receiptIsLoading])

  const mapTable = () => {
    // console.log('mapTable')
    let waste_list = []
    let recognized_sales_list = []
    let restock_list = []
    let recall_list = []
    let updated_stock_list = []
    
    order_details.forEach(item => {
      if (item.units.waste || item.units.expired) {
        waste_list = [
          ...waste_list,
          {
            name: item.product_name,
            quantity: item.units.waste + item.units.expired
          }
        ]
      }
      if (item.units.recognized_sales) {
        recognized_sales_list = [
          ...recognized_sales_list,
          {
            name: item.product_name,
            quantity: item.units.recognized_sales
          }
        ]
      }
      if (item.units.supply) { // item.units.restock
        restock_list = [
          ...restock_list,
          {
            name: item.product_name,
            quantity: item.units.supply // item.units.restock
          }
        ]
      }
      if (item.units.stock_removal) {
        recall_list = [
          ...recall_list,
          {
            name: item.product_name,
            quantity: item.units.stock_removal
          }
        ]
      }
      if (item?.estimated_stock || item?.estimated_stock === 0) {
        updated_stock_list = [
          ...updated_stock_list,
          {
            name: item?.product_name,
            quantity: item?.estimated_stock
          }
        ]
      }
    })
    
    setWasteList(waste_list)
    setRecognizedSalesList(recognized_sales_list)
    setRestockList(restock_list)
    setRecallList(recall_list)
    setUpdatedStockList(updated_stock_list)
  }
  
  // console.log({ restockList })
  // useFocusEffect(useCallback(() => {
  //   getCoords()
  // }, []))


  useEffect(() => {
    if (!settleError && !settleIsLoading && settleStatus === 'accepted') {
      app.showModal({
        component: (
          <TransactionMessageConsignment
            data={{
              consignment_id: id,
              producer_name,
              producer_logo
            }}
          />
        ),
        onClose: () => {
          dispatch(resetSettle())
          navigate('/shelf')
        }
      })
    } else if (!settleError && !settleIsLoading && settleStatus === 'rejected') {
      dispatch(resetSettle())
      navigate('/shelf')
    } else if (settleError && !settleIsLoading) {
      app.showModal({
        component: (
          <TransactionMessageConsignment
            error={{ message: 'Hubo un error al procesar tu solicitud' }}
          />
        ),
        onClose: () => {
          dispatch(resetSettle())
          navigate('/shelf')
        }
      })
    }
  }, [settleIsLoading, settleError, settleStatus])

  useEffect(() => {
    if (order_details.length > 0 && receiptIsLoading === false) {
      mapTable()
    }
  }, [receiptIsLoading, order_details])

  const handleAction = async (status) => {
    try {
      dispatch(settleConsignment({
        consignment_id: id,
        status,
        location: `${latLng.lat},${latLng.lng}`,
        device_id: 'test-id',//await getDeviceId(),
        version_app: 'webapp'// app_version
      }))
    } catch (error) {
      app.showModal({
        type: MODAL_TYPES.ERROR,
        message: 'No pudimos obtener tu identificador de dispositivo, intenta de nuevo.'
      })
    }
  }

  // const getCoords = async () => {
  //   try {
  //     let { status } = await Location.requestForegroundPermissionsAsync();
  //     if (status !== 'granted') {
  //       alert('El permiso de ubicación ha sido denegado')
  //       return;
  //     }

  //     let location = await Location.getCurrentPositionAsync({});
  //     const { latitude, longitude } = location.coords
  //     setLatlng({ lat: latitude, lng: longitude })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleQRCodeWarning = () => {
    app.showModal({
      backgroundColor: 'transparent',
      component: <WarningModal onConfirm={() => handleAction('accepted')} />
    })
  }

  const formattedDate = DateTime.fromISO(created_date, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATE_MED)


  // const openDemoNPSModal = () => {
  //   app.showModal({
  //     component: (
  //       <TransactionMessageConsignment
  //         data={{
  //           consignment_id: id,
  //           producer_name,
  //           producer_logo
  //         }}
  //       />
  //     ),
  //     onClose: () => {
  //       // dispatch(resetSettle())
  //       // navigate('/shelf')
  //     }
  //   })
  // }
  
  return (
    <div className="container">
      <HeaderBar />
      <Container maxWidth="xl">
        <Box>
          <Box mt={4} px={2} py={4} sx={{ backgroundColor: 'white' }}>
            {/* <Text variant="titleLarge">
              {shelf_number}
            </Text> */}
            <Box px={2} mb={4}>
              <Box mb={1} className="title" style={{ color: colors.deepblue }}>
                {producer_name}
              </Box>
              <Box mb={1} className="subtitle flex-row">
                Por pagar: <Box sx={{ marginLeft: 'auto' }}>{toCurrency(to_pay)}</Box>
              </Box>
              <Box className="bodySmall" sx={{ color: colors.darkgrey }}>
                {formattedDate}
              </Box>
            </Box>

            {recallList?.length > 0 && (
              <Table size="small" sx={{ mb: 3 }}>
                <TableBody>
                  <TableRow className="no-border">
                    <TableCell>
                      <Box className="bodyLarge" style={{ color: colors.oriolesred }}>
                        Producto retirado
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title="Los productos expirados o merma no te cuentan como venta y no afectan tu monto por pagar"
                      >
                        <IconButton color="inherit">
                          <HelpOutline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Producto</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Unidades</b>
                    </TableCell>
                  </TableRow>
                  {recallList.map((item, index) => (
                    <TableRow key={`${item.sku}_${index}`}>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell align="right">
                        {item.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="border">
                    <TableCell>
                      Elementos totales:
                    </TableCell>
                    <TableCell align="right">
                      <b>{recallList.reduce((acc, item) => acc + item.quantity, 0)}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

            {wasteList.length > 0 && (
              <Table size="small" sx={{ mb: 3 }}>
                <TableBody>
                  <TableRow className="no-border">
                    <TableCell>
                      <span className="bodyLarge" style={{ color: colors.oriolesred }}>
                        Producto expirado o merma
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title="Los productos expirados o merma no te cuentan como venta y no afectan tu monto por pagar"
                      >
                        <IconButton color="inherit">
                          <HelpOutline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Producto</b>  
                    </TableCell>
                    <TableCell align="right">
                      <b>Unidades</b>
                    </TableCell>
                  </TableRow>
                  {wasteList.map((item, index) => (
                    <TableRow key={`${item.sku}_${index}`}>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell align="right">
                        {item.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* <Divider /> */}
                  <TableRow className="border">
                    <TableCell>
                      Elementos totales:
                    </TableCell>
                    <TableCell align="right">
                      <b>{wasteList.reduce((acc, item) => acc + item.quantity, 0)}</b>
                    </TableCell>
                  </TableRow>

                  {/* <Divider /> */}
                </TableBody>
              </Table>
            )}

            
            {recognizedSalesList.length > 0 && (
              <Table size="small" sx={{ mb: 3 }}>
                <TableBody>
                  <TableRow className="no-border">
                    <TableCell>
                      <span className="bodyLarge" style={{ color: colors.success }}>
                        Producto vendido
                      </span>
                    </TableCell>
                    <TableCell  align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Producto</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Unidades</b>
                    </TableCell>
                  </TableRow>
                  {recognizedSalesList.map((item, index) => (
                    <TableRow key={`${item.sku}_${index}`}>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell align="right">
                        {item.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="no-border">
                    <TableCell>
                      Elementos totales:
                    </TableCell>
                    <TableCell align="right">
                      <b>{recognizedSalesList.reduce((acc, item) => acc + item.quantity, 0)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow className="no-border">
                    <TableCell>
                      <b style={{ color: colors.deepblue }}>
                        Por Pagar:
                      </b>
                    </TableCell>
                    <TableCell align="right">
                      {toCurrency(to_pay)}
                    </TableCell>
                  </TableRow>
                  {/* <Divider /> */}
                  <TableRow className="border">
                    <TableCell>
                      <b style={{ color: colors.success }}>
                        Ganancia:
                      </b>
                    </TableCell>
                    <TableCell align="right">
                      {toCurrency(estimated_profit)}
                    </TableCell>
                  </TableRow>

                  {/* <Divider /> */}
                </TableBody>
              </Table>
            )}
            
            {restockList.length > 0 && (
              <Table size="small" sx={{ mb: 3 }}>
                <TableBody>
                  <TableRow className="no-border">
                    <TableCell>
                      <span className="bodyLarge" style={{ color: colors.hanpurple }}>
                        Producto nuevo
                      </span>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Producto</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Unidades</b>
                    </TableCell>
                  </TableRow>
                  {restockList.map((item, index) => (
                    <TableRow key={`${item.sku}_${index}`}>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell align="right">
                        {item.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* <Divider color={colors.midgrey} /> */}
                  <TableRow className="border">
                    <TableCell>
                      Elementos totales:
                    </TableCell>
                    <TableCell align="right">
                      <b>{restockList.reduce((acc, item) => acc + item.quantity, 0)}</b>
                    </TableCell>
                  </TableRow>
                  {/* <Divider /> */}
                </TableBody>
              </Table>
            )}

            {updatedStockList.length > 0 && (
              <Table size="small" sx={{ mb: 3 }}>
                <TableBody>
                  <TableRow className="no-border">
                    <TableCell>
                      <span className="bodyLarge" style={{ color: colors.darkgrey }}>
                        Existencias actualizadas
                      </span>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Producto</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Unidades</b>
                    </TableCell>
                  </TableRow>
                  {updatedStockList.map((item, index) => (
                    <TableRow key={`${item.sku}_${index}`}>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell align="right">
                        {item.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* <Divider color={colors.midgrey} /> */}
                  <TableRow className="border">
                    <TableCell>
                      Elementos totales:
                    </TableCell>
                    <TableCell align="right">
                      <b>{updatedStockList.reduce((acc, item) => acc + item.quantity, 0)}</b>
                    </TableCell>
                  </TableRow>
                  {/* <Divider /> */}
                </TableBody>
              </Table>
            )}
            

            {(!status || status === 'init') && (
              <Box>
                <Button variant="contained" onClick={handleQRCodeWarning} disabled={settleIsLoading}>
                  Confirmar
                </Button>
                <Button onClick={() => handleAction('rejected')} disabled={settleIsLoading}>
                  Rechazar
                </Button>
              </Box>
            )}
            {status && status !== 'init' && status !== 'rejected' && (
              <Box mt={3} px={2}>
                <b>Recibido por: </b>
                <span style={{ color: colors.deepblue }}>
                  {receipt_user?.user_name || ''}
                </span>
              </Box>
            )}

          </Box>
        </Box>
        {/* <Button onClick={openDemoNPSModal}>
          Demo NPS
        </Button> */}
      </Container>
    </div>
  )
}

export default ShelfReceipt