import React from 'react'

const TvSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color || '#541DFF'}
      d="M5.154 15.846h1.692a1.77 1.77 0 0 0-.53-1.162 1.77 1.77 0 0 0-1.162-.53v1.692Zm3.55 0h1.161c0-1.306-.458-2.418-1.376-3.335-.917-.918-2.029-1.376-3.335-1.376v1.161c.983 0 1.82.346 2.512 1.038a3.422 3.422 0 0 1 1.038 2.512Zm3.02 0h1.16a7.556 7.556 0 0 0-.605-3.007 7.771 7.771 0 0 0-1.655-2.463c-.7-.7-1.52-1.251-2.463-1.655a7.557 7.557 0 0 0-3.007-.606v1.162c1.823 0 3.374.639 4.652 1.917s1.917 2.829 1.917 4.652ZM8.5 20.5v-2H4.308c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V5.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H15.5v2h-7ZM4.308 17h15.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.212V5.308a.294.294 0 0 0-.096-.212.294.294 0 0 0-.212-.096H4.308a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v11.384c0 .077.032.148.096.212a.294.294 0 0 0 .212.096Z"
    />
  </svg>
);

export default TvSvg
