import React from 'react'

const PaymentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color || "#541DFF"}
      d="M11.292 18.808h1.366v-1.212a4.408 4.408 0 0 0 2.106-.874c.63-.478.944-1.202.944-2.172 0-.7-.207-1.32-.62-1.858-.412-.538-1.219-1.024-2.419-1.457-1.051-.36-1.75-.67-2.094-.933-.345-.263-.517-.63-.517-1.102 0-.472.176-.855.53-1.15.353-.295.84-.442 1.462-.442.52 0 .94.122 1.26.368.319.245.564.553.736.924l1.235-.496a3.262 3.262 0 0 0-1.022-1.367 2.783 2.783 0 0 0-1.551-.633V5.192h-1.366v1.212c-.852.164-1.507.51-1.964 1.037-.457.528-.686 1.114-.686 1.759 0 .751.228 1.356.683 1.813.455.458 1.197.872 2.225 1.24 1.063.39 1.789.74 2.178 1.05.389.31.583.725.583 1.247 0 .627-.226 1.082-.678 1.366a2.823 2.823 0 0 1-1.533.426c-.563 0-1.066-.169-1.51-.507-.445-.339-.786-.834-1.025-1.485l-1.265.515c.259.743.631 1.332 1.116 1.77.486.437 1.094.74 1.826.911v1.262ZM12 21.5a9.235 9.235 0 0 1-3.703-.75 9.606 9.606 0 0 1-3.016-2.03 9.607 9.607 0 0 1-2.032-3.017A9.235 9.235 0 0 1 2.5 12c0-1.313.25-2.547.749-3.703a9.607 9.607 0 0 1 2.032-3.016 9.607 9.607 0 0 1 3.016-2.032A9.235 9.235 0 0 1 12 2.5c1.313 0 2.547.25 3.703.749a9.607 9.607 0 0 1 3.016 2.032 9.606 9.606 0 0 1 2.032 3.016c.5 1.156.749 2.39.749 3.703 0 1.313-.25 2.547-.75 3.703a9.607 9.607 0 0 1-2.03 3.016 9.607 9.607 0 0 1-3.017 2.032c-1.156.5-2.39.749-3.703.749Zm0-1.5c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z"
    />
  </svg>
)

export default PaymentIcon
