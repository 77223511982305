function Pins(props){
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.38 61.5231H18.644C16.1781 61.5205 14.1792 59.577 14.1766 57.1794V24.0756C14.1766 23.9481 14.1834 23.8227 14.1945 23.6982C11.8878 23.8962 10.1184 25.7729 10.1152 28.0244V61.1282C10.1179 63.5257 12.1168 65.4693 14.5827 65.4718H63.3186C65.6338 65.4687 67.5645 63.7484 67.7681 61.5056C67.6401 61.5164 67.5111 61.5231 67.38 61.5231Z" fill="#E9A9C1"/>
      <path d="M68.9301 51.144H31.2764C32.6214 50.28 33.4307 48.8288 33.4355 47.2732C33.4355 44.6898 31.2199 42.5881 28.4969 42.5881C26.8319 42.5865 25.2777 43.3871 24.3571 44.7205V22.5774C24.3571 22.0707 23.9301 21.6602 23.4029 21.6602C22.8758 21.6602 22.4488 22.0707 22.4488 22.5774V44.721C22.0276 44.1063 21.4626 43.5949 20.7992 43.2274C20.0421 42.8082 19.1832 42.5876 18.3089 42.5881C15.5859 42.5881 13.3709 44.6898 13.3709 47.2732C13.3752 48.8283 14.1845 50.2795 15.5295 51.144H8.95416C8.42703 51.144 8 51.5545 8 52.0613C8 52.568 8.42703 52.9786 8.95416 52.9786H21.0995L16.5944 57.3095C16.2217 57.6678 16.2217 58.2483 16.5944 58.6066C16.9671 58.9649 17.5709 58.9649 17.9436 58.6066L22.4488 54.2757V66.0825C22.4488 66.5893 22.8758 66.9998 23.4029 66.9998C23.9301 66.9998 24.3571 66.5893 24.3571 66.0825V54.2757L30.4298 60.1136C30.8025 60.4719 31.4063 60.4719 31.779 60.1136C32.1517 59.7558 32.1517 59.1748 31.779 58.8165L25.7063 52.9786H68.9301C69.4573 52.9786 69.8843 52.568 69.8843 52.0613C69.8843 51.5545 69.4573 51.144 68.9301 51.144ZM17.7386 50.0727C16.2094 49.8208 15.1418 48.4772 15.292 46.9927C15.4416 45.5083 16.7584 44.3873 18.3089 44.4226C18.8483 44.4221 19.3781 44.5578 19.8461 44.8157C20.5937 45.2268 21.1192 45.9291 21.2859 46.7414L22.1176 50.862L17.7386 50.0727ZM29.0672 50.0727L24.6888 50.8625L25.52 46.7419C25.8373 45.2913 27.2345 44.2993 28.7701 44.4354C30.3052 44.5711 31.4909 45.7914 31.5272 47.2732C31.5138 48.6538 30.4809 49.8295 29.0672 50.0727Z" fill={props?.color || '#531AFF'} className="border"/>
      <path d="M63.2456 28.0031C62.8562 27.6378 62.2248 27.6378 61.8354 28.0031L59.5096 30.1852V19.5391C59.5096 19.0225 59.0629 18.604 58.5127 18.604C57.962 18.604 57.5159 19.0225 57.5159 19.5391V30.1852L55.1895 28.0031C54.8001 27.6378 54.1692 27.6378 53.7798 28.0031C53.3904 28.3684 53.3904 28.9606 53.7798 29.3253L57.8074 33.1036C58.1968 33.4689 58.8281 33.4689 59.2175 33.1036L63.2451 29.3253C63.6345 28.9606 63.635 28.3684 63.2456 28.0031Z" fill="#E9A9C1"/>
      <path d="M58.476 13C52.6787 13.0066 47.5276 16.5548 45.6638 21.8257H14.4C10.867 21.8292 8.00372 24.5786 8 27.9709V60.8547C8.00372 64.2471 10.867 66.9964 14.4 67H63.4213C66.9542 66.9964 69.8175 64.2471 69.8213 60.8547V33.0457C72.5197 29.0537 72.726 23.9636 70.3596 19.7822C67.9925 15.6002 63.4324 12.998 58.476 13ZM58.476 14.8305C64.892 14.8305 70.0936 19.8251 70.0936 25.9857C70.0936 32.1468 64.892 37.1409 58.476 37.1409C52.0596 37.1409 46.8585 32.1468 46.8585 25.9857C46.8654 19.8276 52.0628 14.8377 58.476 14.8305ZM63.4213 65.1695H14.4C11.9197 65.1669 9.90904 63.2363 9.90638 60.8547V27.9709C9.90904 25.5893 11.9197 23.6587 14.4 23.6562H45.1702C44.1112 29.2167 46.9213 34.7955 52.1186 37.4504C57.3154 40.1057 63.7042 39.2257 67.9149 35.2756V60.8547C67.9122 63.2363 65.9016 65.1669 63.4213 65.1695Z" fill={props?.color || '#531AFF'} className="border"/>
    </svg>
  )
}

export default Pins