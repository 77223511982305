import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close';

import colors from '../../styles/colors'
import './style.scss'

const PasswordRule = ({ password, validation, message }) => {
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsValid(validation(password))
  }, [password, validation])

  const ruleState = () => {
    if (!password) {
      return { color: colors.darkgrey, icon: <div className="dot" style={{ backgroundColor: colors.darkgrey }} /> }
    } else if (isValid) {
      return { color: colors.success, icon: <CheckIcon fontSize="16px" color='success' /> }
    } else {
      return { color: colors.error, icon: <CloseIcon fontSize="16px" color='error' /> }
    }
  }

  const rule = ruleState()
  
  return (
    <Box className="rule-item">
      <Box className="icon-container">
        {rule.icon}
      </Box>
      <Box className="rule" sx={{ color: rule.color }} variant="bodyMedium">
        {message}
      </Box>
    </Box>
  )
}

export default PasswordRule