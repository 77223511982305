import React from 'react'

const CellphoneMinutes = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path 
    d="M10.1154 19.8269H13.8846V18.9423H10.1154V19.8269ZM7.3077 22.5C6.80257 22.5 6.375 22.325 6.025 21.975C5.675 21.625 5.5 21.1974 5.5 20.6923V3.3077C5.5 2.80257 5.675 2.375 6.025 2.025C6.375 1.675 6.80257 1.5 7.3077 1.5H16.6922C17.1974 1.5 17.625 1.675 17.975 2.025C18.325 2.375 18.5 2.80257 18.5 3.3077V20.6923C18.5 21.1974 18.325 21.625 17.975 21.975C17.625 22.325 17.1974 22.5 16.6922 22.5H7.3077ZM6.99997 17.7692V20.6923C6.99997 20.7692 7.03203 20.8397 7.09613 20.9038C7.16024 20.9679 7.23077 21 7.3077 21H16.6922C16.7692 21 16.8397 20.9679 16.9038 20.9038C16.9679 20.8397 17 20.7692 17 20.6923V17.7692H6.99997ZM6.99997 16.2692H17V5.74995H6.99997V16.2692ZM6.99997 4.25H17V3.3077C17 3.23077 16.9679 3.16024 16.9038 3.09613C16.8397 3.03203 16.7692 2.99998 16.6922 2.99998H7.3077C7.23077 2.99998 7.16024 3.03203 7.09613 3.09613C7.03203 3.16024 6.99997 3.23077 6.99997 3.3077V4.25Z"
    fill={props?.color || '#541DFF'}
  />
  </svg>
)

export default CellphoneMinutes