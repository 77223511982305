import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button } from '@mui/material'
import Lottie from 'react-lottie'

import { verifyTransactionData } from 'store/slices/sales'
import * as animationData from 'assets/HourglassAnimation.json'


function TransactionInProgress() {
  const dispatch = useDispatch()
  const { verify_transaction: { isLoading, error }, sale_id } = useSelector((state) => state.sales)
  const [overtime, setOvertime] = useState(false)
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const onRefresh = useCallback(() => {
    dispatch(verifyTransactionData({ sale_id }))
  }, [sale_id])

  useEffect(() => {
    let timer = null
    
    if (sale_id) {
      timer = setTimeout(() => {
        // console.log('verifyTransactionData default', { sale_id })
        dispatch(verifyTransactionData({ sale_id }))
      }, 5000)
    }

    return () => clearTimeout(timer)
  }, [sale_id]) 

  useEffect(() => {
    let timer = null
    
    if (sale_id) {
      timer = setTimeout(() => {
        dispatch(verifyTransactionData({ sale_id }))
      }, 20000)
    }

    return () => clearTimeout(timer)
  }, [sale_id]) 

  useEffect(() => {
    let timer = null
    
    if (sale_id) {
      timer = setTimeout(() => {
        dispatch(verifyTransactionData({ sale_id }))
      }, 35000)
    }

    return () => clearTimeout(timer)
  }, [sale_id]) 

  useEffect(() => {
    let timer = null
    
    if (sale_id) {
      timer = setTimeout(() => {
        dispatch(verifyTransactionData({ sale_id }))
      }, 50000)
    }

    return () => clearTimeout(timer)
  }, [sale_id]) 

  useEffect(() => {
    let timer = null
    
    if (sale_id) {
      timer = setTimeout(() => {
        dispatch(verifyTransactionData({ sale_id }))
      }, 65000)
    }

    return () => clearTimeout(timer)
  }, [sale_id]) 

  useEffect(() => {
    const timer = setTimeout(() => {
      setOvertime(true)
    }, 80000)

    return () => clearTimeout(timer)
  }, [])  

  return (
    <Box p={2}>
      <Lottie 
        options={defaultOptions}
        height={200}
        width={200}
      />
      <Box className="bodySmall" my={3} p={2}>
        Tansacción en proceso. Por favor, espera un momento.
      </Box>
      {overtime && (
        <Box style={{ alignItems: 'center' }}>
          <Box className="bodyMedium" mb={2}>
            {'\n'} Estamos procesando la transacción. Si la espera se prolonga, puedes verificar el estado actualizado en cualquier momento. {'\n'}
          </Box>
          <Button 
            variant="contained" 
            onClick={onRefresh} 
            disabled={isLoading} 
            fullWidth
          >
            Verificar estado
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default TransactionInProgress
