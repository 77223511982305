import axios from 'axios'

export const downloadBalanceReportFile = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/store/balance-report`
    const token = localStorage.getItem('session') || ''
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_API_KEY}`,
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob', // Important to handle binary data
    })

    const blob = new Blob([response.data], { type: response.data.type })
    const blobUrl = URL.createObjectURL(blob)

    const contentDisposition = response.headers['content-disposition']
    const fileNameMatch = contentDisposition && contentDisposition.match(/filename="?(.+)"?/)
    const fileName = fileNameMatch ? fileNameMatch[1] : 'estado_de_cuenta.pdf'

    const link = document.createElement('a')
    link.href = blobUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    // Clean up
    document.body.removeChild(link)
    URL.revokeObjectURL(blobUrl)
  } catch (error) {
    alert('Hubo un error al descargar el archivo. Por favor, intenta de nuevo.')
    // console.error('There was an error downloading the file:', error)
  }
}