import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTransactions } from '../../services/activity'

export const fetchTransactions = createAsyncThunk(
  'activity/fetchTransactions',
  async (_, { getState }) => {
    const { token } = getState().identity
    const response = await getTransactions({ token })
    //console.log('fetchTransactions', JSON.parse(response.data[29].details))
    return response.data
  }
)

const initialState = {
  activities: [],
  current_filter: null,
  filtered_activities: null,
  isLoading: false,
  error: null,
}

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    filterActivities: (state, { payload }) => {
      state.current_filter = payload
      const reference = payload?.reference
      const date_range = payload?.date_range
      const transaction_types = payload?.transaction_types

      const groups_filters = {
        digital_sale: ['prepaid_toll_program', 'catalog_sales', 'entertainment', 'telephony', 'paytv', 'energy', 'water_service'],
        finantial: ['finance-charges', 'payment-spei', 'payment-cash'],
        physical_sale: ['consignment', 'discrepancy'],
      }
      
      state.filtered_activities = state.activities.filter((activity) => {
        let filtered = true
        if (date_range) {
          const today = new Date()
          const date = new Date(activity?.date)
          const diffTime = Math.abs(today - date)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
          if (date_range === 'last_7_days') {
            filtered = filtered && (diffDays <= 7)
          }
          if (date_range === 'last_15_days') {
            filtered = filtered && (diffDays <= 15)
          }
          if (date_range === 'last_30_days') {
            filtered = filtered && true
          }
        }
        if (reference) {
          filtered = filtered && (activity?.auth_number === reference || activity?.reference === reference)
        }
        if (transaction_types?.length > 0) {
          const transaction_types_flat = transaction_types.map((type) => groups_filters?.[type]).flat()
          filtered = filtered && transaction_types_flat.includes(activity?.group)
        }
        return filtered
      });
    },
    clearFilter: (state) => {
      state.current_filter = null
      state.filtered_activities = null
    },
    clearActivityState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchTransactions.fulfilled, (state, { payload }) => {
      // console.log('fetchTransactions') //, JSON.stringify(payload))
      state.activities = payload
      state.isLoading = false
    })
    builder.addCase(fetchTransactions.rejected, (state, action) => {
      console.log('fetchTransactions.error', action.error.message)
      state.isLoading = false
      state.error = action.error.message
    })
  },
})

export const {
  clearFilter,
  filterActivities,
  clearActivityState
} = activitySlice.actions
export default activitySlice.reducer