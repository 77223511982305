import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

import Input from 'components/Input'
import OptionsPicker from 'components/OptionsPicker'
import { isNumeric } from 'utils/validations'
import colors from 'styles/colors'
import { setCheckoutOptions } from 'store/slices/sales'

function CellphoneMinutes({ control, setValue, watch, configuration }) {
  const dispatch = useDispatch()
  const { menu: { cellphonePlans } } = useSelector(state => state.sales)
  const planWatch = watch('plan')

  const {
    customer_fee,
    payment_info,
    // store_fee,
  } = configuration || {}

  const plans = cellphonePlans?.topups?.map((item) => {
    return {
      ...item,
      label: item.description,
    }
  })

  const amounts = planWatch?.topups?.map((item) => {
    return {
      ...item,
      label: `$${item?.price}`
    }
  })

  useEffect(() => {
    dispatch(setCheckoutOptions(planWatch))
  }, [planWatch])

  useEffect(() => {
    if (plans && plans.length === 1 && !planWatch) {
      setValue('plan', plans[0])
    }
  }, [plans, setValue, planWatch])

  return (
    <Box>
      <Box className="service-details">
        <p className="title">Detalles</p>
        <br />
        <p>Comisión a cobrar por servicio: $0.00</p>
        <br />
        <p>Tiempo que tarda en aplicarse: <b>{payment_info || ''}</b></p>
        <br />
        <p><PriorityHighIcon sx={{ color: colors.oriolesred, fontSize: '16px' }} /> Si aceptó un adelanto de saldo via sms, ese saldo se descontará de la recarga.</p>
      </Box>
      <Box className="headlineSmall" my={2}>
        1: Elije plan
      </Box>
      <Box>
        <OptionsPicker options={plans} control={control} name="plan" defaultValue={null} />
      </Box>
      <Box className="headlineSmall" my={2}>
        2: Elije monto
      </Box>
      <Box>
        {amounts ? (
          <OptionsPicker options={amounts} control={control} name="option_amount" />
        ):(
          <Box pb={2} sx={{ color: colors.darkgrey }}>
            Debes seleccionar un plan para ver las opciones de monto.
          </Box>
        )}
      </Box>
      <Box className="headlineSmall" my={2}>
        3: Ingresa datos
      </Box>
      <Box className="flex-row" sx={{ gap: '20px'}}>
        <Input
          control={control}
          name="phone_number"
          label="Ingresa número celular"
          rules={{
            required: true,
            validate: {
              numbers: (value) => isNumeric(value) || 'Debe contener solo números',
              min: (value) => /^.{10}$/.test(value) || 'Debe contener 10 digitos'
            }
          }}
          fullWidth
        />
        <Input
          control={control}
          name="confirm_phone"
          label="Confirma número celular"
          rules={{
            required: true,
            validate: {
              numbers: (value) => isNumeric(value) || 'Debe contener solo números',
              min: (value) => /^.{10}$/.test(value) || 'Debe contener 10 digitos'
            }
          }}
          fullWidth
        />
      </Box>
    </Box>
  )
}

export default CellphoneMinutes
