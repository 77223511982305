import { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import Input from 'components/Input'
import colors from 'styles/colors'
import { sendReceiptData } from 'store/slices/sales'
import { verifyTransaction } from 'services/sales'
import { isNumeric } from 'utils/validations'

function SendReceipt({ onDismiss, sale_id }) {
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()

  const onSubmit = async (data) => {
    try {
      var tx = await verifyTransaction({ sale_id: sale_id });
      window.open(`https://wa.me/52${data.phone_number}?text=${tx?.data?.receipt_link}`,'_blank','noopener,noreferrer');
      onDismiss();
    } catch (error) {
      console.error('Error al enviar el comprobante:', error);
      alert('No se pudo abrir WhatsApp o enviar el comprobante');
    }
  };
  //const onSubmit = (data) => {
  //  dispatch(sendReceiptData({ sale_id, notification_key: '', other_phone: data.phone_number }))
  //  onDismiss()
  //}

  return (
    <Box p={3} width="400px">
      <Box className="flex-row">
        <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <ChatBubbleOutlineIcon sx={{ fontSize: 40, color: colors.darkgrey }} />
      <Box mt={3} mb={2} className="subtitle hanpurple">
        Enviar comprobante
      </Box>
      <Box mb={2}>
        <Input
          label="Número de teléfono"
          name="phone_number"
          control={control}
          fullWidth
          margin="normal"
          rules={{ 
            required: true,
            validate: {
              numbers: (value) => isNumeric(value) || 'Debe contener solo números',
              min: (value) => /^.{10}$/.test(value) || 'Debe contener 10 digitos'
            }
          }}
        />
      </Box>
      <Box className="flex-row">
        <Button variant="text" sx={{ marginLeft: 'auto' }} onClick={onDismiss}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Enviar
        </Button>
      </Box>
    </Box>
  )
}

export default SendReceipt