import styled from '@emotion/styled'
// import { rgba } from 'polished'
import COLORS from 'styles/colors'
import { alpha } from '@mui/material'

export const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 16px;
  row-gap: 10px;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const Option = styled.span`
  background-color: ${props => props.isSelected ? alpha(COLORS.hanpurple, 0.2) : 'transparent'};
  padding: 10px 24px;
  border-radius: 25px;
  border: 1px solid ${COLORS.darkgrey};
  cursor: pointer;
`

const styles = {
  optionText: {
    color: COLORS.deepblue,
  }
}

export default styles