import request from 'utils/request'

export const getMenu = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/menu`,
  })
  return response?.data
}

export const transaction = async ({
  store_id,
  sku,
  reference,
  receipt_phone,
  amount
}) => {
  console.log('REQ', {
    store_id,
    sku,
    reference,
    receipt_phone,
    amount
  })
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/store/generate_tx`,
    data: {
      store_id,
      sku,
      reference,
      receipt_phone,
      amount
    },
  })
  return response?.data
}

export const sendReceipt = async ({ notification_key, sale_id, other_phone }) => {
  try {
    const response = await request({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/store/send_recipt`,
      data: {
        sale_id,
        notification_id: notification_key,
        other_phone
      },
    })
  
    return response?.data
  } catch(error) {
    console.log(error.response)
  }
}

export const getPaymentInfo = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/payment_info`,
  })
  return response?.data
}

export const verifyTransaction = async ({ sale_id }) => {
  console.log('VERIFY', sale_id)
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/tx/${sale_id}`,
  })
  return response?.data
}

export const getTransactionBalance = async ({ sku, reference }) => {
  console.log('VERIFY', `${process.env.REACT_APP_API_URL}/store/transaction_balance?sku=${sku}&reference=${reference}`)
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/transaction_balance?sku=${sku}&reference=${reference}`
  })
  return response?.data
}

export const getProductBySlug = async ({ slug }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/store/checkout/product/${slug}`,
  })
  return response?.data
}