import { Box, Button, IconButton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'

import { downloadBalanceReportFile } from 'services/downloadFile'

function FiltersModal({ onDismiss }) {
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownload = async () => {
    setIsDownloading(true)
    await downloadBalanceReportFile()
    setIsDownloading(false)
  }

  return (
    <Box px={4} pb={4} pt={2} className="confirmation-modal">
      <Box className="flex-row">
        <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box className="title hanpurple" mt={3}>
        Descarga tu estado de cuenta más reciente.
      </Box>
      
      <Box my={3}>
        <p>Los estados de cuenta se generan cada 30 días. ¿Quieres descargar tu estado de cuenta más reciente?</p>
      </Box>

      <Box pt={2} className="flex-row" sx={{ gap: '20px', justifyContent: 'flex-end' }}>
        <Button size="small" onClick={onDismiss}>
          Cancelar
        </Button>
        <LoadingButton size="small" variant="contained" onClick={handleDownload} disabled={isDownloading} loading={isDownloading}>
          Descargar
        </LoadingButton>
      </Box>
      
    </Box>
  )
}

export default FiltersModal