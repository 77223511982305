import { Box, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useSelector } from 'react-redux'

import colors from 'styles/colors'

function SupportModal({ onDismiss, openSupport }) {
  const { support_number } = useSelector((state) => state.store)
  return (
    <Box px={4} pb={4} pt={2} className="transaction-modal">
      <Box className="flex-row">
        <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <HelpOutlineIcon fontSize="large" sx={{ color: colors.darkgrey }} />
      <Box className="title" my={3}>Obtén ayuda</Box>
      <Box>
        Si tienes una duda o deseas hacer una aclaración sobre esta transacción comunícate al área de soporte vía Telegram al <b style={{ color: colors.hanpurple }}>{support_number}</b> o da click en solicitar ayuda.
      </Box>
      <Box pt={2} className="flex-row" sx={{ gap: '20px', justifyContent: 'flex-end' }}>
        <Button onClick={onDismiss}>Cerrar</Button>
        <Button variant="contained" onClick={openSupport}>Solicitar ayuda</Button>
      </Box>
    </Box>
  )
}

export default SupportModal