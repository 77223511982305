import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, Container, Avatar, IconButton, Divider } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

import HeaderBar from 'components/HeaderBar'
import { stringAvatar } from 'utils/helpers'
import colors from 'styles/colors'
import Input from 'components/Input'

function Profile() {
  const navigate = useNavigate()
  const { control } = useForm()
  const { store_name, email, phone } = useSelector((state) => state.store)
  const { children, sx } = stringAvatar(store_name)
  const [editEmail, setEditEmail] = useState(false)
  const [editPhone, setEditPhone] = useState(false)
  const [editPassword, setEditPassword] = useState(false)

  const PlaceholderField = ({ label, value }) => (
    <Box flex={1} my={2}>
      <span>
        {label}:
      </span>
      <Box className="bodyMedium" mt={1}>
        {value}
      </Box>
    </Box>
  )

  return (
    <div className="container">
      <HeaderBar />
      <Container maxWidth="xl">
        <Box className="subtitle" mt={3} mb={3}>
          Perfil de usuario
        </Box>
        <Box>
          <Avatar onClick={() => navigate('/profile')} children={children} sx={{ ...sx, width: 80, height: 80 }} />
          <Box className="bodyLarge" mt={2} sx={{ color: colors.darkpeach }}>
            {store_name}
            {/* <IconButton onClick={() => {}} sx={{ color: colors.hanpurple }}>
              <EditIcon />
            </IconButton> */}
          </Box>
          <Box className="profile-form">
            <Box className="flex-row align-center">
              {editEmail ? 
                <Input 
                  label="Correo" 
                  name="email" 
                  control={control} 
                  defaultValue={email}
                  fullWidth
                /> : 
                <PlaceholderField label="Correo" value={email} />
              }
              <Box>
                {/* <IconButton onClick={() => setEditEmail(prev => !prev)} sx={{ color: colors.hanpurple }}>
                  <EditIcon fontSize="small" />
                </IconButton> */}
              </Box>
            </Box>
            <Divider />
            <Box className="flex-row align-center">
              {editPhone ? 
                <Input 
                  label="Teléfono" 
                  name="phone_number" 
                  control={control} 
                  defaultValue={phone}
                  fullWidth
                /> : 
                <PlaceholderField label="Teléfono" value={phone} />
              }
              <Box>
                {/* <IconButton onClick={() => setEditPhone(prev => !prev)} sx={{ color: colors.hanpurple }}>
                  <EditIcon fontSize="small" />
                </IconButton> */}
              </Box>
            </Box>
            <Divider />
            <Box className="flex-row align-center">
              {editPassword ?
                <Input 
                  label="Contraseña" 
                  name="password" 
                  control={control} 
                  defaultValue={''}
                  type="password"
                  fullWidth
                /> :
                <PlaceholderField label="Contraseña" value="********" />
              }
              <Box>
                {/* <IconButton onClick={() => setEditPassword(prev => !prev)} sx={{ color: colors.hanpurple }}>
                  <EditIcon fontSize="small" />
                </IconButton> */}
              </Box>
            </Box>
            <Divider />
          </Box>
        </Box>
      </Container>
    </div>
  )
}

export default Profile