import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { LoadingButton } from '@mui/lab'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import SuccessIcon from 'assets/transaction/success.svg'
import ErrorIcon from 'assets/transaction/error.svg'
import PlaceholderNPS from 'assets/placeholder-nps.svg'
import colors from 'styles/colors'
import Input from 'components/Input'
import StarsRate from 'components/StarsRate'
import { rateConsignment } from 'store/slices/shelf'

function TransactionMessageConsignment({ error, onDismiss, data}) {
  // const { consignment_id: id, producer_name, producer_logo } = data || {}
  const dispatch = useDispatch()
  const { rateIsLoading, rateError, rateSuccess } = useSelector(state => state.shelf)
  const [step, setStep] = useState(1)
  const { control, handleSubmit } = useForm()
  const {
    producer_name,
    consignment_id,
    producer_logo
  } = data || {}
  const [rate, setRate] = useState(0)

  useEffect(() => {
    if (rateSuccess) {
      setStep(3)
    }
  }, [rateSuccess])
  
  const onSubmit = (data) => {
    dispatch(rateConsignment({
      consignment_id,
      rate,
      attribute: '',
      comments: data.comments
    }))
  }

  useEffect(() => {
    
  }, [rateIsLoading])
  
  const success = !rateError

  if (success) {
    return (
      <Box px={4} pb={4} pt={2} className="confirmation-modal">
        <Box className="flex-row">
          <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        {step === 1 && (
          <>
            <Box mb={3}>
              <img src={SuccessIcon} width={80} alt="Success Icon" />
            </Box>
            <Box>
              <Box className="subtitle hanpurple" mb={2}>
                Transacción exitosa {'\n'}
              </Box>
              <Box className="bodyMedium" mb={1}>
                Tu recibo de <b>{producer_name}</b> está confirmado. {'\n'}
              </Box>
              <Box className="bodyMedium">
                Tus saldos se han actualizado.{'\n'}
              </Box>
            </Box>
          </>
        )}
        {step === 2 && (
          <>
            <Box className="flex-column align-center">
              <Box sx={{ width: 100, height: 100 }} mb={2}>
                {producer_logo ?
                  <img
                    style={{ width: '100%', height: '100%' }}
                    // resizeMode="contain"
                    src={producer_logo}
                  /> : 
                  <img src={PlaceholderNPS} />
                }
              </Box>
            </Box>
            <Box className="flex-column align-center" mb={2}>
              <p style={{ color: colors.deepblue }}>
                Califica a {producer_name}
              </p>
            </Box>
            <Box className="flex-column align-center" mb={3}>
              <StarsRate onRateSelect={(value) => setRate(value)} width='80%' margin={{ b: 15, t: 15 }} />
            </Box>
            <Input
              name="comments"
              control={control}
              label="Agrega tu comentario"
              fullWidth
            />
          </>
        )}
        {step === 3 && (
          <>
            <Box mb={3}>
              <img src={SuccessIcon} width={80} alt="Success Icon" />
            </Box>
            <Box>
              <Box className="subtitle hanpurple" mb={3}>
                Gracias por tus comentarios
              </Box>
              <Box mb={2}>
                Tus comentarios nos ayudan a mejorar.
              </Box>
              {/* <Text variant="bodyLarge">
                Lorem ipsum{'\n'}
              </Text> */}
            </Box>
          </>
        )}
        <Box className="flex-row" mt={3} sx={{ justifyContent: 'flex-end', gap: '20px' }}>
          {step === 1 && (
            <Button variant="contained" onClick={() => setStep(2)}>
              Calificar al proveedor
            </Button>
          )}
          {step === 2 && (
            <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)} loading={rateIsLoading} disabled={rateIsLoading}>
              Continuar
            </LoadingButton>
          )}
          <LoadingButton onClick={onDismiss} loading={rateIsLoading} disabled={rateIsLoading}>
            Cerrar
          </LoadingButton>
        </Box>
      </Box>
    )
  }
  
  return (
    <Box px={4} pb={4} pt={2} className="confirmation-modal">
      <Box mb={3}>
        <img src={ErrorIcon} width={80} alt="Error Icon" />
      </Box>
      <Box>
        <Box className="subtitle error">
          {error?.title || 'Error Inesperado'}. {'\n'}
        </Box>
        {error?.message &&
          <Box>
            {error?.message} {'\n'}
          </Box>
        }
      </Box>
      <Box className="flex-row" mt={3} sx={{ justifyContent: 'flex-end', gap: '20px' }}>
        <Button variant="contained" onClick={onDismiss}>
          Entiendo
        </Button>
        <Button onClick={onDismiss}>
          Cerrar
        </Button>
      </Box>
    </Box>
  )
}

export default TransactionMessageConsignment