import React from 'react'
import './style.css' // Puedes crear un archivo de estilo para personalizar el aspecto del loading

const Loading = ({ loading }) => {
  if (!loading) return null
  
  return (
    <div className="loading-overlay">
      <div className="loading-spinner"></div>
      <p>Cargando...</p>
    </div>
  )
}

export default Loading